import CommonLang from "./common.lang";
import NotFoundLang from "./not-found.lang";
import CheckInLang from "./check-in.lang";
import OrderHistoryLang from "./order-history.lang";
import CategoryLang from "./category.lang";
import Call from "./call.lang";
import Payment from "./payment.lang";
import Cart from "./cart.lang";
import Tip from "./tip.lang";
import Hodai from "./hodai.lang";

export default {
    common: CommonLang,
    notFound: NotFoundLang,
    checkIn: CheckInLang,
    orderHistory: OrderHistoryLang,
    category: CategoryLang,
    call: Call,
    payment: Payment,
    cart: Cart,
    tip: Tip,
    hodai: Hodai,
};
