import ItemDetailContent from "./item.detail.content";
import {toCamel} from "../utils";

class Details {
    courseF?: number

    constructor() {
        this.courseF = 0;
    }
}

export default class ItemDetail {
    // Set default
    isRequired: boolean
    minNumber: number | null
    maxNumber: number | null
    itemDetailCode: string
    name: string
    details: string | null
    state: number
    sortOrder: number | null
    created: number
    modified: number
    itemDetailContents: ItemDetailContent[]
    isCourseF: boolean
    isOpenItemDetailContents: boolean

    constructor() {
        this.isRequired = false;
        this.minNumber = null;
        this.maxNumber = null;
        this.itemDetailCode = "";
        this.name = "";
        this.details = null;
        this.state = 0;
        this.sortOrder = 0;
        this.created = 0;
        this.modified = 0;
        this.itemDetailContents = []
        this.isCourseF = false
        this.isOpenItemDetailContents = true
    }

    setCourseF() {
        if (this.details == null) {
            this.isCourseF = false
            return this
        }
        const detailJson: Details = toCamel(JSON.parse(this.details))
        this.isCourseF = detailJson.courseF != null && detailJson.courseF == 1
        return this
    }

}
