export interface TableModeDto {
    shopCode: string,
    code: string,
    userId: string
}

export default class TableMode implements TableModeDto {
    code: string;
    shopCode: string;
    userId: string;

    constructor() {
        this.code = ""
        this.shopCode = ""
        this.userId = ""
    }
}