import {makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import Tip from "../../../models/tip";
import {AttachmentService} from "../../../services";
import {SystemConstant} from "../../../const";
import clsx from "clsx";
import {getPriceFormatYen} from "../../../utils/lang.utils";
import LangConst from "../../../const/lang.const";
import {useTranslation} from "react-i18next";

interface TipItem {
    tip: Tip,
    tipForStaff: (tip: Tip) => void,
}

const TipItem = (props: TipItem) => {
    const {tip, tipForStaff} = props;
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_CART);
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        if (tip.attachmentId) {
            AttachmentService.readAttachment({
                type: SystemConstant.ATTACHMENT_TYPE_TIP,
                id: tip.attachmentId,
            }).then(responseAttachment => {
                const encryptedBytes = new Uint8Array(responseAttachment.data as any);
                const blob = new Blob([encryptedBytes], {type: "application/image"})
                setImageUrl(URL.createObjectURL(blob));
            });
        }

        return () => {
            setImageUrl("");
        };
    }, []);

    return (
        <Grid item xs={4} sm={3} md={2}>
            <Box className={classes.root} onClick={() => tipForStaff(tip)}>
                {imageUrl ? <img
                    loading="lazy"
                    src={imageUrl}
                    className={classes.image}
                /> : <Box
                    className={classes.image}
                />}
                <Box className={classes.boxPrice}>
                    <Typography className={clsx(classes.yen, `light-xxs-txt hvt-font-family`)}>{getLabel(LangConst.TXT_CURRENCY_CART)}</Typography>
                    <Typography
                        className={clsx(classes.value, `light-sm-txt hvt-font-family`)}>{getPriceFormatYen(tip.value)}</Typography>
                </Box>
            </Box>
        </Grid>
    );
};


const useStyles = makeStyles(() => ({
    root: {
        width: 68,
        height: 97,
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
        padding: "0px important",
    },
    image: {
        width: 60,
        height: 60,
        margin: "4px 4px 0px 4px",
        objectFit: "cover"
    },
    yen: {
        marginRight: 4,
        fontWeight: "bold",
    },
    value: {
        fontWeight: "bold",
        textAlign: "center",
    },
    boxPrice: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "center",
    }
}));

export default TipItem;
