import React, {useEffect, useState} from "react";
import {Avatar, Button, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import LangConst from "../../const/lang.const";
import CustomerInput from "./components/CustomerInput";
import CustomerButtonLanguage from "./components/CustomerButtonLanguage";
import {makeStyles} from "@material-ui/core";
import {KeyConstant, PathConstant} from "../../const";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import TableActivityAction from "../../redux/table.activity.redux";
import HodaiAction from "../../redux/hodai.redux";
import SettingMoTopAction, {requestSettingMoTop} from "../../redux/setting.mo.top.redux";
import LanguageAction, {requestReadLanguage} from "../../redux/language.redux";
import {IStateRedux} from "../../redux/state";
import TableMode from "../../models/table.mode";
import ErrorModal from "../../components/ErrorModal";
import LoadingProgress from "../../components/LoadingProgress";
import MainLayout from "../../components/MainLayout";
import {getShopCode} from "../../utils/shop.util";
import {getLiffId, getTableActivityCode, setSearchQuery} from "../../utils";
import theme from "../../theme/material";
import i18next from "i18next";
import humps from "humps";

const CheckIn = () => {
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_CHECK_IN);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isTableActivityFetching = useSelector((state: IStateRedux) => state.tableActivityRedux.isFetching)
    const dataHodaiRead = useSelector((state: IStateRedux) => state.hodaiRedux.data)
    const isTableActivityCreated = useSelector((state: IStateRedux) => state.tableActivityRedux.isCreated)
    const tableActivityError = useSelector((state: IStateRedux) => state.tableActivityRedux.error)
    const tableActivityCode = useSelector((state: IStateRedux) => state.tableActivityRedux.tableActivityCode)
    const dataMoSettingTop = useSelector((state: IStateRedux) => state.settingMoTopRedux.dataSettingMoTop)
    const dataLanguage = useSelector((state: IStateRedux) => state.languageRedux.data)
    const [isInvalidTableActivity, setIsInvalidTableActivity] = useState(false)

    const user = {
        name: "",
        avatar: null,
        guestNumber: 1,
        language: LangConst.DEFAULT_LANG,
    }

    const [userState, setUserState] = useState(user);

    const onChangeGuestNumber = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserState({...userState, [name]: value});
    };

    const onChangeLanguage = (e: any) => {
        const name = e.target.name;
        const value = e.target.getAttribute('data-language');
        if (value) {
            localStorage.setItem(KeyConstant.KEY_LANG, value);
            i18next.changeLanguage(humps.camelize(value));
            setUserState({...userState, [name]: value});
        }
    };

    const createTableActivity = () => {
        const tableMode = localStorage.getItem(KeyConstant.KEY_TABLE_MODE)
        if (tableMode) {
            const tableModeJson: TableMode = JSON.parse(tableMode)
            const createTableActivityRequestData = {
                shopCode: tableModeJson.shopCode,
                tableCode: tableModeJson.code,
                tableActivityCustomerNumber: userState.guestNumber,
                userId: tableModeJson.userId
            }
            dispatch(TableActivityAction.requestCreateTableActivity(createTableActivityRequestData))
        } else {
            setIsInvalidTableActivity(true)
        }
    }

    const getHodai = () => {
        dispatch(HodaiAction.requestHodaiRead({
            shopCode: getShopCode(),
        }));
    }

    const onSubmit = () => {
        createTableActivity();
        getHodai();
    }

    useEffect(() => {
        if (dataHodaiRead.isHodai !== null && isTableActivityCreated && tableActivityCode) {
            localStorage.setItem(KeyConstant.KEY_HODAI_CODE, dataHodaiRead.hodaiCode)
            if (dataHodaiRead.isHodai) {
                navigate({
                    pathname: PathConstant.CONFIRM_HODAI,
                    search: setSearchQuery(getShopCode(), tableActivityCode, getLiffId())
                })
            } else {
                navigate({
                    pathname: PathConstant.CATEGORY,
                    search: setSearchQuery(getShopCode(), tableActivityCode, getLiffId())
                })
            }
        }
    }, [dataHodaiRead.isHodai, isTableActivityCreated, tableActivityCode]);

    useEffect(() => {
        const moCustomer = localStorage.getItem(KeyConstant.KEY_MO_CUSTOMER)
        if (moCustomer !== null) {
            const profile = JSON.parse(moCustomer)
            setUserState({
                ...userState,
                name: profile.displayName,
                avatar: profile.pictureUrl
            })
        }
        dispatch(SettingMoTopAction.requestSettingMoTop({
            shopCode: getShopCode(),
        }));
        dispatch(LanguageAction.requestReadLanguage());
        localStorage.setItem(KeyConstant.KEY_LANG, LangConst.DEFAULT_LANG);
    }, [])

    useEffect(() => {
        if (tableActivityError != null) {
            setIsInvalidTableActivity(true)
        }
    }, [tableActivityError])

    useEffect(() => {
        dispatch(SettingMoTopAction.requestSettingMoTop({
            shopCode: getShopCode(),
        }));
        dispatch(LanguageAction.requestReadLanguage());
        localStorage.setItem(KeyConstant.KEY_LANG, LangConst.DEFAULT_LANG);
    }, []);

    return (!isInvalidTableActivity)
        ? (isTableActivityFetching)
            ? <LoadingProgress/>
            : (
                <MainLayout customClasses={classes.root}>
                    <Grid container={true}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.checkInWrap}>
                        <Typography
                            className={`${classes.guideline} hgp-w6-font-family dark-lg-txt`}>{getLabel(LangConst.TXT_CHECK_IN_TITLE)}</Typography>
                        {(dataMoSettingTop == null || dataMoSettingTop.checkIn == 1) &&
                            <CustomerInput guestNumber={userState.guestNumber}
                                           onChangeGuestNumber={onChangeGuestNumber}/>}
                        <CustomerButtonLanguage
                            languageArray={dataMoSettingTop ? JSON.parse(dataMoSettingTop.languageChangingOption) : []}
                            languageData={dataLanguage}
                            selectedLanguage={userState.language}
                            onChangeLanguage={onChangeLanguage}></CustomerButtonLanguage>
                        <Button variant="contained" className={`${classes.btnNext} light-md-txt`} fullWidth={true}
                                onClick={onSubmit}>
                            {getLabel(LangConst.TXT_GO_TO_MENU)}
                        </Button>
                    </Grid>
                </MainLayout>)
        : <ErrorModal/>
};

export default CheckIn;

const useStyles = makeStyles(() => ({
    root: {
        padding: 8
    },
    checkInWrap: {
        padding: "32px 16px",
        backgroundColor: theme.palette.background.default,
        margin: 16,
        width: "auto",
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
    },
    customerInfo: {
        paddingTop: 24,
        paddingBottom: 32,
        paddingLeft: 8,
        paddingRight: 8
    },
    avatar: {
        width: 60,
        height: 60
    },
    customerName: {
        paddingLeft: 16,
    },
    guideline: {
        width: "100%",
        whiteSpace: "pre-line",
        textAlign: "center",
        color: "#000000",
    },
    btnNext: {
        height: 44,
        backgroundColor: "#ffaf31",
        color: "black",
        marginTop: 48,
    }
}));
