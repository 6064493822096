import * as React from 'react';
import {makeStyles} from "@material-ui/core";
import theme from "../../../theme/material";
import {ImageConstant, PathConstant} from "../../../const";
import clsx from "clsx";
import {Typography, Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {getLiffId, getTableActivityCode, setSearchQuery} from "../../../utils";
import { getShopCode } from '../../../utils/shop.util';
import {useTranslation} from "react-i18next";
import LangConst from "../../../const/lang.const";

export default function OrderSuccessPopup() {
    const classes = useStyles();
    const navigate = useNavigate();
    const {t: getLabel} = useTranslation(LangConst.NS_CART);

    const onHistoryScreen = () => {
        navigate({
            pathname: PathConstant.HISTORY,
            search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
        });
    }

    return (
        <Box className={clsx(classes.box)}>
            <Box className={classes.notification}>
                <img src={ImageConstant.RoundSelectedIcon} className={classes.image}/>
                <Typography className={clsx(classes.text, `light-sm-txt`)}>{getLabel(LangConst.TXT_ORDER_IS_COMPLETE)}</Typography>
            </Box>
            <Typography
                className={clsx(classes.textHistory, `light-md-txt`)}
                onClick={onHistoryScreen}>{getLabel(LangConst.TXT_CHECK_ORDER_HISTORY)}</Typography>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    box: {
        position: "absolute",
        bottom: "15px",
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        width: '100%',
        height: 56,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:focus-visible': {
            outline: "unset",
        },
        zIndex: 11111,
        boxShadow: "4px 4px 8px 0 rgba(0, 0, 0, 0.1)",
    },
    notification: {
        display: "flex"
    },
    image: {
        width: 24,
        height: 24,
        marginRight: 8,
    },
    text: {
        color: theme.palette.common.white,
    },
    textHistory: {
        color: "#ffaf31",
        textDecoration: "underline",
    }
}));
