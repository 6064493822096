import {AnyAction} from "redux";
import {toCamel, toSnake} from "../utils";
import {call, put} from "redux-saga/effects";
import {OrderStatusService} from "../services";
import {ApiConstant, SystemConstant} from "../const";
import OrderStatusAction from "../redux/order.status.redux";
import OrderStatus from "../models/order.status";

export interface ResponseGenerator {
    data?: any,
    status?: number,
}

export function* requestReadOrderStatus(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(OrderStatusService.readOrderStatus, snakeData);
        const orderStatusData = toCamel(response.data);

        if (response.status === ApiConstant.STT_OK && orderStatusData) {
            const orderStatusList: OrderStatus[] = []
            const orderStatus = Object.assign(orderStatusList, orderStatusData)
            yield put(OrderStatusAction.readOrderStatusSuccess(orderStatus))
        } else {
            yield put(OrderStatusAction.readOrderStatusFailure(response.data));
        }
    } catch (error) {
        yield put(OrderStatusAction.readOrderStatusFailure(error));
    }
}

function sleep(milliseconds: number) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}
