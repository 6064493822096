import React, {useEffect, useState} from "react";
// material
import {AppBar, Badge, Stack,} from '@mui/material';
// components
import {makeStyles} from "@material-ui/core";
import {ImageConstant, KeyConstant, LangConstant, PathConstant, SystemConstant} from "../const";
import {useLocation, useNavigate} from "react-router-dom";
import {
    detectUserOrder,
    getFailedOrderStatus,
    getLiffId,
    getTableActivityCode,
    hasLogin,
    removeNewOrder,
    setSearchQuery,
    uuid
} from "../utils";
import {palette} from "../theme/material";
import {getItemQuantity} from "../utils/cart.utils";
import {useDispatch, useSelector} from "react-redux";
import {IStateRedux} from "../redux/state";
import {getShopCode} from "../utils/shop.util";
import Box from "@mui/material/Box";
import OrderStatusAction, {failedDetecting} from "../redux/order.status.redux";
import TableActivity from "../models/table.activity";
import Cookies from 'js-cookie';
import {useTranslation} from "react-i18next";
import LangConst from "../const/lang.const";
import i18next from "i18next";
import humps from "humps";
import clsx from "clsx";

export const APPBAR_MOBILE = 89;

interface MenuItem {
    path: string,
    iconActive: string,
    icon: string,
    label: string
}

export default function MenuBar() {
    const {t: getLabel} = useTranslation(LangConst.NS_COMMON);
    const menuItems: MenuItem[] = [
        {
            path: PathConstant.CATEGORY,
            iconActive: ImageConstant.MenuActiveIcon,
            icon: ImageConstant.MenuIcon,
            label: getLabel(LangConst.TXT_MENU)
        },
        {
            path: PathConstant.CALL,
            iconActive: ImageConstant.CallActiveIcon,
            icon: ImageConstant.CallIcon,
            label: getLabel(LangConstant.TXT_CALL)
        },
        {
            path: PathConstant.HISTORY,
            iconActive: ImageConstant.HistoryActiveIcon,
            icon: ImageConstant.HistoryIcon,
            label: getLabel(LangConstant.TXT_HISTORY)
        },
        {
            path: PathConstant.PAYMENT,
            iconActive: ImageConstant.PaymentActiveIcon,
            icon: ImageConstant.PaymentIcon,
            label: getLabel(LangConstant.TXT_PAYMENT)
        }
    ]

    const classes = useStyles();
    const {pathname} = useLocation();
    const cart = useSelector((state: IStateRedux) => state.cartRedux.data)
    let itemNumber = getItemQuantity()
    const isLoggedIn = useSelector((state: IStateRedux) => state.authRedux.isLoggedIn)
    const tableActivities = useSelector((state: IStateRedux) => state.tableActivityRedux.data)
    const orderStatusList = useSelector((state: IStateRedux) => state.orderStatusRedux.status)
    const isFailedDetected = useSelector((state: IStateRedux) => state.orderStatusRedux.isFailedDetected)
    const [logged, setLogged] = useState(hasLogin())
    const [isShowMenuBar, setIsShowMenuBar] = useState(true);
    const [isValidPath, setIsValidPath] = useState(true)
    const [indexMenuItemActive, setIndexMenuItemActive] = useState(1)
    const [failedState, setFailedState] = useState(getFailedOrderStatus())
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        itemNumber = getItemQuantity()
    }, [cart])

    useEffect(() => {
        if (isLoggedIn) {
            setLogged(hasLogin())
        }
    }, [isLoggedIn])

    useEffect(() => {
        let indexMenuDefault = 0
        menuItems.forEach((value, index) => {
            if (value.path == pathname) {
                indexMenuDefault = index
            }
        })
        setIndexMenuItemActive(indexMenuDefault)
        if (pathname === PathConstant.TIP || pathname === PathConstant.STAFF) {
            setIsShowMenuBar(false);
        } else {
            setIsShowMenuBar(true);
        }
    }, [pathname])

    useEffect(() => {
        const language = localStorage.getItem(KeyConstant.KEY_LANG);
        if (language) {
            i18next.changeLanguage(humps.camelize(language));
        }
    }, [])

    useEffect(() => {
        if (orderStatusList.length > 0) {
            orderStatusList.forEach(value => {
                if (value.orderStatus == SystemConstant.ORDER_STATUS.FAILED && detectUserOrder(value.orderCode)) {
                    dispatch(OrderStatusAction.failedDetecting(true))
                }
                if (value.orderStatus != SystemConstant.ORDER_STATUS.PROCESSING) {
                    removeNewOrder(value.orderCode)
                }
            })
        }
    }, [orderStatusList])

    useEffect(() => {
        if (!tableActivities || tableActivities.length == 0 || tableActivities[0].tableActivityState !== SystemConstant.STATE.ACTIVE) {
            setIsValidPath(false)
        } else {
            setIsValidPath(true)
            if (tableActivities && tableActivities.length > 0) {
                localStorage.setItem(KeyConstant.KEY_TABLE_ACTIVITY, JSON.stringify(tableActivities[0]))
            }
            Cookies.set(KeyConstant.KEY_TABLE_ACTIVITY, 'true')
        }
    }, [tableActivities])

    useEffect(() => {
        if (isFailedDetected != undefined && !getFailedOrderStatus()) {
            if (isFailedDetected) {
                localStorage.setItem(KeyConstant.KEY_FAILED_ORDER_DETECTED, "1")
            }
            setFailedState(isFailedDetected)
        }
    }, [isFailedDetected])

    const isPreview = localStorage.getItem(KeyConstant.KEY_IS_PREVIEW)
    const tableActivityJsonCookies = Cookies.get(KeyConstant.KEY_TABLE_ACTIVITY)
    return (
        // To keep UI layout will be not broke, isValidPath should be condition to render Stack instead of AppBar
        <AppBar
            className={clsx((logged && tableActivityJsonCookies == 'true' || (isPreview != null && isPreview == '1')) ? classes.root : classes.hidden, !isShowMenuBar && classes.hidden,`hgp-w6-font-family light-xs-txt`)}>
            <Stack direction="row" justifyContent="space-around" style={{opacity: isValidPath ? 1 : 0.5}}>
                {menuItems.map((menuItem, index) => (
                    <Box
                        onClick={(isPreview != null && isPreview == '1' && menuItem.path != PathConstant.CATEGORY) ? undefined : () => navigate({
                            pathname: menuItem.path,
                            search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
                        })} key={uuid()}
                        className={indexMenuItemActive == index ? `${classes.menuItem} ${classes.menuItemActive}` : `${classes.menuItem}`}
                    >
                        {menuItem.path == PathConstant.CART ?
                            <Badge color="error"
                                   badgeContent={(isPreview != null && isPreview == '1') ? null : itemNumber}
                                   className={`${classes.badge} hp-w6-font-family light-xxs-txt`}>
                                <img src={indexMenuItemActive == index ? menuItem.iconActive : menuItem.icon}
                                     className={classes.icon}/>
                            </Badge>
                            : (menuItem.path == PathConstant.HISTORY && failedState) ?
                                <Badge color="error" badgeContent={(isPreview != null && isPreview == '1') ? null : ""}
                                       className={classes.badge}>
                                    <img src={indexMenuItemActive == index ? menuItem.iconActive : menuItem.icon}
                                         className={classes.icon}/>
                                </Badge>
                                : (menuItem.path == PathConstant.PAYMENT && failedState) ?
                                    <Badge color="error"
                                           badgeContent={(isPreview != null && isPreview == '1') ? null : ""}
                                           className={classes.badge}>
                                        <img src={indexMenuItemActive == index ? menuItem.iconActive : menuItem.icon}
                                             className={classes.icon}/>
                                    </Badge> :
                                    <img src={indexMenuItemActive == index ? menuItem.iconActive : menuItem.icon}
                                         className={classes.icon}/>
                        }
                        {menuItem.label}
                    </Box>
                ))}
            </Stack>
        </AppBar>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        backgroundColor: theme.palette.background.paper,
        height: APPBAR_MOBILE,
        position: "unset",
        top: "unset",
        bottom: "unset",
        borderTop: `1px solid ${palette.divider}`,
        padding: 10
    },
    hidden: {
        display: 'none'
    },
    menuItem: {
        fontSize: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        color: "#ababab",
        "&:hover": {
            textDecoration: "none",
        },
    },
    menuItemActive: {
        color: "#ffaf31"
    },
    icon: {
        width: 24,
        height: 26,
    },
    badge: {
        top: 2
    }
}));
