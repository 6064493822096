import React, {FC, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import PageTitle from "./PageTitle";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {IStateRedux} from "../redux/state";
import TableActivityAction from "../redux/table.activity.redux";
import {parse} from "qs";
import {useLocation} from "react-router-dom";
import {KeyConstant, SystemConstant} from "../const";
import ErrorModal from "./ErrorModal";
import {useTranslation} from "react-i18next";
import LangConst from "../const/lang.const";

interface Props {
    title?: string | undefined,
    children: any,
    customClasses: any
}

const MainLayout: FC<Props> = ({title, children, customClasses, ...otherProps}) => {
    const dispatch = useDispatch();
    const tableActivities = useSelector((state: IStateRedux) => state.tableActivityRedux.data)
    const isSuccess = useSelector((state: IStateRedux) => state.tableActivityRedux.isSuccess)
    const readTableError = useSelector((state: IStateRedux) => state.tableActivityRedux.error)
    const [error, setError] = useState(false)
    const searchQuery = parse(useLocation().search, {ignoreQueryPrefix: true})
    const {t: getLabel} = useTranslation();

    useEffect(() => {
        if (searchQuery.shopCode && searchQuery.tableActivityCode) {
            dispatch(TableActivityAction.requestReadTableActivity({
                shopCode: searchQuery.shopCode,
                tableActivityCode: searchQuery.tableActivityCode
            }))
        }
    }, [])

    useEffect(() => {
        if (tableActivities && tableActivities.length > 0 && tableActivities[0].tableActivityState == SystemConstant.STATE.ACTIVE) {
            setError(false)
        } else if ((isSuccess && searchQuery.shopCode && searchQuery.tableActivityCode)) {
            setError(true)
        }
    },[tableActivities])

    useEffect(() => {
        if (readTableError) setError(true)
        else setError(false)
    }, [readTableError])

    return (
        <>
            {error ?
                <ErrorModal title={getLabel(LangConst.TXT_UNKNOWN_ERROR)}/> :
                <>
                    {title ? <PageTitle title={title}/> : <></>}
                    <Grid
                        {...otherProps}
                        className={clsx(title ? `container-with-header` : `container-without-header`, customClasses.body)}
                    >
                        {children}
                    </Grid>
                </>
            }
        </>
    )
}

export default MainLayout;
