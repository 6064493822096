export default {
    TXT_APP_NAME: "MOA",
    TXT_MENU: "Menu",
    TXT_CALL: "Call",
    TXT_HISTORY: "History",
    TXT_PAYMENT: "Payment",
    TXT_UNIT: "さん",
    TXT_YEN: "円",
    TXT_ITEM: "品",
    TXT_SOLD_OUT : "SOLDOUT",
    TXT_CURRENTLY_UNAVAILABLE: "Currently unavailable.",
    TXT_UNKNOWN_ERROR: "An error occurred",
    TXT_HOME_PAGE: "Start the camera and \n scan the QR code."
};
