import {AnyAction} from "redux";
import {put} from "redux-saga/effects";
import CartAction from "../redux/cart.redux";
import {KeyConstant} from "../const";
import OrderDetail from "../models/order.detail";
import {getCartData} from "../utils/cart.utils";
import {toSnake} from "../utils";

export function* requestDeleteItemInCart(action: AnyAction) {
    try {
        const cartData = localStorage.getItem(KeyConstant.KEY_CART);
        if (cartData != null) {
            const cartDataJson: { [key: string]: OrderDetail; } = JSON.parse(cartData)
            if (action.data != null && cartDataJson[action.data]) {
                delete cartDataJson[action.data]
            }
            localStorage.setItem(KeyConstant.KEY_CART, JSON.stringify({...cartDataJson}))
            yield put(CartAction.deleteItemInCartSuccess(getCartData()))
        } else {
            yield put(CartAction.deleteItemInCartFailure("Cart is empty"));
        }
    } catch (error) {
        yield put(CartAction.deleteItemInCartFailure(error));
    }
}

export function* requestChangeItemInCart(action: AnyAction) {
    try {
        const {data} = action;
        const orderNumber = data.orderNumber;
        const orderDetailCode = data.orderDetailCode;
        const cartData = localStorage.getItem(KeyConstant.KEY_CART);
        if (cartData != null) {
            const cartDataJson: { [key: string]: OrderDetail; } = JSON.parse(cartData)
            const cartDataUpdate = cartDataJson[orderDetailCode];
            if (orderNumber !== 0) {
                cartDataUpdate.itemDetailContent.map((value, index) => {
                    value.total = value.total && value.total / cartDataUpdate.orderNumber * orderNumber;
                    value.subtotal = value.subtotal && value.subtotal / cartDataUpdate.orderNumber * orderNumber;
                    value.orderNumber = value.orderNumber / cartDataUpdate.orderNumber * orderNumber;
                });
                cartDataUpdate.orderNumber = orderNumber;
                cartDataJson[orderDetailCode] = cartDataUpdate;
            } else {
                delete cartDataJson[orderDetailCode];
            }
            localStorage.setItem(KeyConstant.KEY_CART, JSON.stringify({...cartDataJson}))
            yield put(CartAction.changeItemInCartSuccess(getCartData()))
        } else {
            yield put(CartAction.changeItemInCartFailure("Change cart error"));
        }
    } catch (error) {
        yield put(CartAction.changeItemInCartFailure(error));
    }
}
