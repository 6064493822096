import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import LangConst from "../const/lang.const";
import palette from "../theme/material/palette";
import clsx from "clsx";
import {AttachmentService} from "../services";
import {SystemConstant} from "../const";

const BoxItem = (props: BoxItemProps) => {
    const {name, attachment, formatPrice, goToDetails, stock, isUseStock} = props;
    const classes = useStyles();
    const isSoldOut = stock != null && stock == 0 && isUseStock;
    const {t: getLabel} = useTranslation();
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        if (attachment) {
            AttachmentService.readAttachment({
                type: SystemConstant.ATTACHMENT_TYPE_ITEM,
                id: attachment,
            }).then(responseAttachment => {
                const encryptedBytes = new Uint8Array(responseAttachment.data as any);
                const blob = new Blob([encryptedBytes], {type: "application/image"})
                setImageUrl(URL.createObjectURL(blob));
            });
        }

        return () => {
            setImageUrl("");
        };
    }, [attachment])

    return <>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}
              onClick={isSoldOut ? undefined : goToDetails}>
            <Box className={classes.root}>
                {imageUrl ? <img
                    loading="lazy"
                    src={imageUrl}
                    className={classes.image}
                /> : <Box className={classes.boxNameSpecial}>
                    <Box className={classes.boxName}>
                        <Typography
                            className={clsx(classes.nameSpecial, classes.name, `hgp-w6-font-family-normal light-xs-txt`)}>
                            {name}
                        </Typography>
                    </Box>
                </Box>}
                <Typography className={clsx(classes.name, `hgp-w6-font-family-normal light-xs-txt`)}>
                    {attachment && name}
                </Typography>
                <Box className={classes.boxPrice}>
                    <Typography className={clsx(classes.yen, `hvt-font-family light-xs-txt`)}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}</Typography>
                    <Typography className={`hgp-w6-font-family-normal dark-lg-txt`}>
                        {formatPrice}
                    </Typography>
                </Box>
                {isSoldOut &&
                    <Box className={`${classes.stockWrap} hgp-w6-font-family light-xls-txt`}>
                        {getLabel(LangConst.TXT_SOLD_OUT)}
                    </Box>
                }
            </Box>
        </Grid>
    </>
}

interface BoxItemProps {
    name: string,
    attachment: number | null,
    formatPrice: string,
    goToDetails: (e: any) => void,
    stock?: number | null,
    isUseStock?: boolean
}

BoxItem.defaultProps = {
    name: "",
    attachment: "",
    formatPrice: "",
    stock: null,
    isUseStock: false
}
export default BoxItem

const HEIGHT_BOX = 166;
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        height: HEIGHT_BOX,
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
        position: "relative",
    },
    image: {
        height: 88,
        width: "100%",
        objectFit: "cover",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    name: {
        height: 44,
        maxWidth: "100%",
        lineHeight: 1.5,
        padding: "8px 8px 0 8px",
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    nameSpecial: {
        width: "100%",
        height: 36,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: "0 8px",
    },
    boxPrice: {
        height: 30,
        width: "100%",
        display: "flex",
        padding: "0 8px 4px",
        alignItems: "baseline",
    },
    yen: {
        lineHeight: 1.5,
        color: "#2d2d2d",
        marginRight: 4,
        fontWeight: "bold",
    },
    boxNameSpecial: {
        height: 88,
        width: "100%",
        padding: 8,
    },
    boxName: {
        borderRadius: 4,
        border: "1px solid #dddddd",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    stockWrap: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        position: "absolute",
        zIndex: 2,
        top: 0,
        left: 0,
        color: palette.white,
        textAlign: "center"
    }
}));
