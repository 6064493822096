import {createTheme} from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import components from "./components";
import breakpoints from "./breakpoints";

const theme = createTheme({
  palette,
  typography,
  components,
  breakpoints
});

export default theme;

export { palette, typography, breakpoints };
