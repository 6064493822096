import {MoPeriod} from "./category";
import {toCamel} from "../utils";
import SystemConstant from "../const/system.const";
import OrderDetail from "./order.detail";

export class SelectItemList {
    itemCode: string;
    itemName: string;
    multiName: any;

    constructor() {
        this.itemCode = "";
        this.itemName = "";
        this.multiName = [];
    }
}


export default class Hodai {
    hodaiCode: string;
    shopCode: string;
    name: string;
    description: string | null;
    details: string | null;
    moPeriod?: string | null;
    moPeriodObject?: MoPeriod;
    moDayOfWeek?: string | null;
    moDayOfWeekArray?: number[] | null;
    moDisplay: number;
    moSortOrder: number;
    sortOrder: number;
    itemNumber: number;
    state: number;
    created: number;
    modified: number;
    isHodai: boolean | null;
    price: number;
    priceTaxIn: number;
    multiName: any;
    stock: number | null;
    tax: number;
    itemCode: string;
    selectItemList: SelectItemList[];
    orderNumber: number;
    orderDetails: OrderDetail[];

    constructor() {
        this.hodaiCode = "";
        this.shopCode = "";
        this.name = "";
        this.description = "";
        this.details = "";
        this.moPeriod = null;
        this.moPeriodObject = new MoPeriod();
        this.moDayOfWeek = null;
        this.moDayOfWeekArray = null;
        this.moDisplay = 0;
        this.moSortOrder = 0;
        this.sortOrder = 0;
        this.itemNumber = 0;
        this.state = SystemConstant.STATE.ACTIVE;
        this.created = 0;
        this.modified = 0;
        this.isHodai = null;
        this.price = 0;
        this.priceTaxIn = 0;
        this.multiName = {};
        this.stock = null;
        this.tax = 0;
        this.itemCode = "";
        this.selectItemList = [];
        this.orderNumber = 0;
        this.orderDetails = [];
    }

    setMoPeriod() {
        if (this.moPeriod) {
            this.moPeriodObject = toCamel(JSON.parse(this.moPeriod))
        }
        return this
    }

    setMoDayOfWeek() {
        if (this.moDayOfWeek) {
            this.moDayOfWeekArray = JSON.parse(this.moDayOfWeek)
        }
        return this
    }

    setOrderNumber() {
        this.orderNumber = 0
        return this
    }
}
