import palette from "./palette";

export default {
    fontFamily: ["HiraginoKakuGothicPro-W3"].join(","),
    h1: {
        color: palette.black,
        fontWeight: "normal",
        fontSize: 28,
        letterSpacing: "normal",
        lineHeight: 1.71,
    },
    h2: {
        color: palette.black,
        fontWeight: "normal",
        fontSize: 28,
        letterSpacing: "normal",
        lineHeight: 1,
    },
    h3: {
        color: palette.black,
        fontWeight: "normal",
        fontSize: 24,
        letterSpacing: "normal",
        lineHeight: 1.13,
    },
    h4: {
        color: palette.black,
        fontWeight: "normal",
        fontSize: 24,
        letterSpacing: "normal",
        lineHeight: 1.71,
    },
    h5: {
        color: palette.black,
        fontWeight: "normal",
        fontSize: 22,
        letterSpacing: "normal",
        lineHeight:  1.32,
    },
    h6: {
        color: palette.black,
        fontWeight: "normal",
        fontSize: 18,
        letterSpacing: "normal",
        lineHeight:  1.61,
    },
    subtitle1: {
        color: palette.grey.warmGrey,
        fontWeight: "normal",
        fontSize: 14,
        letterSpacing: "normal",
        lineHeight: 1.79,
    },
    subtitle2: {
        color: palette.grey[700],
        fontWeight: 600,
        fontSize: 15,
        letterSpacing: "normal",
        lineHeight: "normal",
    },
    body1: {
        color: palette.grey[700],
        fontWeight: 500,
        fontSize: 15,
        letterSpacing: "normal",
        lineHeight: "normal",
    },
    body2: {
        color: palette.grey[700],
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: "normal",
        lineHeight: "normal",
    },
    button: {
        color: palette.white,
        fontSize: 24,
        letterSpacing: "normal",
        lineHeight: 1.13,
    },
    buttonSmall: {
        color: palette.white,
        fontSize: 18,
        letterSpacing: "normal",
        lineHeight: 1,
    },
};
