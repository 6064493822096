import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import {Hodai} from "../models";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestHodaiRead: ["data"],
    hodaiReadSuccess: ["data"],
    hodaiReadFailure: ["error"],

    requestPlanRead: ["data"],
    planReadSuccess: ["dataHodaiItem"],
    planReadFailure: ["error"],

    requestPlanItemRead: ["data"],
    planItemReadSuccess: ["dataHodaiItemList"],
    planItemReadFailure: ["error"],
});

export const HodaiTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface HodaiStateRedux extends IDefaultStateProps {
    data: Hodai;
    isSuccess: boolean;
    dataHodaiItem: Hodai[];
    dataHodaiItemList: Hodai[]
}

export const INITIAL_STATE: HodaiStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: new Hodai(),
    dataHodaiItem: [],
    dataHodaiItemList: [],
};

/* ------------- Reducers ------------- */

export const requestHodaiRead = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    error: null,
    isFetching: true,
});

export const hodaiReadSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action,
    isSuccess: true,
});

export const hodaiReadFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error,
    isSuccess: false
});

export const requestPlanRead = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    error: null,
    isFetching: true,
});

export const planReadSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const planReadFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error,
    isSuccess: false
});


export const requestPlanItemRead = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    error: null,
    isFetching: true,
});

export const planItemReadSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const planItemReadFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error,
    isSuccess: false
});
/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_HODAI_READ]: requestHodaiRead,
    [Types.HODAI_READ_SUCCESS]: hodaiReadSuccess,
    [Types.HODAI_READ_FAILURE]: hodaiReadFailure,

    [Types.REQUEST_PLAN_READ]: requestPlanRead,
    [Types.PLAN_READ_SUCCESS]: planReadSuccess,
    [Types.PLAN_READ_FAILURE]: planReadFailure,

    [Types.REQUEST_PLAN_ITEM_READ]: requestPlanItemRead,
    [Types.PLAN_ITEM_READ_SUCCESS]: planItemReadSuccess,
    [Types.PLAN_ITEM_READ_FAILURE]: planItemReadFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
