import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import MainLayout from "../../components/MainLayout";
import theme, {palette} from "../../theme/material";
import LangConst from "../../const/lang.const";
import {useTranslation} from "react-i18next";
import {Box, Button, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {IStateRedux} from "../../redux/state";
import clsx from "clsx";
import {getPriceFormatYen} from "../../utils/lang.utils";
import {KeyConstant, SystemConstant} from "../../const";
import TableActivity from "../../models/table.activity";
import {getShopInfo} from "../../utils";
import HistoryAction from "../../redux/history.redux";
import SettingMoPaymentAction from "../../redux/setting.mo.payment.redux";
import {getShopCode} from "../../utils/shop.util";

const PaymentPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t: getLabel} = useTranslation(LangConst.NS_PAYMENT);
    const [isPayment, setIsPayment] = useState(localStorage.getItem(KeyConstant.KEY_IS_PAYMENT) ? (localStorage.getItem(KeyConstant.KEY_IS_PAYMENT) == SystemConstant.STATE.ACTIVE.toString()) : false);
    const [isPaymentConfirmation, setIsPaymentConfirmation] = useState(false);
    const [isCancelTheCheckout, setIsCancelTheCheckout] = useState(false);
    const historyReduxData = useSelector((state: IStateRedux) => state.historyRedux.data);
    const dataMoSettingPayment = useSelector((state: IStateRedux) => state.settingMoPaymentRedux.dataSettingMoPayment);

    let tableActivityCode: string | null = null;
    const tableActivity = localStorage.getItem(KeyConstant.KEY_TABLE_ACTIVITY)
    if (tableActivity) {
        const tableActivityJson: TableActivity = JSON.parse(tableActivity)
        if (tableActivityJson) {
            tableActivityCode = tableActivityJson.tableActivityCode
        }
    }

    const customClasses = {
        body: `${classes.containerPayment}`
    }

    const successfulPaymentConfirmation = () => {
        setIsPayment(true);
        localStorage.setItem(KeyConstant.KEY_IS_PAYMENT, SystemConstant.STATE.ACTIVE.toString());
        setIsPaymentConfirmation(false);
    }

    const cancelTheCheckout = () => {
        setIsCancelTheCheckout(true);
        //TODO call api table activity read
    }

    const closeCancelCheckOutConfirmation = () => {
        setIsCancelTheCheckout(false);
        setIsPayment(false);
        localStorage.setItem(KeyConstant.KEY_IS_PAYMENT, SystemConstant.STATE.INACTIVE.toString());
    }

    const paymentConfirmation = isPaymentConfirmation && <Box className={classes.boxPaymentConfirmation}>
      <Box className={classes.containerPaymentConfirmation}>
        <Typography className={`light-sm-txt`} sx={{textAlign: "center", padding: "0px 20px"}}>
            {getLabel(LangConst.TXT_AFTER_CONFIRMING_PAYMENT)}
        </Typography>
        <Button className={classes.paymentConfirmation}
                onClick={successfulPaymentConfirmation}>{getLabel(LangConst.TXT_YES, {ns: LangConst.NS_CALL})}</Button>
        <Button className={classes.cancel}
                onClick={() => setIsPaymentConfirmation(false)}>{getLabel(LangConst.TXT_CANCEL, {ns: LangConst.NS_CART})}</Button>
      </Box>
    </Box>

    const popupCancelTheCheckout = isCancelTheCheckout && <Box className={classes.boxPaymentConfirmation}>
      <Box className={classes.containerPaymentConfirmation}>
        <Typography className={classes.stopPayment}>{getLabel(LangConst.TXT_PAYMENT_STOP_PAYMENT)}</Typography>
        <Button
            className={classes.closeCancelCheckOut}
            onClick={closeCancelCheckOutConfirmation}>{getLabel(LangConst.TXT_CLOSE, {ns: LangConst.NS_CART})}</Button>
      </Box>
    </Box>

    useEffect(() => {
        if (tableActivityCode) {
            const searchData = {
                shopCode: getShopInfo().shopCode,
                tableActivityCode: tableActivityCode,
            };
            dispatch(HistoryAction.requestReadHistory(searchData));
            dispatch(SettingMoPaymentAction.requestSettingMoPayment({
                shopCode: getShopCode(),
            }));
        }
    }, []);

    return (
        <MainLayout customClasses={customClasses} title={getLabel(LangConst.TXT_PAYMENT_TITLE)}>
            {paymentConfirmation}
            {popupCancelTheCheckout}
            {isPayment ? <Box className={classes.billedAmountPaid}>
                {/*TODO Get name table*/}
                <Typography className={clsx(classes.nameTable, `hvt-font-family`)}>T01</Typography>
                <Typography
                    className={clsx(classes.pleaseShowScreenStaff, `light-md-txt hgp-w6-font-family-normal`)}>{dataMoSettingPayment?.guideText}</Typography>
            </Box> : <Box className={classes.billedAmountUnpaid}>
                <Typography
                    className={`light-md-txt hgp-w6-font-family-normal`}>{getLabel(LangConst.TXT_BILL_AMOUNT)}</Typography>
                <Box className={classes.boxPriceTaxIn}>
                    <Typography
                        className={clsx(classes.yen, `dark-lg-txt hvt-font-family`)}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}</Typography>
                    <Typography className={clsx(classes.total, `hvt-font-family`)}>
                        {getPriceFormatYen(historyReduxData.total)}
                    </Typography>
                    <Typography
                        className={clsx(classes.tax, `light-sm-txt hgp-w6-font-family-normal`)}>{getLabel(LangConst.TXT_PAYMENT_TAX_INCLUDED)}</Typography>
                </Box>
            </Box>}
            <Box className={classes.details}>
                <Box className={classes.detail}>
                    <Typography className={`light-sm-txt`}>{getLabel(LangConst.TXT_PAYMENT_SUBTOTAL)}</Typography>
                    <Typography
                        className={`light-sm-txt hvt-font-family`}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}{getPriceFormatYen(historyReduxData.subtotal)}</Typography>
                </Box>
                <Box className={classes.detail}>
                    <Typography className={`light-sm-txt`}>{getLabel(LangConst.TXT_PAYMENT_SALE_TAX)}</Typography>
                    <Typography
                        className={`light-sm-txt hvt-font-family`}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}{getPriceFormatYen(historyReduxData.total - historyReduxData.subtotal)}</Typography>
                </Box>
                <Box className={classes.detailSpecial}>
                    <Typography
                        className={`hgp-w6-font-family-normal dark-lg-txt`}>{getLabel(LangConst.TXT_PAYMENT_TOTAL)}</Typography>
                    <Typography
                        className={`light-xll-txt hvt-font-family`}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}{getPriceFormatYen(historyReduxData.total)}</Typography>
                </Box>
            </Box>
            {isPayment ? <Button className={classes.buttonPaid} onClick={cancelTheCheckout}>
                    {getLabel(LangConst.TXT_PAYMENT_CANCEL_CHECK_OUT)}
                </Button> :
                <Button className={classes.buttonUnpaid} onClick={() => setIsPaymentConfirmation(true)}>
                    {getLabel(LangConst.TXT_PAYMENT_CHECK_OUT)}
                </Button>}
        </MainLayout>
    );
}

const useStyles = makeStyles(() => ({
    containerPayment: {
        backgroundColor: palette.background.default,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
    },
    billedAmountUnpaid: {
        height: 146,
        width: "100%",
        borderBottom: "solid 0.5px #ddd",
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
        alignItems: "center",
    },
    billedAmountPaid: {
        width: "100%",
        height: 168,
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffaf31",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 16,
    },
    boxPriceTaxIn: {
        display: "flex",
        alignItems: "baseline",
    },
    yen: {
        marginRight: 4,
    },
    tax: {},
    total: {
        fontSize: 36,
        fontWeight: "bold",
        marginRight: 4,
    },
    details: {
        display: "flex",
        flexDirection: "column",
        padding: "16px 0px 26px",
    },
    detail: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 8,
    },
    detailSpecial: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 10,
    },
    buttonUnpaid: {
        width: "100%",
        height: 44,
        borderRadius: 4,
        backgroundColor: "#ffaf31 !important",
        fontSize: 16,
        color: theme.palette.common.black,
    },
    buttonPaid: {
        width: "100%",
        height: 44,
        borderRadius: 4,
        border: "solid 0.5px #ffaf31",
        backgroundColor: "#fff !important",
        color: "#ffaf31 !important   ",
        fontSize: 16,
    },
    nameTable: {
        fontSize: 48,
        color: theme.palette.common.white,
    },
    pleaseShowScreenStaff: {
        color: theme.palette.common.white,
        textAlign: "center"
    },
    boxPaymentConfirmation: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 11111,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    containerPaymentConfirmation: {
        width: 296,
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        padding: "32px 16px 16px",
    },
    paymentConfirmation: {
        width: 264,
        height: 44,
        borderRadius: 4,
        backgroundColor: "#ffaf31 !important",
        marginTop: 32,
        fontSize: 16,
        color: theme.palette.common.black,
    },
    cancel: {
        width: 264,
        height: 44,
        borderRadius: 4,
        border: "solid 0.5px #000",
        backgroundColor: theme.palette.common.white,
        marginTop: 16,
        fontSize: 16,
        color: theme.palette.common.black,
    },
    stopPayment: {
        fontSize: 14,
        textAlign: "center",
    },
    closeCancelCheckOut: {
        width: 264,
        height: 44,
        borderRadius: 4,
        fontSize: 14,
        color: theme.palette.common.black,
        marginTop: 32,
        backgroundColor: "#ffaf31 !important",

    }
}));

export default PaymentPage;
