import {AnyAction} from "redux";
import {call, put} from "redux-saga/effects";
import {LanguageService} from "../services";
import {ApiConstant} from "../const";
import {ResponseGenerator} from "../models";
import LanguageAction from "../redux/language.redux";
import humps from "humps";

export function* requestLanguage(action: AnyAction) {
    try {
        const response: ResponseGenerator = yield call(LanguageService.readLanguage);
        if (response.status === ApiConstant.STT_OK && response.data) {
            response.data.languages.map((value: any) => {
                value.code = humps.camelize(value.code);
            });
            yield put(LanguageAction.readLanguageSuccess(response.data.languages));
        } else {
            yield put(LanguageAction.readLanguageFailure(response.data.languages));
        }
    } catch
        (e) {
        yield put(LanguageAction.readLanguageFailure(e));
    }
}
