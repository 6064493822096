import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import LangConst from "../../const/lang.const";
import {Box, Button, Typography} from "@mui/material";
import theme from "../../theme/material";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {IStateRedux} from "../../redux/state";
import {KeyConstant, PathConstant, SystemConstant} from "../../const";
import {getLiffId, setSearchQuery, uuid} from "../../utils";
import {getShopCode} from "../../utils/shop.util";
import {useNavigate} from "react-router-dom";
import HodaiAction from "../../redux/hodai.redux";
import {Hodai} from "../../models";
import HodaiItem from "./compoments/HodaiItem";
import Order from "../../models/order";
import TableActivity from "../../models/table.activity";
import OrderDetail from "../../models/order.detail";
import OrderAction from "../../redux/order.redux";

const HodaiPlanSelectPage = () => {
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_HODAI);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeButton, setActiveButton] = useState(false);

    const dataHodaiPlanRead = useSelector((state: IStateRedux) => state.hodaiRedux.dataHodaiItem);
    const tableActivityCode = useSelector((state: IStateRedux) => state.tableActivityRedux.tableActivityCode);
    const responseOrderHodai = useSelector((state: IStateRedux) => state.orderRedux);

    const checkButtonSure = (itemCode: string, orderNumber: number) => {
        dataHodaiPlanRead.map((value) => {
            if (value.itemCode == itemCode) {
                value.orderNumber = orderNumber;
            }
        })
        let temp = false;
        if (dataHodaiPlanRead) {
            dataHodaiPlanRead.map((value) => {
                if (value.orderNumber > 0) {
                    temp = true;
                    return
                }
            })
        }
        setActiveButton(temp);
    }

    const returnScreenConfirmHodai = () => {
        navigate({
            pathname: PathConstant.CONFIRM_HODAI,
            search: setSearchQuery(getShopCode(), tableActivityCode, getLiffId())
        })
    }

    const useHodai = () => {
        const cartList = dataHodaiPlanRead.filter(value => value.orderNumber > 0);
        const tableActivity = localStorage.getItem(KeyConstant.KEY_TABLE_ACTIVITY);
        if (tableActivity) {
            const tableActivityJson: TableActivity = JSON.parse(tableActivity)
            const orderDetails = new Order();
            orderDetails.shopCode = getShopCode();
            orderDetails.tableActivityCode = tableActivityCode;
            orderDetails.discountPrice = tableActivityJson.discountPrice;
            orderDetails.discountType = tableActivityJson.discountType;
            orderDetails.discountValue = tableActivityJson.discountValue;
            cartList.map(itemDetailContent => {
                orderDetails.orderDetails.push({
                    orderNumber: itemDetailContent.orderNumber,
                    orderDetailCode: uuid(),
                    itemCode: itemDetailContent.itemCode,
                    itemName: itemDetailContent.name,
                    priceTaxIn: itemDetailContent.priceTaxIn * itemDetailContent.orderNumber,
                    price: itemDetailContent.price * itemDetailContent.orderNumber,
                    tax: itemDetailContent.tax,
                    discountPrice: tableActivityJson.discountPrice,
                    discountType: tableActivityJson.discountType,
                    discountValue: tableActivityJson.discountValue,
                } as OrderDetail)
            });
            dispatch(OrderAction.requestCreateOrder(orderDetails));
        }
    }

    useEffect(() => {
        dispatch(HodaiAction.requestPlanRead({
            shopCode: getShopCode(),
            hodaiCode: localStorage.getItem(KeyConstant.KEY_HODAI_CODE),
        }));
    }, []);

    useEffect(() => {
        if (responseOrderHodai.orderCode) {
            localStorage.setItem(KeyConstant.KEY_IS_HODAI, SystemConstant.STATE.ACTIVE.toString());
            navigate({
                pathname: PathConstant.CATEGORY,
                search: setSearchQuery(getShopCode(), tableActivityCode, getLiffId())
            });
        }
    }, [responseOrderHodai]);

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <Typography
                    className={clsx(classes.title, `hgp-w6-font-family dark-lg-txt`)}>{getLabel(LangConst.TXT_HODAI_TITLE_LIST)}</Typography>
                <Box className={classes.listHodaiItem}>
                    {dataHodaiPlanRead && dataHodaiPlanRead.map((item: Hodai) =>
                        <HodaiItem key={item.itemCode}
                                   value={item}
                                   checkButtonSure={checkButtonSure}
                        />
                    )}
                </Box>
                <Box className={classes.listButton}>
                    <Button
                        disabled={!activeButton}
                        className={clsx(classes.buttonSureNoActive, activeButton && classes.buttonSureActive, `light-md-txt`)}
                        onClick={useHodai}>{getLabel(LangConst.TXT_HODAI_SURE)}</Button>
                    <Button
                        className={clsx(classes.buttonReturn, `light-md-txt`)}
                        onClick={returnScreenConfirmHodai}>{getLabel(LangConst.TXT_HODAI_RETURN)}</Button>
                </Box>
            </Box>
        </Box>
    )
}
export default HodaiPlanSelectPage;

const useStyles = makeStyles(() => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "#f8f8f8",
        padding: 16,
    },
    title: {
        height: 78,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.common.black,
    },
    container: {
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
        padding: 16,
        position: "relative",
    },
    listHodaiItem: {
        width: "100%",
        position: "absolute",
        left: 0,
        top: 94,
        height: "calc(100% - 136px - 94px);",
        padding: "0px 16px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none"
        },
    },
    listButton: {
        height: 136,
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        padding: 16,
    },
    buttonSureNoActive: {
        width: "100%",
        height: 44,
        borderRadius: 4,
        backgroundColor: "rgba(255, 175, 49, 0.25) !important",
        color: "rgba(0, 0, 0, 0.25)",
    },
    buttonSureActive: {
        backgroundColor: "#ffaf31 !important",
        color: theme.palette.common.black,
    },
    buttonSure: {
        backgroundColor: "gba(255, 175, 49, 0.25) !important",
        color: "rgba(0, 0, 0, 0.25) !important",
    },
    buttonReturn: {
        marginTop: 16,
        width: "100%",
        height: 44,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white + "!important",
        color: "#2d2d2d",
        border: "solid 0.5px #2d2d2d"
    }
}));

