import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import SettingMoTop from "../models/setting.mo.top";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestSettingMoTop: ["data"],
    settingMoTopSuccess: ["data"],
    settingMoTopFailure: ["error"]
});

export const SettingMoTopTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface SettingMoTopRedux extends IDefaultStateProps {
    dataSettingMoTop?: SettingMoTop | null,
}

export const INITIAL_STATE: SettingMoTopRedux = {
    error: null,
    isFetching: false,
    dataSettingMoTop: null,
}

/* ------------- Reducers ------------- */
export const requestSettingMoTop = (state = INITIAL_STATE) => ({
    ...state,
    isFetching: true,
    error: null
});

export const settingMoTopSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    dataSettingMoTop: action.data
});

export const settingMoTopFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_SETTING_MO_TOP]: requestSettingMoTop,
    [Types.SETTING_MO_TOP_SUCCESS]: settingMoTopSuccess,
    [Types.SETTING_MO_TOP_FAILURE]: settingMoTopFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
