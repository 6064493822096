import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import {Language} from "../models";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestReadLanguage: ["data"],
    readLanguageSuccess: ["data"],
    readLanguageFailure: ["error"]
});

export const LanguageTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface LanguageStateRedux extends IDefaultStateProps {
    data: Language[];
    isSuccess: boolean;
}

export const INITIAL_STATE: LanguageStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: [],
};

/* ------------- Reducers ------------- */

export const requestReadLanguage = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    error: null,
    isSuccess: false,
    isFetching: true,
});

export const readLanguageSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readLanguageFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error,
    isSuccess: false
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_LANGUAGE]: requestReadLanguage,
    [Types.READ_LANGUAGE_SUCCESS]: readLanguageSuccess,
    [Types.READ_LANGUAGE_FAILURE]: readLanguageFailure
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
