export default {
    TXT_ITEMS_IN_CART: "カート内の商品",
    TXT_DELETE_ALL: "すべて削除",
    TXT_CONFIRMED_ORDER: "注文確定",
    TXT_ARE_YOU_SURE: "カート内から商品を削除しますか？",
    TXT_DELETE: "削除する",
    TXT_CANCEL: "キャンセル",
    TXT_ORDER_IS_COMPLETE: "注文が完了しました。",
    TXT_CHECK_ORDER_HISTORY: "注文履歴を確認",
    TXT_ITEM_SOLD_OUT: "売り切れのメニューがございます。\n" + "該当のメニューを削除して、もう一度\n" + "注文してください。",
    TXT_CLOSE: "閉じる",
    TXT_ERROR_STOCK_TEXT: "現在売り切れています。",
    TXT_ERROR_STOCK: "売り切れのメニューがございます。\n" + "該当のメニューを削除して、もう一度\n" + " 注文してください。",
    TXT_CURRENCY_CART: "¥",
    TXT_TOTAL_ORDER: "注文合計",
    TXT_TAX_INCLUDED: "（税込み）",
    TXT_ORDER_ERROR: "エラーが発生しました。もう一度やり直してください",
}
