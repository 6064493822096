export default {
    TXT_HODAI_TITLE: "放題プランを利用しますか？",
    TXT_HODAI_USE: "利用する",
    TXT_HODAI_DONT_USE: "利用しない",
    TXT_HODAI_SURE: "確定",
    TXT_HODAI_RETURN: "戻る",
    TXT_HODAI_TITLE_LIST: "プランを選択してください",
    TXT_HODAI_USUALLY: "通常",
    TXT_HODAI_UNLIMITED: "放題",
    TXT_TARGET_MENU: "対象メニュー",

}
