export default {
    TXT_HODAI_TITLE: "你想使用无限计划吗？",
    TXT_HODAI_USE: "使用",
    TXT_HODAI_DONT_USE: "不要使用",
    TXT_HODAI_SURE: "确定",
    TXT_HODAI_RETURN: "返回",
    TXT_HODAI_TITLE_LIST: "请选择一个计划",
    TXT_HODAI_USUALLY: "一般来说",
    TXT_HODAI_UNLIMITED: "无限",
    TXT_TARGET_MENU: "目标菜单"
}
