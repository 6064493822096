export default {
    TXT_ITEMS_IN_CART: "장바구니의 제품",
    TXT_DELETE_ALL: "모두 삭제",
    TXT_CONFIRMED_ORDER: "주문 확인",
    TXT_ARE_YOU_SURE: "장바구니에서 제품을 삭제하시겠습니까?",
    TXT_DELETE: "삭제",
    TXT_CANCEL: "취소",
    TXT_ORDER_IS_COMPLETE: "주문이 완료되었습니다.",
    TXT_CHECK_ORDER_HISTORY: "주문 내역 확인",
    TXT_ITEM_SOLD_OUT: "매진된 메뉴가 있습니다.\n" + "해당 메뉴를 삭제하고 다시 \n" + "주문하세요.",
    TXT_CLOSE: "닫기",
    TXT_ERROR_STOCK_TEXT: "현재 매진되었습니다",
    TXT_ERROR_STOCK: "매진 메뉴가 있습니다.\n" + "해당 메뉴를 삭제하고 다시\n" + "주문하십시오.",
    TXT_CURRENCY_CART: "¥",
    TXT_TOTAL_ORDER: "주문 합계",
    TXT_TAX_INCLUDED: "(세금 포함)",
    TXT_ORDER_ERROR: "에러가 발생했습니다 .. 다시 시도 해주세요",
}
