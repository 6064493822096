import {SystemConstant} from "../const";
import {toCamel} from "../utils";

export interface MoItemDetailCodes {
    isRequired: boolean,
    minNumber: number| null
    maxNumber: number | null,
    sortOrder: number | null
    itemDetailCode: string,
}

export interface Details {
    moAttachmentId?: number | null,
    moDescription?: string | null,
    moItemDetailCodes?: MoItemDetailCodes[] | null,
    defaultId?: string | null,
    multipleTax?: TaxInfo[] | null
}

export interface TaxInfo {
    taxRate: number,
    id: number
    priceTaxIn: number
}

export default class Item {
    // Set default
    itemDetailCode: string;
    categoryCode: string;
    itemCode: string;
    details: string | null;
    moDisplay: number;
    description: string | null;
    name: string;
    price: number;
    priceTaxIn: number;
    moSortOrder: number;
    sortOrder: number;
    state: number;
    stock: number | null;
    tax: number;
    created: number;
    modified: number | null;
    moAttachmentId?: number | null;
    attachment: string;
    moDescription?: string | null;
    moItemDetailCodes?: MoItemDetailCodes[] | null;
    multiName: any;
    multiMoDescription: any;

    constructor() {
        this.itemDetailCode = "";
        this.categoryCode = "";
        this.itemCode = "";
        this.details = null;
        this.moDisplay = 0;
        this.description = null;
        this.name = "";
        this.price = 0;
        this.priceTaxIn = 0;
        this.moSortOrder = 0;
        this.sortOrder = 0;
        this.state = SystemConstant.STATE.ACTIVE;
        this.stock = 0;
        this.tax = 0;
        this.created = 0;
        this.modified = null;
        this.moAttachmentId = null;
        this.attachment = "";
        this.moDescription = null;
        this.moItemDetailCodes = [];
        this.multiName = {};
        this.multiMoDescription = {};
    }

    setMoInfo() {
        if (this.details != null) {
            const jsonDetail = JSON.parse(this.details)
            const jsonDetailCamel: Details = toCamel(jsonDetail)

            const defaultTaxRate = jsonDetailCamel?.defaultId
            const multipleTax = jsonDetailCamel?.multipleTax
            if (defaultTaxRate && multipleTax) {
                const tax = multipleTax.find(value => value.id == parseInt(defaultTaxRate))
                if (tax && tax.taxRate) this.tax = tax.taxRate
            }

            this.moAttachmentId = jsonDetailCamel?.moAttachmentId;
            this.moDescription = jsonDetailCamel?.moDescription;

            const moItemDetailIdsObject = jsonDetailCamel?.moItemDetailCodes;
            if (moItemDetailIdsObject != null) {
                const itemDetailArray: MoItemDetailCodes[] = []
                for (const [key, value] of Object.entries(moItemDetailIdsObject)) {
                    const itemDetail: MoItemDetailCodes = {
                        isRequired: value.isRequired,
                        minNumber: value.minNumber,
                        maxNumber: value.maxNumber,
                        sortOrder: value.sortOrder,
                        itemDetailCode: key,
                    }
                    itemDetailArray.push(itemDetail)
                }

                this.moItemDetailCodes = itemDetailArray
            }
        }
        return this;
    };
}
