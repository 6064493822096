import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import Category from "../models/category";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestReadCategory: ["searchData"],
    readCategorySuccess: ["data"],
    readCategoryFailure: ["error"]
});

export const CategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface CategoryStateRedux extends IDefaultStateProps {
    data: Category[];
    isSuccess: boolean;
    searchData: any;
}

export const INITIAL_STATE: CategoryStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: [],
    searchData: {}
};

/* ------------- Reducers ------------- */
export const requestReadCategory = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true,
    ...action
});

export const readCategorySuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readCategoryFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_CATEGORY]: requestReadCategory,
    [Types.READ_CATEGORY_SUCCESS]: readCategorySuccess,
    [Types.READ_CATEGORY_FAILURE]: readCategoryFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
