import ItemDetailContent from "./item.detail.content";
import {uuid} from "../utils";


export default class OrderDetail {
    orderDetailCode: string
    itemCode: string
    itemName: string
    orderNumber: number
    price: number
    priceTaxIn: number
    comment: string
    discountValue: number | null
    discountPrice: number | null
    discountType: number | null
    tax: number
    subtotal: number
    total: number
    itemDetailContent: ItemDetailContent[]

    constructor() {
        this.orderDetailCode = uuid()
        this.itemCode = ""
        this.itemName = ""
        this.orderNumber = 1
        this.price = 0
        this.priceTaxIn = 0
        this.comment = ""
        this.discountValue = 0
        this.discountPrice = 0
        this.discountType = 0
        this.tax = 0
        this.subtotal = 0
        this.total = 0
        this.itemDetailContent = []
    }
}