export default {
    TXT_ITEMS_IN_CART: "购物车中的商品",
    TXT_DELETE_ALL: "全部删除",
    TXT_CONFIRMED_ORDER: "订单确认",
    TXT_ARE_YOU_SURE: "您确定要从购物车中移除商品吗？",
    TXT_DELETE: "删除",
    TXT_CANCEL: "取消",
    TXT_ORDER_IS_COMPLETE: "订单完成。",
    TXT_CHECK_ORDER_HISTORY: "查看订单历史",
    TXT_ITEM_SOLD_OUT: "此商品已售罄。\n" + "请删除该商品并重试\n" + "请重新订购。",
    TXT_CLOSE: "关闭",
    TXT_ERROR_STOCK_TEXT: "目前售罄",
    TXT_ERROR_STOCK: "部分菜单项目已售罄。\n" + "删除该菜单，然后重试\n" + "请订购。",
    TXT_CURRENCY_CART: "¥",
    TXT_TOTAL_ORDER: "订单总计",
    TXT_TAX_INCLUDED: "（含税）",
    TXT_ORDER_ERROR: "发生错误，请重试",
}
