import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import OrderDetail from "../models/order.detail";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestDeleteItemInCart: ["data"],
    deleteItemInCartSuccess: ["data"],
    deleteItemInCartFailure: ["error"],
    requestChangeItemInCart: ["data"],
    changeItemInCartSuccess: ["data"],
    changeItemInCartFailure: ["error"],
    resetCart: []
});

export const CartTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface CartStateRedux extends IDefaultStateProps {
    data: OrderDetail[];
    isSuccess: boolean;
}

export const INITIAL_STATE: CartStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: [],
};

/* ------------- Reducers ------------- */
export const requestDeleteItemInCart = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true
});

export const deleteItemInCartSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const deleteItemInCartFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

export const requestChangeItemInCart = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true
});

export const changeItemInCartSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const changeItemInCartFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

export const resetCart = () => ({
    isFetching: false,
    error: null,
    isSuccess: false,
    data: []
})

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_DELETE_ITEM_IN_CART]: requestDeleteItemInCart,
    [Types.DELETE_ITEM_IN_CART_SUCCESS]: deleteItemInCartSuccess,
    [Types.DELETE_ITEM_IN_CART_FAILURE]: deleteItemInCartFailure,

    [Types.REQUEST_CHANGE_ITEM_IN_CART]: requestChangeItemInCart,
    [Types.CHANGE_ITEM_IN_CART_SUCCESS]: changeItemInCartSuccess,
    [Types.CHANGE_ITEM_IN_CART_FAILURE]: changeItemInCartFailure,
    [Types.RESET_CART]: resetCart
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
