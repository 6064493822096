import {KeyConstant} from "../const";
import OrderDetail from "../models/order.detail";
import {Item, Shop} from "../models";
import {getShopInfo} from "./index";
import TableActivity from "../models/table.activity";

export const getCartData = () => {
    const cartData = localStorage.getItem(KeyConstant.KEY_CART);
    const cartArray: OrderDetail[] = []
    if (cartData != null) {
        const cartDataJson: { [key: string]: OrderDetail; } = JSON.parse(cartData)
        for (const [key, value] of Object.entries(cartDataJson)) {
            cartArray.push(value)
        }
    }

    return cartArray
}

export const getItemQuantity = () => {
    const cartData = localStorage.getItem(KeyConstant.KEY_CART);
    let itemQuantity = 0;
    if (cartData != null) {
        const cartDataJson: { [key: string]: OrderDetail; } = JSON.parse(cartData)
        for (const [key, value] of Object.entries(cartDataJson)) {
            itemQuantity += value.orderNumber
        }

    }
    return itemQuantity
}

export const getSumCurrentAmountOfCart = () => {
    const cartData = localStorage.getItem(KeyConstant.KEY_CART);
    let total = 0;
    if (cartData != null) {
        const cartDataJson: { [key: string]: OrderDetail; } = JSON.parse(cartData)
        for (const [key, value] of Object.entries(cartDataJson)) {
            total += value.orderNumber * value.priceTaxIn
            value.itemDetailContent.forEach(detailContent => {
                total += detailContent.priceTaxIn * detailContent.orderNumber
            })
        }

    }
    return total
}

export const checkUseStock = () => {
    const shopInfo: Shop = getShopInfo();
    return shopInfo.detailsJson.stockF == 1
}

export const getOrderDetailByItem = (item: Item) => {
    const orderDetail = new OrderDetail()
    orderDetail.itemCode = item.itemCode
    orderDetail.itemName = item.name
    orderDetail.tax = item.tax
    orderDetail.price = item.price
    orderDetail.priceTaxIn = item.priceTaxIn

    return orderDetail
}

export const calculateStock = (item: Item) => {
    const cartData = localStorage.getItem(KeyConstant.KEY_CART);
    if (cartData != null) {
        const cartDataJson: { [key: string]: OrderDetail; } = JSON.parse(cartData)
        if (cartDataJson) {
            for (const [key, value] of Object.entries(cartDataJson)) {
                if (item.itemCode === value.itemCode && item.stock != null) {
                    item.stock = item.stock - value.orderNumber
                }
            }
        }
    }
}

export const updateOrderDetailByItemCode = (orderDetail: OrderDetail) => {
    orderDetail.itemDetailContent.forEach(value => {
        value.orderNumber = value.orderNumber * orderDetail.orderNumber
    })
    const cartData = localStorage.getItem(KeyConstant.KEY_CART);
    let cartDataJson: { [key: string]: OrderDetail; } = {}
    if (cartData != null) {
        cartDataJson = JSON.parse(cartData)
    }
    cartDataJson[orderDetail.orderDetailCode] = orderDetail
    localStorage.setItem(KeyConstant.KEY_CART, JSON.stringify({...cartDataJson}))
}
