import {AnyAction} from "redux";
import {call, put} from "redux-saga/effects";
import TipAction from "../redux/tip.redux";
import {ResponseGenerator} from "../models";
import {TipService} from "../services";
import {toSnake} from "../utils";
import {ApiConstant} from "../const";
import humps from "humps";

export function* requestReadTip(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(TipService.requestReadTip, snakeData);
        if (response.status === ApiConstant.STT_OK && response.data) {
            const responseCamel = humps.camelizeKeys(response.data);
            responseCamel.map((value: any) => {
                if (value.details != null) {
                    const details = JSON.parse(value.details);
                    value.attachmentId = humps.camelizeKeys(details).attachmentId ? humps.camelizeKeys(details).attachmentId : null;
                } else {
                    value.attachmentId = null;
                }
            });
            yield put(TipAction.readTipSuccess(responseCamel));
        } else {
            yield put(TipAction.readTipFailure(response.data));
        }
    } catch (e) {
        yield put(TipAction.readTipFailure(e));
    }
}
