import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import TableActivity from "../models/table.activity";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    requestReadTableActivity: ["data"],
    readTableActivitySuccess: ["data"],
    readTableActivityFailure: ["error"],

    requestCreateTableActivity: ["data"],
    createTableActivitySuccess: ["tableActivityCode"],
    createTableActivityFailure: ["error"],

    requestUpdateTableActivity: ["data"],
    updateTableActivitySuccess: ["shopCode"],
    updateTableActivityFailure: ["error"]
});

export const TableActivityTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface TableActivityStateRedux extends IDefaultStateProps {
    data?: TableActivity[];
    tableActivityCode: string;
    isSuccess: boolean;
    isCreated: boolean;
}

export const INITIAL_STATE: TableActivityStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    isCreated: false,
    data: [],
    tableActivityCode: ""
};

/* ------------- Reducers ------------- */
export const requestReadTableActivity = (state = INITIAL_STATE) => ({
    ...state,
    isFetching: true,
    error: null,
    isSuccess: false,
    data: [],
});

export const readTableActivitySuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    isSuccess: true,
    data: action.data
});

export const readTableActivityFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    isSuccess: false,
    error: action.error
});

export const requestCreateTableActivity = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true,
    error: null,
    isCreated: false,
    data: []
})

export const createTableActivitySuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    isCreated: true,
    ...action
});

export const createTableActivityFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    isCreated: false,
    error: action.error
});

export const requestUpdateTableActivity = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true,
    error: null
})

export const updateTableActivitySuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    isCreated: true,
    ...action
});

export const updateTableActivityFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    isCreated: false,
    error: action.error
});
/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_TABLE_ACTIVITY]: requestReadTableActivity,
    [Types.READ_TABLE_ACTIVITY_SUCCESS]: readTableActivitySuccess,
    [Types.READ_TABLE_ACTIVITY_FAILURE]: readTableActivityFailure,

    [Types.REQUEST_CREATE_TABLE_ACTIVITY]: requestCreateTableActivity,
    [Types.CREATE_TABLE_ACTIVITY_SUCCESS]: createTableActivitySuccess,
    [Types.CREATE_TABLE_ACTIVITY_FAILURE]: createTableActivityFailure,

    [Types.REQUEST_UPDATE_TABLE_ACTIVITY]: requestUpdateTableActivity,
    [Types.UPDATE_TABLE_ACTIVITY_SUCCESS]: updateTableActivitySuccess,
    [Types.UPDATE_TABLE_ACTIVITY_FAILURE]: updateTableActivityFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);