import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import MainLayout from "../../components/MainLayout";
import {palette} from "../../theme/material";
import {Button, Typography, Box} from "@mui/material";
import {ImageConstant, KeyConstant} from "../../const";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import TableActivityCallAction from "../../redux/table.activity.call.redux";
import {getShopCode} from "../../utils/shop.util";
import TableActivity from "../../models/table.activity";
import {IStateRedux} from "../../redux/state";
import {useTranslation} from "react-i18next";
import LangConst from "../../const/lang.const";
import {getCustomerInfo} from "../../utils";

const TableActivityCallPage = () => {
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_CALL);
    const dispatch = useDispatch();
    const tableActivityCallReduxData = useSelector((state: IStateRedux) => state.tableActivityCallRedux);
    const {data, isFetching} = tableActivityCallReduxData;

    const [isCall, setIsCall] = useState(false);
    const customClasses = {
        body: `${classes.containerCall}`
    }

    useEffect(() => {
        if (data && isFetching) {
            setIsCall(true);
        }
    }, [data, isFetching]);

    const callStaff = () => {
        const tableActivityJson = localStorage.getItem(KeyConstant.KEY_TABLE_ACTIVITY);
        if (tableActivityJson) {
            const tableActivity: TableActivity = JSON.parse(tableActivityJson);
            dispatch(TableActivityCallAction.requestTableActivityCallCreate({
                shopCode: getShopCode(),
                tableActivityCode: tableActivity.tableActivityCode,
                userId: getCustomerInfo().userId,
            }));
        }
    }

    return (
        <>
            {!isCall ? <MainLayout customClasses={customClasses}>
                <Typography className={clsx(classes.title1, `dark-lg-txt hgp-w6-font-family`)}>{getLabel(LangConst.TXT_THE_CLERK_CALL_OUT)}</Typography>
                <Typography className={`light-md-txt`}>{getLabel(LangConst.TXT_WOULD_YOU_LIKE_TO_CALL_THE_CLERK)}</Typography>
                <Button className={clsx(classes.button, `light-md-txt`)} onClick={callStaff}>{getLabel(LangConst.TXT_YES)}</Button>
            </MainLayout> : <MainLayout customClasses={customClasses}>
                <Box className={classes.boxCallingNow}>
                    <img src={ImageConstant.CallActiveIcon}/>
                    <Typography
                        className={clsx(classes.title2, `hgp-w6-font-family`)}>{getLabel(LangConst.TXT_CALLING_NOW)}</Typography>
                </Box>
                <Typography className={`light-md-txt`}>{getLabel(LangConst.TXT_PLEASE_WAIT)}</Typography>
            </MainLayout>}
        </>
    );
}

const useStyles = makeStyles(() => ({
    containerCall: {
        backgroundColor: palette.background.paper,
        padding: 16,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    button: {
        width: "100%",
        height: 44,
        backgroundColor: "#ffaf31 !important",
        marginTop: 40,
        color: "#2d2d2d",
    },
    title1: {
        marginBottom: 16,
    },
    boxCallingNow: {
        display: "flex",
        marginBottom: 16,
    },
    title2: {
        fontSize: 20,
        color: "#ffaf31",
    },
}));

export default TableActivityCallPage;
