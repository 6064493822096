import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {makeStyles} from "@material-ui/core";
import {palette} from "../theme/material";

export default function LoadingProgress() {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <CircularProgress
                variant="indeterminate"
                disableShrink
                size={40}
                thickness={4}
            />
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        padding: 8,
        backgroundColor: palette.background.paper,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    },
}));
