export default {
    TXT_HODAI_TITLE: "무제한 플랜을 이용하시겠습니까?",
    TXT_HODAI_USE: "이용하다",
    TXT_HODAI_DONT_USE: "사용하지 않음",
    TXT_HODAI_RETURN: "뒤로",
    TXT_HODAI_SURE: "확신하는",
    TXT_HODAI_TITLE_LIST: "플랜을 선택하세요.",
    TXT_HODAI_USUALLY: "通常",
    TXT_HODAI_UNLIMITED: "무제한",
    TXT_TARGET_MENU: "메구리 나비",
}
