export default {
    TXT_ITEMS_IN_CART: "Items in cart",
    TXT_DELETE_ALL: "Delete all",
    TXT_CONFIRMED_ORDER: "Confirmed order",
    TXT_ARE_YOU_SURE: "Are you sure you want to remove the item from your cart?",
    TXT_DELETE: "Delete",
    TXT_CANCEL: "Cancel",
    TXT_ORDER_IS_COMPLETE: "Your order is complete.",
    TXT_CHECK_ORDER_HISTORY: "Check order history",
    TXT_ITEM_SOLD_OUT: "Some menu items are sold out.\n" + "Delete that menu and try again\n" + "Please order.",
    TXT_CLOSE: "Close",
    TXT_ERROR_STOCK_TEXT: "Currently sold out",
    TXT_ERROR_STOCK: "Some menu items are sold out. \n" + "Delete that menu and try again\n" + "Please order.",
    TXT_CURRENCY_CART: "¥",
    TXT_TOTAL_ORDER: "Total Order",
    TXT_TAX_INCLUDED: "（Tax Included）",
    TXT_ORDER_ERROR: "An error occurred, please try again",
}
