import {AnyAction, applyMiddleware, combineReducers, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import {IStateRedux} from "./state";
import * as AuthRedux from "./auth.redux";
import * as TableActivityRedux from "./table.activity.redux";
import * as CategoryRedux from "./category.redux";
import * as ItemRedux from "./item.redux";
import * as ShopRedux from "./shop.redux";
import * as OrderRedux from "./order.redux";
import * as ItemDetailRedux from "./item.detail.redux";
import * as HistoryRedux from "./history.redux";
import * as CartRedux from "./cart.redux";
import * as OrderStatusRedux from "./order.status.redux";
import * as SettingMoTopRedux from "./setting.mo.top.redux";
import * as LanguageRedux from "./language.redux";
import * as TableActivityCallRedux from "./table.activity.call.redux";
import * as HodaiRedux from "./hodai.redux";
import * as TipRedux from "./tip.redux";
import * as StaffRedux from "./staff.redux";
import * as TableActivityTipCreateRedux from "./table.activity.tip.create.redux";
import * as SettingMoPaymentRedux from "./setting.mo.payment.redux";

/* ------------- Assemble The Reducers ------------- */

export const appReducer = combineReducers({
    authRedux: AuthRedux.reducer,
    tableActivityRedux: TableActivityRedux.reducer,
    categoryRedux: CategoryRedux.reducer,
    itemRedux: ItemRedux.reducer,
    shopRedux: ShopRedux.reducer,
    itemDetailRedux: ItemDetailRedux.reducer,
    historyRedux: HistoryRedux.reducer,
    orderRedux: OrderRedux.reducer,
    cartRedux: CartRedux.reducer,
    orderStatusRedux: OrderStatusRedux.reducer,
    settingMoTopRedux: SettingMoTopRedux.reducer,
    languageRedux: LanguageRedux.reducer,
    tableActivityCallRedux: TableActivityCallRedux.reducer,
    hodaiRedux: HodaiRedux.reducer,
    tipRedux: TipRedux.reducer,
    staffRedux: StaffRedux.reducer,
    tableActivityTipCreateRedux: TableActivityTipCreateRedux.reducer,
    settingMoPaymentRedux: SettingMoPaymentRedux.reducer,
});

const initialState: IStateRedux = {
    authRedux: AuthRedux.INITIAL_STATE,
    tableActivityRedux: TableActivityRedux.INITIAL_STATE,
    categoryRedux: CategoryRedux.INITIAL_STATE,
    itemRedux: ItemRedux.INITIAL_STATE,
    shopRedux: ShopRedux.INITIAL_STATE,
    itemDetailRedux: ItemDetailRedux.INITIAL_STATE,
    historyRedux: HistoryRedux.INITIAL_STATE,
    orderRedux: OrderRedux.INITIAL_STATE,
    cartRedux: CartRedux.INITIAL_STATE,
    orderStatusRedux: OrderStatusRedux.INITIAL_STATE,
    settingMoTopRedux: SettingMoTopRedux.INITIAL_STATE,
    languageRedux: LanguageRedux.INITIAL_STATE,
    tableActivityCallRedux: TableActivityCallRedux.INITIAL_STATE,
    hodaiRedux: HodaiRedux.INITIAL_STATE,
    tipRedux: TipRedux.INITIAL_STATE,
    staffRedux: StaffRedux.INITIAL_STATE,
    tableActivityTipCreateRedux: TableActivityTipCreateRedux.INITIAL_STATE,
    settingMoPaymentRedux: SettingMoPaymentRedux.INITIAL_STATE,

};

export const rootReducer = (state = initialState, action: AnyAction) => {
    // Action logout
    // if (action.type === KeyConstant.LOGOUT_REQUEST) {
    //   state = undefined;
    // }
    return appReducer(state, action);
};

/* ------------- Redux Configuration ------------- */

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();

// Create store
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// kick off root saga
sagaMiddleware.run(rootSaga);

export default store;
