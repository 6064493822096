import Cookies from "js-cookie";
import {KeyConstant, SystemConstant} from "../const";
import {DataLogin} from "../models";
import {v4 as uuidv4} from 'uuid';
import {convertHmToM} from "./date.utils";
import {createSearchParams} from "react-router-dom";

export const uuid = () => {
    return uuidv4()
        .replace(/-/g, "");
};

export const getError = (response: { data: any; error: any; }) => {
    let errorContent = "";
    if (response) {
        const {data, error} = response;
        if (data) {
            errorContent = data.message + data.error || "Error happen!!!";
        } else if (error) errorContent = error;
    }

    return errorContent;
};

export const hasLogin = () => Boolean(Cookies.get(KeyConstant.KEY_TOKEN));

export const snakeToCamelCase = (str: string) =>
    str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const camelToSnakeCase = (str: string) =>
    (str.charAt(0).toLowerCase() + str.slice(1) || str).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const toCamel = (obj: any[]) => {
    let newObj, origKey, newKey, value;
    if (obj instanceof Array) {
        return obj.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value);
            }
            return value;
        });
    } else {
        newObj = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        for (origKey in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
                newKey = snakeToCamelCase(origKey);
                value = obj[origKey];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (value instanceof Array || (value && value.constructor === Object)) {
                    value = toCamel(value);
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newObj[newKey] = value;
            }
        }
    }
    return newObj;
};

export const toSnake = (obj: any[]) => {
    let newObj, origKey, newKey, value;
    if (obj instanceof Array) {
        return obj.map(function (value) {
            if (typeof value === "object") {
                value = toSnake(value);
            }
            return value;
        });
    } else {
        newObj = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        for (origKey in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
                newKey = camelToSnakeCase(origKey);
                value = obj[origKey];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (value instanceof Array || (value && value.constructor === Object)) {
                    value = toSnake(value);
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newObj[newKey] = value;
            }
        }
    }
    return newObj;
};

export const handlingLogin = (data: DataLogin) => {
    localStorage.removeItem(KeyConstant.KEY_CART)
    localStorage.removeItem(KeyConstant.KEY_TABLE_ACTIVITY)
    // Cookies.remove(KeyConstant.KEY_TABLE_ACTIVITY);
    localStorage.removeItem(KeyConstant.KEY_TABLE_MODE)
    if (data.accessToken) {
        Cookies.set(KeyConstant.KEY_TOKEN, data.accessToken);
    }
    if (data.userId) {
        Cookies.set(KeyConstant.KEY_UID, data.userId);
    }
    if (data.timeZone) {
        Cookies.set(KeyConstant.KEY_TIME_ZONE, data.timeZone.toString());
    }
    if (data.liffAppName) {
        Cookies.set(KeyConstant.KEY_LIFF_APP_NAME, data.liffAppName);
    }
};


export const getShopInfo = () => {
    const shopInfo = localStorage.getItem(KeyConstant.KEY_SHOP_INFO);
    if (shopInfo != null) {
        return JSON.parse(shopInfo)
    } else {
        return {}
    }
}

export const getTableActivityCode = () => {
    const tableActivity = localStorage.getItem(KeyConstant.KEY_TABLE_ACTIVITY);
    
    if (tableActivity != null && JSON.parse(tableActivity).tableActivityCode) {
        return JSON.parse(tableActivity).tableActivityCode
    } else {
        return ""
    }
}

export const getLiffId = () => {
    const liffId = localStorage.getItem(KeyConstant.KEY_LIFF_ID)
    return liffId ? liffId : ""
}

export const setSearchQuery = (shopCode: string, tableActivityCode: string, liffId: string) => {
    const isPreview = localStorage.getItem(KeyConstant.KEY_IS_PREVIEW)
    const dayOfWeekInPreviewLink = localStorage.getItem(KeyConstant.KEY_DAY_OF_WEEK)
    const timeInPreviewLink = localStorage.getItem(KeyConstant.KEY_TIME_PERIOD)
    const tableCode = localStorage.getItem(KeyConstant.KEY_TABLE_CODE)

    // Format link for preview:
    // EX: https://192.168.1.87:3000/277_2252/1/277_1000076?moa=1656930868-8oo4pXjr&is_preview=1&day_of_week=2&time_period=17:30
    if (isPreview != null && isPreview == '1' && dayOfWeekInPreviewLink != null && timeInPreviewLink != null && tableCode != null) {
        return `?${createSearchParams({
            moa: liffId + "-" + shopCode,
            shopCode: shopCode,
            tableActivityCode: tableActivityCode,
            tableCode: tableCode,
            is_preview: isPreview,
            day_of_week: dayOfWeekInPreviewLink,
            time_period: timeInPreviewLink
        })}`
    }

    return `?${createSearchParams({
        moa: liffId + "-" + shopCode,
        shopCode: shopCode,
        tableActivityCode: tableActivityCode
    })}`
}

export const getCustomerInfo = () => {
    const customerInfo = localStorage.getItem(KeyConstant.KEY_MO_CUSTOMER);
    if (customerInfo != null) {
        return JSON.parse(customerInfo)
    } else {
        return {}
    }
}

export const isDisplay = (value: any, currentMinutes: number) => {
    const moPeriod = value.moPeriodObject;
    let isDisplay = true;
    if (moPeriod?.lunchTime != null) {
        const lunchTime = moPeriod.lunchTime;
        if (convertHmToM(lunchTime.start) <= currentMinutes &&
            (convertHmToM(lunchTime.end) >= currentMinutes || lunchTime.end == SystemConstant.TIME_PERIOD_DEFAULT)) {
            isDisplay = true;
            return isDisplay;
        } else {
            isDisplay = false
        }
    }

    if (moPeriod?.dinnerTime != null) {
        const dinnerTime = moPeriod.dinnerTime;
        if (convertHmToM(dinnerTime.start) <= currentMinutes &&
            (convertHmToM(dinnerTime.end) >= currentMinutes || dinnerTime.end == SystemConstant.TIME_PERIOD_DEFAULT)) {
            isDisplay = true;
            return isDisplay;
        } else {
            isDisplay = false
        }
    }

    return isDisplay;
}

export const isDisplayInDayOfWeek = (value: any, currentDateYYYYMMDD: string | null, dayOfWeekOfCurrentDate: number, holiday?: string[] | null) => {
    const moDayOfWeekFull = [0, 1, 2, 3, 4, 5, 6, 7]; // Mon,Tue,Wed,Thu, Fri, Sat, Sun, Holiday

    const moDayOfWeek = value.moDayOfWeekArray;
    if (moDayOfWeek == null || moDayOfWeek?.length == moDayOfWeekFull.length) {
        return true
    } else if (moDayOfWeek.length == 0) {
        return false
    }
    // Check currentDay is holiday
    if (holiday != null && currentDateYYYYMMDD != null && holiday?.includes(currentDateYYYYMMDD)) {
        return moDayOfWeek.includes(7)
    } else {
        return moDayOfWeek.includes(dayOfWeekOfCurrentDate)
    }
}


export interface NewOrder {
    orderCode: string
    lineUserId: string
}

export const addNewOrder = (newOrder: NewOrder) => {
    const savedNewOrder = localStorage.getItem(KeyConstant.KEY_NEW_ORDER)
    let newOrders: NewOrder[] = []
    if (savedNewOrder && savedNewOrder != "") {
        newOrders = JSON.parse(savedNewOrder)
    }
    if (!newOrders.map(value => value.orderCode).includes(newOrder.orderCode)) {
        newOrders.push(newOrder)
    }
    localStorage.setItem(KeyConstant.KEY_NEW_ORDER, JSON.stringify(newOrders))
}

export const removeNewOrder = (newOrderCode: string) => {
    const savedNewOrder = localStorage.getItem(KeyConstant.KEY_NEW_ORDER)
    if (savedNewOrder && savedNewOrder != "") {
        const newOrders: NewOrder[] = JSON.parse(savedNewOrder)
        const index = newOrders.findIndex(value => value.orderCode == newOrderCode)
        newOrders.splice(index)
        localStorage.setItem(KeyConstant.KEY_NEW_ORDER, JSON.stringify(newOrders))
    }
}

export const detectUserOrder = (checkingOrderCode: string) => {
    const savedNewOrder = localStorage.getItem(KeyConstant.KEY_NEW_ORDER)
    if (savedNewOrder && savedNewOrder != "") {
        const newOrders: NewOrder[] = JSON.parse(savedNewOrder)
        return newOrders && newOrders.filter(value => value.orderCode == checkingOrderCode && value.lineUserId == getCustomerInfo()["userId"]).length > 0;
    } else {
        return false
    }
}

export const getFailedOrderStatus = () => {
    const failedOrderDetected = localStorage.getItem(KeyConstant.KEY_FAILED_ORDER_DETECTED)
    return !!(failedOrderDetected && failedOrderDetected == "1");
}

