export default {
    TXT_CURRENCY: "¥",
    TXT_MULTIPLY: "×",
    TXT_YEN: "円",
    TXT_TOTAL_ORDER: "ご注文合計",
    TXT_TAX_INCLUDED: "(税込)",
    TXT_TITLE_HISTORY: "注文履歴",
    TXT_ORDER_DETAIL_FAILED: "Failed to send order.",
    TXT_ORDER_AGAIN_NOTIFICATION: "An error occurred while submitting the order.\n" +
        "Please order again from the menu."
};