import {toCamel} from "../utils";

export default class Shop implements ShopDto {
    created?: number | null;
    details?: string;
    detailsJson: ShopDetails;
    modified?: number | null;
    shopCode?: string;

    constructor() {
        this.shopCode = "";
        this.details = "";
        this.detailsJson = new ShopDetails();
        this.created = 0;
        this.modified = 0;
    }

    setDetailJson() {
        if (this.details != null) {
            this.detailsJson = toCamel(JSON.parse(this.details))
        }
        return this
    }
}

export interface ShopDto {
    shopCode?: string,
    details?: string,
    created?: number | null,
    modified?: number | null,
}

export class ShopDetails {
    stockF?: number;

    constructor() {
        this.stockF = 0;
    }
}