import React from "react";
import {makeStyles} from "@material-ui/core";
import {Box, Button, Typography} from "@mui/material";
import theme from "../../theme/material";
import {useTranslation} from "react-i18next";
import LangConst from "../../const/lang.const";
import clsx from "clsx";
import {KeyConstant, PathConstant, SystemConstant} from "../../const";
import {IStateRedux} from "../../redux/state";
import {getLiffId, setSearchQuery} from "../../utils";
import { getShopCode } from "../../utils/shop.util";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const ConfirmHodaiPage = () => {
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_HODAI);
    const tableActivityCode = useSelector((state: IStateRedux) => state.tableActivityRedux.tableActivityCode)
    const navigate = useNavigate();

    const clickUseHodai = () => {
        navigate({
            pathname: PathConstant.PLAN_LIST,
            search: setSearchQuery(getShopCode(), tableActivityCode, getLiffId())
        })
    }

    const clickDontUseHodai = () => {
        localStorage.setItem(KeyConstant.KEY_IS_HODAI, SystemConstant.STATE.INACTIVE.toString());
        navigate({
            pathname: PathConstant.CATEGORY,
            search: setSearchQuery(getShopCode(), tableActivityCode, getLiffId())
        })
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <Typography
                    className={clsx(classes.title, `hgp-w6-font-family dark-lg-txt`)}>{getLabel(LangConst.TXT_HODAI_TITLE)}</Typography>
                <Button
                    className={clsx(classes.buttonUse, `light-md-txt`)}
                    onClick={clickUseHodai}>{getLabel(LangConst.TXT_HODAI_USE)}
                </Button>
                <Button
                    className={clsx(classes.buttonDontUse, `light-md-txt`)}
                    onClick={clickDontUseHodai}>{getLabel(LangConst.TXT_HODAI_DONT_USE)}
                </Button>
            </Box>
        </Box>
    )
}
export default ConfirmHodaiPage;

const useStyles = makeStyles(() => ({
    root: {
        padding: 16,
        width: "100vw",
        height: "100vh",
        backgroundColor: "#f8f8f8",
    },
    title: {
        textAlign: "center",
        color: theme.palette.common.black,
    },
    container: {
        width: "100%",
        backgroundColor: theme.palette.common.white,
        height: "auto",
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
        display: "flex",
        padding: "32px 16px",
        flexDirection: "column",
        alignItems: "center",
    },
    buttonUse: {
        width: "100%",
        height: 44,
        borderRadius: 4,
        backgroundColor: "#ffaf31 !important",
        color: theme.palette.common.black,
        marginTop: 32,
    },
    buttonDontUse: {
        marginTop: 16,
        width: "100%",
        height: 44,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white + "!important",
        color: theme.palette.common.black,
        border: "solid 0.5px #2d2d2d",
    }
}));

