import {LangConstant} from "../const";
import {getLabel} from "../language";
import {getNumberFormat, getNumberFormatYen} from "./number.utils";

export const getCommonKey = (key: any) => `${LangConstant.NS_COMMON}:${key}`;

export const truncateString = (string: string | undefined, lengthLimit: number) => {
    if (string == undefined) return ``;
    return (string.length > lengthLimit) ? `${string.substring(0, lengthLimit)}...` : string
}

export const getNameCustomer = (name: string) => {
    name = truncateString(name, 9)
    return `${name}${getLabel(LangConstant.TXT_UNIT, {})}`;
}

export const getItemNumberFormat = (itemNumber: number) => {
    const itemNumberFormat = getNumberFormat(itemNumber)
    return `${itemNumberFormat} ${getLabel(LangConstant.TXT_ITEM, {})}`
}

export const getPriceFormat = (price: number) => {
    const itemNumberFormat = getNumberFormat(price)
    return `${itemNumberFormat}${getLabel(LangConstant.TXT_YEN, {})}`
}

export const getPriceFormatYen = (price: number) => {
    const itemNumberFormat = getNumberFormatYen(price)
    return itemNumberFormat;
}
