import React, {FC, useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import {makeStyles} from "@material-ui/core";
import theme from "../../../theme/material";
import LangConst from "../../../const/lang.const";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import OrderAction from "../../../redux/order.redux";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import {Box, Button} from "@mui/material";
import {useSelector} from "react-redux";
import {IStateRedux} from "../../../redux/state";

const OrderFailedPopup = () => {
    const [open, setOpen] = useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t: getLabel} = useTranslation(LangConst.NS_CART);
    const createOrderError = useSelector((state: IStateRedux) => state.orderRedux.error)

    const handleClose = () => {
        setOpen(false);
        if (!Array.isArray(createOrderError)) {
            dispatch(OrderAction.resetOrderCreate());
        }
    }

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modal}
        >
            <Box className={classes.box}>
                <Typography className={clsx(classes.content, 'light-sm-txt')}>
                    {Array.isArray(createOrderError) ? getLabel(LangConst.TXT_ERROR_STOCK) : getLabel(LangConst.TXT_ORDER_ERROR)}
                </Typography>
                <Button className={classes.buttonClose} onClick={handleClose}>
                    {getLabel(LangConst.TXT_CLOSE, {ns: LangConst.NS_CART})}
                </Button>
            </Box>
        </Modal>
    );
}

export default OrderFailedPopup;

const useStyles = makeStyles(() => ({
    modal: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 11111,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    box: {
        height: "auto",
        width: 296,
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        padding: "32px 16px 16px",
    },
    content: {
        padding: "0px 21px",
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    buttonClose: {
        width: "100%",
        height: 44,
        borderRadius: 4,
        backgroundColor: "#ffaf31 !important",
        fontSize: 16,
        color: theme.palette.common.black,
        marginTop: 32,
    }
}));
