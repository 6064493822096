import Cookies from "js-cookie";
import {KeyConstant, SystemConstant} from "../const";

export const getTimeZoneServer = () => {
    const timeZone = Cookies.get(KeyConstant.KEY_TIME_ZONE)
    return (timeZone != undefined && timeZone) ? parseInt(timeZone) : SystemConstant.TIME_ZONE_SERVER_DEFAULT
}

export const timeZoneClient = new Date().getTimezoneOffset() / -60;

export const addHour = (date: Date, hour: number) => {
    return date.getTime() + hour * 60 * 60 * 1000
}


export const createDateServer = (date = new Date()) => {
    const timeZoneServer = getTimeZoneServer();
    return new Date(addHour(date, timeZoneServer - timeZoneClient));
}

export const getCurrentMinutesInServer = () => {
    const currentDate = createDateServer();
    const hour = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    return hour * 60 + minutes;
}

export const convertHmToM = (time: string) => {
    const timeArray = time.split(":");
    let hm = 0;
    if (timeArray != null && timeArray.length == 2) {
        hm = Number(timeArray[0]) * 60 + Number(timeArray[1])
    }
    return hm;
}

export function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export const getFormatDateYYYYMMDD = (date: Date) => {
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('/');
}