import React from "react";
import {makeStyles} from "@material-ui/core";
import {Box, Typography} from "@mui/material";
import {ItemOrderHistoryDto} from "../../../models/history";
import {palette} from "../../../theme/material";
import {useTranslation} from "react-i18next";
import LangConst from "../../../const/lang.const";
import {truncateString} from "../../../utils/lang.utils";
import {FormatConstant} from "../../../const";

const ItemOrderHistory = (props: ItemOrderHistoryProps) => {
    const {data, isItemDetailContent, isFailed} = props;
    const classes = useStyle();
    const {t: getLabel} = useTranslation(LangConst.NS_ORDER_HISTORY);
    const multiply = getLabel(LangConst.TXT_MULTIPLY);
    const maxStringName = 11;
    const classesLine = (isItemDetailContent != null && isItemDetailContent) ? `${classes.line} ${classes.lineItemDetailContent}` : `${classes.line} ${classes.lineItem} `
    const fontFamily = (isItemDetailContent != null && isItemDetailContent) ? `` : `hgp-w6-font-family `
    return (
        <>
            <Box className={classesLine}>
                <Box>
                    <Typography className={`${classes.name}  ${fontFamily} light-md-txt `}>{truncateString(data.itemName,maxStringName)}</Typography>
                    {isFailed && <Typography sx={{color: "#ff3e54", marginTop: "9px", fontSize: "13px"}} className={`${classes.name}  hgp-w6-font-family-normal`}>{getLabel(LangConst.TXT_ORDER_DETAIL_FAILED)}</Typography>}
                </Box>
                <Typography
                    className={`${classes.orderNumber}  ${fontFamily} light-md-txt`}>{multiply}{data.orderNumber}</Typography>
                <Typography className={`${classes.price}  ${fontFamily} light-md-txt`}>
                    {(data.priceTaxIn != undefined && true) ? `${FormatConstant.JAPANESE_CURRENCY_FORMAT(data.priceTaxIn)}` : ""}
                </Typography>
            </Box>
        </>
    );

};

interface ItemOrderHistoryProps {
    data: ItemOrderHistoryDto,
    isItemDetailContent?: boolean
    isFailed?: boolean
}

export default ItemOrderHistory;

const useStyle = makeStyles(() => ({
    line: {
        padding: "16px 0",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    lineItemDetailContent: {
        borderTop: `1px solid ${palette.divider}`,
        height: 47,
        paddingLeft: 16
    },
    lineItem: {
        height: 77
    },
    name: {},
    orderNumber: {
        paddingLeft: 20,
        paddingRight: 16,
        marginLeft: "auto"
    },
    price: {
        float: "right",
        minWidth: 62,
        textAlign: "right"
    },
}));