import liff from "@line/liff";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

let searchMoa = new URLSearchParams(window.location.search).get("moa")
    if (searchMoa && searchMoa.split("-").length > 2) {
        searchMoa = searchMoa.split("-").slice(0, 2).join("-")
    }

liff
  .init({ liffId: searchMoa || "" })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch((e) => {
    alert(`LIFF error: ${e.message}`);
  });
