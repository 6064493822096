import {makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import Staff from "../../../models/staff";
import {AttachmentService} from "../../../services";
import {ImageConstant, PathConstant, SystemConstant} from "../../../const";
import clsx from "clsx";
import {getLiffId, getTableActivityCode, setSearchQuery} from "../../../utils";
import {getShopCode} from "../../../utils/shop.util";
import {useNavigate} from "react-router-dom";

interface StaffItem {
    staff: Staff,
}

const StaffItem = (props: StaffItem) => {
    const {staff} = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState("");
    const gotoStaffDetail = (staff: Staff) => {
        navigate({
            pathname: PathConstant.TIP.replace(":staffCode", staff.staffCode),
            search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
        }, {
            state: {staff: staff}
        });
    }

    useEffect(() => {
        if (staff.attachmentId) {
            AttachmentService.readAttachment({
                id: staff.attachmentId,
            }).then(responseAttachment => {
                const encryptedBytes = new Uint8Array(responseAttachment.data as any);
                const blob = new Blob([encryptedBytes], {type: "application/image"})
                setImageUrl(URL.createObjectURL(blob));
            });
        }

        return () => {
            setImageUrl("");
        };
    }, [staff]);

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Box className={classes.root} onClick={() => gotoStaffDetail(staff)}>
                <img
                    loading="lazy"
                    src={imageUrl ? imageUrl : ImageConstant.AvatarIcon}
                    className={classes.image}
                />
                <Typography
                    className={clsx(classes.name, `light-sm-txt hgp-w6-font-family`)}>{staff.displayName}</Typography>
                <Typography className={clsx(classes.introduction, `light-xxs-txt`)}>{staff.introduction}</Typography>
            </Box>
        </Grid>
    );
};


const useStyles = makeStyles(() => ({
    root: {
        height: 253,
        backgroundColor: "white",
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
    },
    image: {
        width: "100%",
        height: 156,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        objectFit: "cover",
    },
    name: {
        textAlign: "center",
        padding: "0px 8px"
    },
    introduction: {
        textAlign: "center",
        padding: "0px 8px",
        wordBreak: "break-word",
    }
}));

export default StaffItem;
