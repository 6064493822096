import {AnyAction} from "redux";
import {call, put} from "redux-saga/effects";
import StaffAction from "../redux/staff.redux";
import {ResponseGenerator} from "../models";
import {StaffService, TipService} from "../services";
import {toCamel, toSnake} from "../utils";
import {ApiConstant} from "../const";
import humps from "humps";

export function* requestReadStaff(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(StaffService.requestReadStaff, snakeData);
        if (response.status === ApiConstant.STT_OK && response.data) {
            const responseCamel = humps.camelizeKeys(response.data);
            responseCamel.map((value: any) => {
                if (value.details != null) {
                    const details = JSON.parse(value.details);
                    value.attachmentId = humps.camelizeKeys(details).attachmentId ? humps.camelizeKeys(details).attachmentId : null;
                } else {
                    value.attachmentId = null;
                }
                if (value.displayName == null) {
                    const name = JSON.parse(value.name);
                    value.displayName = humps.camelizeKeys(name).name0 ? humps.camelizeKeys(name).name0 : null;
                }
            });
            yield put(StaffAction.readStaffSuccess(responseCamel));
        } else {
            yield put(StaffAction.readStaffFailure(response.data));
        }
    } catch (e) {
        yield put(StaffAction.readStaffFailure(e));
    }
}
