export default {
    TXT_HODAI_TITLE: "Do you want to use unlimited plan?",
    TXT_HODAI_USE: "Use",
    TXT_HODAI_DONT_USE: "Don't use",
    TXT_HODAI_RETURN: "Return",
    TXT_HODAI_SURE: "Sure",
    TXT_HODAI_TITLE_LIST: "Please select a plan",
    TXT_HODAI_USUALLY: "Generally",
    TXT_HODAI_UNLIMITED: "Unlimited",
    TXT_TARGET_MENU: "Target menu",

}
