import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core";
import {Typography, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import LangConst from "../../const/lang.const";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import StaffAction from "../../redux/staff.redux";
import {getShopCode} from "../../utils/shop.util";
import {IStateRedux} from "../../redux/state";
import {StaffItem} from "./components";
import {uuid} from "../../utils";
import Staff from "../../models/staff";
import {truncateString} from "../../utils/lang.utils";
import {SystemConstant} from "../../const";
import {MainLayout} from "../../components";

const StaffPage = () => {
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_TIP);
    const dispatch = useDispatch();
    const data = useSelector((state: IStateRedux) => state.staffRedux.data);

    const customClasses = {
        body: `${classes.root}`
    }

    useEffect(() => {
        if (data.length == 0) {
            dispatch(StaffAction.requestReadStaff({
                shopCode: getShopCode(),
            }));
        }
    }, []);

    return (
        <MainLayout customClasses={customClasses}
                    title={truncateString(getLabel(LangConst.TXT_MENU, {ns: LangConst.NS_COMMON}), SystemConstant.LIMIT_LENGTH_NAME)}>
            <Typography
                className={clsx(classes.title, `light-md-txt hgp-w6-font-family`)}>{getLabel(LangConst.TXT_TIP_TITLE)}</Typography>
            <Grid container spacing={2} className={classes.boxItem}>
                {data.length > 0 && data.map((value: Staff) =>
                    <StaffItem staff={value} key={value.staffCode}/>
                )}
            </Grid>
        </MainLayout>
    )
}
export default StaffPage;

const useStyles = makeStyles(() => ({
    root: {
        padding: "16px 16px 0px",
        width: "100vw",
        height: "calc(100vh - 44px)",
        backgroundColor: "#f8f8f8",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none"
        },
    },
    title: {
        height: 48,
        textAlign: "center",
        whiteSpace: "pre-line",
        marginBottom: 16,
    },
    boxItem: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 16,
    }
}));

