import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import Item from "../models/item";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestReadItem: ["searchData"],
    readItemSuccess: ["data"],
    readItemFailure: ["error"]
});

export const ItemTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface ItemStateRedux extends IDefaultStateProps {
    data: Item[];
    isSuccess: boolean;
    searchData: any;
}

export const INITIAL_STATE: ItemStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: [],
    searchData: {}
};

/* ------------- Reducers ------------- */
export const requestReadItem = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readItemSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readItemFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_ITEM]: requestReadItem,
    [Types.READ_ITEM_SUCCESS]: readItemSuccess,
    [Types.READ_ITEM_FAILURE]: readItemFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
