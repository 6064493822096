import {makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Box, Collapse, List} from "@mui/material";
import {uuid} from "../../../utils";
import ItemDetailTitle from "./ItemDetailTitle";
import ItemDetailContentSelect from "./ItemDetailContentSelect";
import ItemDetailContentMultiSelect from "./ItemDetailContentMultiSelect";
import ItemDetail from "../../../models/item.detail";
import theme from "../../../theme/material";

interface ItemDetailInfoProps {
    data: ItemDetail,
    isInValidSelect: boolean,
    pickItemDetailContent: (itemDetailCode: string, itemDetailContentCode: string, orderNumberChange: number, isDeleteOtherItemDetailDetailContentCode?: boolean | null) => void,
    changeStatusItemDetailContents: () => void,
}

const ItemDetailInfo = (props: ItemDetailInfoProps) => {
    const {data, isInValidSelect, pickItemDetailContent, changeStatusItemDetailContents} = props
    const classes = useStyles();
    const [open, setOpen] = useState(data.isOpenItemDetailContents);

    const getTotalOrderNumber = () => {
        let number = 0
        data.itemDetailContents.forEach(value => {
            number += value.orderNumber
        })
        return number
    }

    return (
        <List className={classes.root}>
            <ItemDetailTitle
                name={data.name} minNumber={data.minNumber}
                maxNumber={data.maxNumber}
                isRequired={data.isRequired}
                open={open}
                openItemDetailContents={() => {
                    changeStatusItemDetailContents();
                    setOpen(!open)
                }}/>
            <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapse}>
                {data.itemDetailContents && data.itemDetailContents.length > 0 &&
                    <Box>
                        {data.itemDetailContents.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1).map(value =>
                            (data.maxNumber == 1 || value.itemCode == null) ? (
                                <ItemDetailContentSelect
                                    key={uuid()} itemDetailContent={value}
                                    onClickItemDetailContent={(itemDetailContent, orderNumber) => {
                                        if (value.orderNumber === 0) {
                                            if (data.maxNumber && getTotalOrderNumber() >= data.maxNumber) {
                                                // Unselect item detail content which user select before
                                                const resultFilter = data.itemDetailContents.filter(itemDetailContentValue =>
                                                    itemDetailContentValue.orderNumber > 0 &&
                                                    itemDetailContentValue.itemDetailContentCode != value.itemDetailContentCode);
                                                const isDeleteOtherItemDetailDetailContentCode = (resultFilter.length > 0)
                                                pickItemDetailContent(data.itemDetailCode, value.itemDetailContentCode, value.orderNumber += 1, isDeleteOtherItemDetailDetailContentCode)
                                            } else {
                                                pickItemDetailContent(data.itemDetailCode, value.itemDetailContentCode, value.orderNumber += 1)
                                            }
                                        } else {
                                            pickItemDetailContent(data.itemDetailCode, value.itemDetailContentCode, value.orderNumber -= 1)
                                        }
                                    }}/>
                            ) : (
                                <ItemDetailContentMultiSelect
                                    key={uuid()} itemDetailContent={value}
                                    maxNumber={data.maxNumber}
                                    currentNumber={getTotalOrderNumber()}
                                    onClickItemDetailContent={(itemDetailContent, orderNumberChange) => {
                                        if (value.orderNumber > 0 && orderNumberChange === -1) {
                                            pickItemDetailContent(data.itemDetailCode, value.itemDetailContentCode, value.orderNumber -= 1)
                                        }
                                        if ((data.maxNumber && getTotalOrderNumber() < data.maxNumber && orderNumberChange === 1) ||
                                            (data.maxNumber == null && orderNumberChange === 1)) {
                                            pickItemDetailContent(data.itemDetailCode, value.itemDetailContentCode, value.orderNumber += 1)
                                        }
                                    }}/>
                            )
                        )}
                    </Box>
                }
            </Collapse>
        </List>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        padding: 0,
        "&:last-child": {
            marginBottom: 16,
        }
    },
    boxItemDetailContents: {
        borderTop: "solid 0.5px #ddd",
    },
    collapse: {
        backgroundColor: theme.palette.background.default,
        margin: "0 16px",
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
    }
}));

export default ItemDetailInfo;
