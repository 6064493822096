import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import TableActivityCall from "../models/TableActivityCall";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestTableActivityCallCreate: ["data"],
    createTableActivityCallSuccess: ["data"],
    createTableActivityCallFailure: ["error"]
});

export const TableActivityCallTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface TableActivityCallStateRedux extends IDefaultStateProps {
    data: TableActivityCall;
    isSuccess: boolean;
}

export const INITIAL_STATE: TableActivityCallStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: new TableActivityCall(),
};

/* ------------- Reducers ------------- */

export const requestTableActivityCallCreate = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    error: null,
    isSuccess: false,
    isFetching: true,
});

export const createTableActivityCallSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const createTableActivityCallFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error,
    isSuccess: false
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_TABLE_ACTIVITY_CALL_CREATE]: requestTableActivityCallCreate,
    [Types.CREATE_TABLE_ACTIVITY_CALL_SUCCESS]: createTableActivityCallSuccess,
    [Types.CREATE_TABLE_ACTIVITY_CALL_FAILURE]: createTableActivityCallFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
