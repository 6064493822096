import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import SettingMoPayment from "../models/setting.mo.payment";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestSettingMoPayment: ["data"],
    settingMoPaymentSuccess: ["data"],
    settingMoPaymentFailure: ["error"]
});

export const SettingMoPaymentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface SettingMoPaymentRedux extends IDefaultStateProps {
    dataSettingMoPayment?: SettingMoPayment | null,
}

export const INITIAL_STATE: SettingMoPaymentRedux = {
    error: null,
    isFetching: false,
    dataSettingMoPayment: null,
}

/* ------------- Reducers ------------- */
export const requestSettingMoPayment = (state = INITIAL_STATE) => ({
    ...state,
    isFetching: true,
    error: null
});

export const settingMoPaymentSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    dataSettingMoPayment: action.data
});

export const settingMoPaymentFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_SETTING_MO_PAYMENT]: requestSettingMoPayment,
    [Types.SETTING_MO_PAYMENT_SUCCESS]: settingMoPaymentSuccess,
    [Types.SETTING_MO_PAYMENT_FAILURE]: settingMoPaymentFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
