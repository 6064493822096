import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestCreateOrder: ["data"],
    createOrderSuccess: ["orderCode"],
    createOrderFailure: ["error"],
    resetOrderCreate: []
});

export const OrderTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface OrderStateRedux extends IDefaultStateProps {
    orderCode: string,
    isSuccess?: boolean
}

export const INITIAL_STATE: OrderStateRedux = {
    error: null,
    isFetching: false,
    orderCode: "",
    isSuccess: false
}

/* ------------- Reducers ------------- */
export const requestCreateOrder = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true,
    isSuccess: false,
    orderCode: "",
});

export const createOrderSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    isSuccess: true,
    ...action
});

export const createOrderFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    isSuccess: false,
    error: action.error,
    orderCode: ""
});

export const resetOrderCreate = (state = INITIAL_STATE) => ({
    ...state,
    error: null,
    isFetching: false,
    isSuccess: false
})

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_CREATE_ORDER]: requestCreateOrder,
    [Types.CREATE_ORDER_SUCCESS]: createOrderSuccess,
    [Types.CREATE_ORDER_FAILURE]: createOrderFailure,
    [Types.RESET_ORDER_CREATE]: resetOrderCreate
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
