import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ItemAction from "../../redux/item.redux"
import {IStateRedux} from "../../redux/state";
import {getPriceFormat, truncateString} from "../../utils/lang.utils";
import BoxItem from "../../components/BoxItem";
import MainLayout from "../../components/MainLayout";
import {getLiffId, getShopInfo, getTableActivityCode, setSearchQuery} from "../../utils";
import {SystemConstant} from "../../const";
import {palette} from "../../theme/material";
import {Item} from "../../models";
import LoadingProgress from "../../components/LoadingProgress";
import Shop from "../../models/shop";
import {Box} from "@mui/material";
import {getShopCode} from "../../utils/shop.util";

interface State {
    name: string
}

const ItemPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {categoryCode} = useParams();
    const dispatch = useDispatch();
    const itemReduxData = useSelector((state: IStateRedux) => state.itemRedux);
    const {data, isFetching} = itemReduxData
    const shopInfo: Shop = getShopInfo();
    const isUseStock = shopInfo.detailsJson.stockF;
    const {state} = useLocation();
    let title = "";
    if (categoryCode) {
        if (state != null) {
            const stateLocation: State = state as State;
            title = stateLocation.name
        }
    }
    const customClasses = {
        body: `${classes.listCategoryWrap}`
    }

    const goToDetails = (itemCode: string, item: Item) => {
        navigate({
            pathname: `/item/list/${itemCode}`,
            search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
        },
        {
            state: {item: item}
        }
    )
    }

    useEffect(() => {
        const searchData = {
            shopCode: shopInfo.shopCode,
            categoryCode: categoryCode,
        };
        dispatch(ItemAction.requestReadItem(searchData))
    }, [categoryCode])

    return (
        <MainLayout title={truncateString(title, SystemConstant.LIMIT_LENGTH_NAME)}
                    customClasses={customClasses}>
            {isFetching && <LoadingProgress/>}
            <Box className={classes.listCategory}>
                {!isFetching && data.map((value) =>
                    <BoxItem
                        key={value.itemCode}
                        name={truncateString(value.name, SystemConstant.LIMIT_LENGTH_NAME)}
                        formatPrice={getPriceFormat(value.priceTaxIn)}
                        goToDetails={() => {
                            goToDetails(value.itemCode, value)
                        }}
                        stock={value.stock}
                        isUseStock={isUseStock == SystemConstant.STATE.ACTIVE}
                    />
                )}
            </Box>
        </MainLayout>
    );
};

const HEIGHT_BOX = 117;
const HEIGHT_BOX_SMALL = 105;

const useStyles = makeStyles(() => ({
    listCategoryWrap: {
        backgroundColor: palette.background.paper,
        padding: "16px 0 16px 16px",
        "@media only screen and (max-width: 413px)": {
            padding: "0px"
        },
    },
    listCategory:{
        "@media only screen and (max-width: 413px)": {
            display: "grid",
            gridTemplateColumns: `${HEIGHT_BOX}px ${HEIGHT_BOX}px ${HEIGHT_BOX}px`,
            gridGap: "10px",
            padding: "10px"
        },
        "@media only screen and (max-width: 375px)": {
            gridTemplateColumns: `${HEIGHT_BOX_SMALL}px ${HEIGHT_BOX_SMALL}px ${HEIGHT_BOX_SMALL}px`,
            gridGap: "15px",
        },
    }
}));

export default ItemPage;
