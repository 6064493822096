import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import {SystemConstant} from "../const";
import OrderStatus from "../models/order.status";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestReadOrderStatus: ["data"],
    readOrderStatusSuccess: ["status"],
    readOrderStatusFailure: ["error"],
    failedDetecting: ["isFailed"],
    resetOrderStatus: []
});

export const OrderStatusTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface OrderStatusStateRedux extends IDefaultStateProps {
    status: OrderStatus[],
    isReady: boolean,
    isFailedDetected?: boolean
}

export const INITIAL_STATE: OrderStatusStateRedux = {
    error: null,
    isFetching: false,
    status: [],
    isReady: false,
    isFailedDetected: false
}

/* ------------- Reducers ------------- */
export const requestReadOrderStatus = (state = INITIAL_STATE) => ({
    ...state,
    error: null,
    status: [],
    isReady: false,
    isFetching: true
})

export const readOrderStatusSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    error: null,
    isFetching: false,
    isReady: true,
    ...action
})

export const readOrderStatusFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    error: action.error,
    isReady: false,
    isFetching: false
})

export const failedDetecting = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFailedDetected: action.isFailed
})

export const resetOrderStatus = (state = INITIAL_STATE) => ({
    ...state,
    error: null,
    isReady: false,
    isFetching: false
})

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_ORDER_STATUS]: requestReadOrderStatus,
    [Types.READ_ORDER_STATUS_SUCCESS]: readOrderStatusSuccess,
    [Types.READ_ORDER_STATUS_FAILURE]: readOrderStatusFailure,
    [Types.FAILED_DETECTING]: failedDetecting,
    [Types.RESET_ORDER_STATUS]: resetOrderStatus
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);