import {AnyAction} from "redux";
import {toSnake} from "../utils";
import {ResponseGenerator} from "../models";
import {call, put} from "redux-saga/effects";
import {TipService} from "../services";
import {ApiConstant} from "../const";
import humps from "humps";
import TableActivityTipCreateAction from "../redux/table.activity.tip.create.redux";

export function* requestTableActivityTipCreate(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(TipService.requestTableActivityTipCreate, snakeData);
        if (response.status === ApiConstant.STT_OK && response.data) {
            const responseCamel = humps.camelizeKeys(response.data);
            yield put(TableActivityTipCreateAction.readTableActivityTipCreateSuccess(responseCamel));
        } else {
            yield put(TableActivityTipCreateAction.readTableActivityTipCreateFailure(response));
        }
    } catch (e) {
        yield put(TableActivityTipCreateAction.readTableActivityTipCreateFailure(e));
    }
}
