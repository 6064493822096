import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {Box, Button, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import LangConst from "../../const/lang.const";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import TipAction from "../../redux/tip.redux";
import TableActivityTipCreateAction from "../../redux/table.activity.tip.create.redux";
import {getShopCode} from "../../utils/shop.util";
import {IStateRedux} from "../../redux/state";
import {useLocation} from "react-router-dom";
import Staff from "../../models/staff";
import {AttachmentService} from "../../services";
import theme from "../../theme/material";
import {ImageConstant, SystemConstant} from "../../const";
import {TipItem} from "./components";
import {getCustomerInfo, getTableActivityCode} from "../../utils";
import Tip from "../../models/tip";
import {truncateString} from "../../utils/lang.utils";
import {MainLayout} from "../../components";

interface State {
    staff: Staff
}

const TipPage = () => {
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_TIP);
    const dispatch = useDispatch();

    const data = useSelector((state: IStateRedux) => state.tipRedux.data);
    const responseTip = useSelector((state: IStateRedux) => state.tableActivityTipCreateRedux);
    const [imageUrl, setImageUrl] = useState("");
    const [isCompleteTip, setIsCompleteTip] = useState(false);
    const {state} = useLocation();
    let staff = new Staff();
    if (state != null) {
        const stateLocation: State = state as State;
        staff = stateLocation.staff;
    }

    const customClasses = {
        body: `${classes.root}`
    }

    const completeTip = isCompleteTip && <Box className={classes.boxCompleteTip}>
      <img src={ImageConstant.StartImg}/>
      <Box className={classes.boxAvatar}>
        <Box className={classes.avatar}>
          <Box className={classes.avatarIn}>
            <img
                loading="lazy"
                src={imageUrl ? imageUrl : ImageConstant.AvatarIcon}
                className={classes.imageAvatar}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.thankYou}>
        <Typography
            className={clsx(classes.thankYouForTip, `hgp-w6-font-family-normal light-sm-txt`)}>{getLabel(LangConst.TXT_THANK_YOU_1)}
        </Typography>
        <Typography
            className={clsx(classes.thankYouForTip2, `light-xs-txt`)}>{getLabel(LangConst.TXT_THANK_YOU_2)}</Typography>
      </Box>
      <Button className={classes.close} onClick={() => setIsCompleteTip(false)}>{getLabel(LangConst.TXT_CLOSE, {ns: LangConst.NS_CART})}</Button>
    </Box>

    const tipForStaff = (tip: Tip) => {
        dispatch(TableActivityTipCreateAction.requestTableActivityTipCreate({
            tableActivityCode: getTableActivityCode(),
            shopCode: getShopCode(),
            tipCode: tip.tipCode,
            staffCode: staff.staffCode,
            userId: getCustomerInfo().userId,
            tipName: tip.name,
            tipValue: tip.value,
        }));
    }

    useEffect(() => {
        setIsCompleteTip(false);
        if (staff && staff.attachmentId) {
            AttachmentService.readAttachment({
                id: staff.attachmentId,
            }).then(responseAttachment => {
                const encryptedBytes = new Uint8Array(responseAttachment.data as any);
                const blob = new Blob([encryptedBytes], {type: "application/image"})
                setImageUrl(URL.createObjectURL(blob));
            });
        }
        if (data.length == 0) {
            dispatch(TipAction.requestReadTip({
                shopCode: getShopCode(),
            }));
        }
    }, [staff]);

    useEffect(() => {
        if (responseTip.isFetching && responseTip.data) {
            setIsCompleteTip(true);
        }
    }, [responseTip]);

    return (
        <MainLayout customClasses={customClasses}
                    title={truncateString(getLabel(LangConst.TXT_MENU, {ns: LangConst.NS_COMMON}), SystemConstant.LIMIT_LENGTH_NAME)}>
            <Box className={classes.container}>
                <img
                    loading="lazy"
                    src={imageUrl ? imageUrl : ImageConstant.AvatarIcon}
                    className={classes.image}
                />
                <Box className={classes.information}>
                    <Typography
                        className={clsx(classes.displayName, `light-sm-txt hgp-w6-font-family`)}>{staff.displayName}</Typography>
                    {staff.introduction && <Typography
                        className={clsx(classes.introduction, `light-xxs-txt`)}>{staff.introduction}</Typography>}
                </Box>
                <Typography className={clsx(classes.pleaseSelectTip, `light-md-txt hgp-w6-font-family`)}>
                    {getLabel(LangConst.TXT_PLEASE_SELECT_TIP)}
                </Typography>
                <Grid className={classes.containerTip}
                      container spacing={1}>
                    {data.length > 0 && data.map((value) =>
                        <TipItem key={value.tipCode} tip={value} tipForStaff={tipForStaff}/>
                    )}
                </Grid>
                <Typography className={clsx(classes.itemValueIncludedBill, `light-xs-txt`)}>
                    {getLabel(LangConst.TXT_ITEM_VALUE_INCLUDED_BILL)}
                </Typography>
            </Box>
            {completeTip}
        </MainLayout>
    )
}
export default TipPage;

const useStyles = makeStyles(() => ({
    root: {
        padding: 16,
        width: "100vw",
        height: "calc(100vh -44px)",
        backgroundColor: "#f8f8f8",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none"
        },
    },
    container: {
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        position: "relative",
        paddingBottom: 16,
    },
    image: {
        width: "100%",
        height: 328,
        objectFit: "cover",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    pleaseSelectTip: {
        textAlign: "center",
        margin: "16px 0px",
    },
    information: {
        padding: 12,
        width: "calc(100% - 64px)",
        height: "auto",
        backgroundColor: theme.palette.common.white,
        position: "absolute",
        top: 246,
        left: 32,
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
    },
    displayName: {},
    introduction: {
        marginTop: 8,
    },
    containerTip: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        margin: 0,
    },
    itemValueIncludedBill: {
        textAlign: "center",
        margin: "16px auto 0px",
    },
    boxCompleteTip: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 11111,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        padding: 16,
    },
    close: {
        width: "100%",
        height: 44,
        borderRadius: 4,
        backgroundColor: "#ffaf31 !important",
        color: "#2d2d2d",
        fontSize: 16,
    },
    boxAvatar: {
        position: "absolute",
        top: "15%",
        zIndex: 11111,
    },
    avatar: {
        width: 200,
        height: 200,
        border: "10px #ffaf31 solid",
        borderRadius: "50%",
    },
    avatarIn: {
        width: 180,
        height: 180,
        border: "11.5px #ffffff solid",
        borderRadius: "50%",
    },
    imageAvatar: {
        width: 158,
        height: 158,
        objectFit: "cover",
        borderRadius: "50%"
    },
    thankYou: {
        height: "auto",
        width: "80%",
        backgroundColor: "white",
        position: "absolute",
        top: "calc(15% + 168px)",
        zIndex: 11110,
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
        padding: "32px 16px 16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    thankYouForTip: {
        textAlign: "center",
        marginBottom: 4,
    },
    thankYouForTip2: {
        textAlign: "center",
    },
}));

