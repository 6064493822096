const white = "#FFFFFF";
const black = "#000000";

export default {
    black: black,
    white: white,
    primary: {
        contrastText: white,
        main: "#00c129"
    },
    error: {
        contrastText: white,
        main: "#ff4b40",
        secondary: "#ff3e54"
    },
    grey: {
        warmGrey: "#838383",
        200: "#D8D8D8",
        300: "#999999",
        400: "#707070",
        500: "#525252",
        600: "#3A3A3A",
        700: "#2d2d2d",
        800: "#1B1B1B",
        900: "#0A0A0A",
    }, text: {
        primary: black, secondary: "#f4f5f6", orange: "#ffaf31", warmGrey: "#838383"
    }, background: {
        default: white,
        paper: "#f4f5f6",
    }, divider: "#dddddd",
};
