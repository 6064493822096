import React, {useEffect, useState} from "react";
import {getFailedOrderStatus, getShopInfo, uuid} from "../../utils";
import {Avatar, Box, Icon, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import HistoryAction from "../../redux/history.redux";
import {IStateRedux} from "../../redux/state";
import {ImageConstant, KeyConstant, SystemConstant} from "../../const";
import {palette} from "../../theme/material";
import MainLayout from "../../components/MainLayout";
import {getPriceFormat} from "../../utils/lang.utils";
import ItemOrderHistory from "./compoments/ItemOrderHistory";
import LangConst from "../../const/lang.const";
import {useTranslation} from "react-i18next";
import TableActivity from "../../models/table.activity";
import LoadingProgress from "../../components/LoadingProgress";
import OrderStatusAction from "../../redux/order.status.redux";
import OrderFailedPopup from "../CartPage/components/OrderFailedPopup";

const OrderHistory = () => {
    const historyReduxData = useSelector((state: IStateRedux) => state.historyRedux);
    const isFailedDetected = useSelector((state: IStateRedux) => state.orderStatusRedux.isFailedDetected);
    const {data, isFetching} = historyReduxData;
    const dispatch = useDispatch();
    const classes = useStyles();
    const shopInfo = getShopInfo();
    const [displayFailedDetected, setDisplayFailedDetected] = useState(false)
    let tableActivityCode: string | null = null
    const tableActivity = localStorage.getItem(KeyConstant.KEY_TABLE_ACTIVITY)
    if (tableActivity) {
        const tableActivityJson: TableActivity = JSON.parse(tableActivity)
        if (tableActivityJson) {
            tableActivityCode = tableActivityJson.tableActivityCode
        }
    }

    const {t: getLabel} = useTranslation(LangConst.NS_ORDER_HISTORY)
    const {t: getCartLabel} = useTranslation(LangConst.NS_CART)
    const title = getLabel(LangConst.TXT_TITLE_HISTORY);
    const customClasses = {
        body: `${classes.orderHistoryPageWrap}`
    }

    useEffect(() => {
        if (tableActivityCode) {
            const searchData = {
                shopCode: shopInfo.shopCode,
                tableActivityCode: tableActivityCode,
            };
            dispatch(HistoryAction.requestReadHistory(searchData));
        }
    }, []);

    useEffect(() => {
        if (getFailedOrderStatus() || isFailedDetected) {
            localStorage.removeItem(KeyConstant.KEY_FAILED_ORDER_DETECTED)
            dispatch(OrderStatusAction.failedDetecting(false))
            setDisplayFailedDetected(true)
        }
    }, [])

    return (
        <MainLayout title={title} customClasses={customClasses}>
            {isFetching && <LoadingProgress/>}
            {displayFailedDetected && <OrderFailedPopup/>}
            {!isFetching && (data.moOrderHistory.length > 0 || data.bHandyOrderHistory.length > 0 || data.bRegOrderHistory.length > 0) && (
                <>
                    <Box className={`${classes.orderHistoryListWrap} no-scrollbar`}>
                        <Box>
                            {
                                data.moOrderHistory.map(valueMoOrderHistory =>
                                    <Box key={uuid()} className={`${classes.moOrderHistoryWrap}`}>
                                        <Box className={`${classes.lineAvatarWrap}`}>
                                            {valueMoOrderHistory.picture != null ?
                                                <Avatar src={valueMoOrderHistory.picture} className={`${classes.image}`}/> :
                                                <Avatar className={`${classes.image}`}/>
                                            }
                                        </Box>
                                        <Box width={"100%"}>
                                            {
                                                valueMoOrderHistory.history.map(valueHistory =>
                                                    <Box key={uuid()} className={`${classes.itemOrderHistoryWrap}`}>
                                                        <ItemOrderHistory data={valueHistory} isFailed={(valueHistory.createStatus == SystemConstant.ORDER_STATUS.FAILED)}/>
                                                        {valueHistory.itemDetailContentOrderList != undefined &&
                                                            valueHistory.itemDetailContentOrderList.filter(idc => idc.itemCode != null && idc.itemCode != "").map(value =>
                                                                <ItemOrderHistory key={uuid()} data={value}
                                                                                  isItemDetailContent={true}/>
                                                            )
                                                        }
                                                    </Box>)
                                            }
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                        <Box>
                            {
                                data.bHandyOrderHistory.map(item =>
                                    <Box key={uuid()} className={`${classes.moOrderHistoryWrap}`}>
                                        <Box className={`${classes.lineAvatarWrap}`}>
                                            {/* <Avatar src={ImageConstant.HandyIcon} className={`${classes.image}`}/> */}
                                            <Avatar sx={{bgcolor: "#ebebeb"}} className={`${classes.image}`}>
                                                <Icon fontSize="small">
                                                    <img src={ImageConstant.HandyIcon}/>
                                                </Icon>
                                            </Avatar>
                                        </Box>
                                        <Box width={"100%"}>
                                            <Box key={uuid()} className={`${classes.itemOrderHistoryWrap}`}>
                                                <ItemOrderHistory data={item}/>
                                                {item.itemDetailContentOrderList != undefined &&
                                                    item.itemDetailContentOrderList.filter(idc => idc.itemCode != null && idc.itemCode != "").map(value =>
                                                        <ItemOrderHistory key={uuid()} data={value}
                                                                          isItemDetailContent={true}/>
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                )}

                            {
                                data.bRegOrderHistory.map(item =>
                                    <Box key={uuid()} className={`${classes.moOrderHistoryWrap}`}>
                                        <Box className={`${classes.lineAvatarWrap}`}>
                                            <Avatar sx={{bgcolor: "#ebebeb"}} className={`${classes.image}`}>
                                                <Icon fontSize="small">
                                                    <img src={ImageConstant.HandyIcon}/>
                                                </Icon>
                                            </Avatar>
                                        </Box>
                                        <Box width={"100%"}>
                                            <Box key={uuid()} className={`${classes.itemOrderHistoryWrap}`}>
                                                <ItemOrderHistory data={item}/>
                                                {item.itemDetailContentOrderList != undefined &&
                                                    item.itemDetailContentOrderList.filter(idc => idc.itemCode != null && idc.itemCode != "").map(value =>
                                                        <ItemOrderHistory key={uuid()} data={value}
                                                                          isItemDetailContent={true}/>
                                                    )
                                                }

                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                        </Box>

                    </Box>
                    <Box className={classes.totalOrderHistoryWrap}>
                        <Typography component="p">{getLabel(LangConst.TXT_TOTAL_ORDER)} &nbsp;</Typography>
                        <Typography component={"h6"}>{getPriceFormat(data.total)}</Typography>
                        <Typography component="p">&nbsp; {getLabel(LangConst.TXT_TAX_INCLUDED)}</Typography>
                    </Box>
                </>
            )}
        </MainLayout>
    );
};

export default OrderHistory;


const useStyles = makeStyles(() => ({
    orderHistoryPageWrap: {
        backgroundColor: palette.background.paper,
        padding: "0",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
    },
    orderHistoryListWrap: {
        height: "100%",
        overflow: "auto"
    },
    totalOrderHistoryWrap: {
        background: palette.white,
        width: "100%",
        height: 57,
        borderTop: `1px solid ${palette.divider}`,
        zIndex: 2,
        padding: 16,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        "&>p": {
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "22px"
        },
        "&>h6": {
            fontSize: "24px",
            fontWeight: "600",
        }
    },
    totalOrderHistory: {
        lineHeight: "16px",
    },
    itemOrderHistoryLineMax: {
        display: "flex",
        alignItems: "center",
        backgroundColor: palette.white,
        height: 77,
        marginTop: 12,
        "&:last-child": {
            marginBottom: 12
        },
        padding: 16
    },
    image: {
        width: 45,
        height: 45,
        marginRight: 16,
        marginTop: 16
    },
    moOrderHistoryWrap: {
        background: palette.white,
        display: "flex",
        width: "100%",
        padding: "0 16px",
        marginBottom: 16
    },
    itemOrderHistoryWrap: {
        borderBottom: `1px solid ${palette.divider}`,
        "&:last-child": {
            borderBottom: "unset"
        },
    },
    lineAvatarWrap: {}

}));
