import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import {Hodai} from "../../../models";
import {useTranslation} from "react-i18next";
import LangConst from "../../../const/lang.const";
import {Box, Typography} from "@mui/material";
import clsx from "clsx";
import {ImageConstant} from "../../../const";
import {getPriceFormatYen} from "../../../utils/lang.utils";
import theme from "../../../theme/material";

const HodaiItem = (props: HodaiItemProps) => {
    const {value, checkButtonSure} = props;
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_HODAI);
    const [orderNumber, setOrderNumber] = useState(value.orderNumber);
    const [isShowDetails, setIsShowDetails] = useState(false);

    const addIcon = ImageConstant.AddActiveIcon;
    const minusIcon = orderNumber == 0 ? ImageConstant.MinusIcon : ImageConstant.MinusActiveIcon;

    const onChangeOrderNumberByItemCode = (orderNumber: number) => {
        checkButtonSure(value.itemCode, orderNumber);
        if (orderNumber >= 0) {
            setOrderNumber(orderNumber);
            value.orderNumber = orderNumber;
        }
    }

    return (
        <Box className={classes.root}>
            <Typography className={clsx(classes.name, `hgp-w6-font-family light-xs-txt`)}>{value.name}</Typography>
            <Box className={classes.boxPrice}>
                <Typography className={clsx(classes.yen, `hvt-font-family light-xxs-txt`)}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}</Typography>
                <Typography className={clsx(classes.price, `hvt-font-family dark-lg-txt`)}>
                    {getPriceFormatYen(value.priceTaxIn)}
                </Typography>
            </Box>
            <img src={ImageConstant.DetailsIcon} className={classes.details}
                 onClick={() => setIsShowDetails(true)}/>
            <Box className={classes.boxChangeOrderNumber}>
                <img src={minusIcon} className={classes.image} onClick={() => {
                    onChangeOrderNumberByItemCode(orderNumber - 1);
                }}/>
                <Typography
                    className={`hvt-font-family dark-lg-txt`}>{orderNumber}</Typography>
                <img src={addIcon} className={classes.image} onClick={() => {
                    onChangeOrderNumberByItemCode(orderNumber + 1)
                }}/>
            </Box>
            <Box className={clsx(classes.hodaiItemDetails, isShowDetails && classes.displayBlock)}>
                <Box className={classes.containerHodaiItemDetails}>
                    <img src={ImageConstant.CloseIcon} className={classes.imageClose}
                         onClick={() => setIsShowDetails(false)}/>
                    <Typography
                        className={clsx(classes.nameDetails, `light-md-txt hgp-w6-font-family`)}>{value.name}</Typography>
                    <Typography
                        className={clsx(classes.descriptionDetails, `light-xs-txt`)}>{value.description}</Typography>
                    <Typography
                        className={clsx(classes.descriptionDetails, `light-md-txt hgp-w6-font-family`)}>{getLabel(LangConst.TXT_TARGET_MENU)}</Typography>
                    <Box className={classes.boxItemDetails}>
                        {value.selectItemList && value.selectItemList.map((item) =>
                            <Typography key={item.itemCode} className={`light-xs-txt`}>・{item.itemName}</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );

};

interface HodaiItemProps {
    key: string,
    value: Hodai,
    checkButtonSure: (itemCode: string, orderNumber: number) => void,
}

export default HodaiItem;

const useStyles = makeStyles(() => ({
    root: {
        height: 118,
        width: "100%",
        marginBottom: 16,
        borderRadius: 4,
        boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.1)",
        "&:first-child": {
            marginTop: 16
        },
        padding: "12px 16px",
        position: "relative",
    },
    name: {},
    details: {
        position: "absolute",
        right: 16,
        top: 28,
    },
    boxPrice: {
        display: "flex",
        marginTop: 8,
        alignItems: "baseline",
    },
    yen: {
        marginRight: 4,
        lineHeight: 1.5,
        fontWeight: "bold",
    },
    price: {
        lineHeight: 1.5,
        fontWeight: "bold",
    },
    boxChangeOrderNumber: {
        display: "flex",
        width: 88,
        justifyContent: "space-between",
        margin: "14px auto 0px",
    },
    image: {
        width: 24,
        height: 24,
    },
    hodaiItemDetails: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 11111,
        display: "none",
        alignItems: "center",
        justifyContent: "center",
    },
    displayBlock: {
        display: "flex",
    },
    containerHodaiItemDetails: {
        width: 328,
        height: 520,
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        padding: 16,
        position: "relative",
    },
    imageClose: {
        position: "absolute",
        top: 16,
        right: 16,
        width: 24, height: 24,
    },
    nameDetails: {
        marginTop: 48,
        width: "100%",
        color: theme.palette.common.black,
    },
    descriptionDetails: {
        marginTop: 16,
        width: "100%",
        color: theme.palette.common.black,
    },
    boxItemDetails: {
        marginTop: 8,
        width: 296,
        height: 334,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none"
        },
    }
}));
