export default class Holiday {
    created?: number | null;
    details?: string;
    dates?: string | null;
    datesArray?: string[] | null; //[yyyy/mm/dd,yyyy/mm/dd]
    modified?: number | null;
    shopCode?: string;

    constructor() {
        this.shopCode = "";
        this.details = "";
        this.dates = null;
        this.datesArray = null;
        this.created = 0;
        this.modified = 0;
    }

    setDatesArray() {
        if (this.dates != null) {
            this.datesArray = JSON.parse(this.dates)
        }
        return this
    }

}