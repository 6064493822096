import {API_ENDPOINT} from "../config";

const ApiConstant = {
    // Common
    BASE_URL: API_ENDPOINT,
    HEADER_DEFAULT: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8;imageFile.type",
        Authorization: ""
    },
    TIMEOUT: 10000,

    // HTTP Status
    STT_OK: 200,
    STT_BAD_REQUEST: 400,
    STT_UNAUTHORIZED: 401,
    STT_FORBIDDEN: 403,
    STT_INTERNAL_SERVER: 500,

    // Check in mode
    TABLE_MODE: "1",
    TABLE_ACTIVITY_MODE: "2",

    // Api
    LOGIN: "/auth/login",
    CATEGORY_READ: "/category/read",
    ITEM_READ: "/item/read",
    ITEM_DETAIL_READ: "/item/detail/read",
    ITEM_DETAIL_CONTENT_READ: "/item/detail/content/read",
    TABLE_ACTIVITY_READ: "/table/activity/read",
    TABLE_ACTIVITY_CREATE: "/table/activity/create",
    TABLE_ACTIVITY_UPDATE: "/table/activity/update",
    SHOP_READ: "/shop/read",
    ORDER_CREATE: "/order/create",
    ATTACHMENT_READ:"/attachment/read",
    ORDER_STATUS_READ: "order/status",
    HOLIDAY_READ: "/holiday/read",
    SETTING_MO_TOP_READ: "/setting/mo/top/read",
    SETTING_MO_PAYMENT_READ: "/setting/mo/payment/read",
    LANGUAGE_READ: "language/read",
    TABLE_ACTIVITY_CALL_CREATE: "table/activity/call/create",
    HODAI_READ: "hodai/read",
    PLAN_READ: "plan/read",
    PLAN_ITEM_READ: "plan/item/read",
    TIP_READ: "tip/read",
    STAFF_READ: "staff/read",
    TABLE_ACTIVITY_TIP_CREATE: "table/activity/tip/create",
};

export default ApiConstant;
