export default {
    TXT_TIP_THE_STAFF: "직원에게 팁을 주자",
    TXT_TIP_CONTENT: "응원하고 싶은 감사를 전하고 싶은 마음을 \n 스탭에게 칩으로 줄 수 있습니다.",
    TXT_TIP_1: "정중한 접객 대응에 호감!",
    TXT_TIP_2: "추천이나 알레르기 등\n" + "친절하게 해주셨어요!",
    TXT_TIP_3: "좌석 이동 희망에도 \n" + "웃는 얼굴로 대응해 주었다!",
    TXT_LET_SEND_TIP : "이런 때 꼭 직원에게 칩을 보냅니다.",
    TXT_GIVE_TIP: "칩을 제공",
    TXT_TIP_TITLE: "칩을 주고 싶다. \n 직원을 선택하십시오",
    TXT_PLEASE_SELECT_TIP: "칩을 선택하세요",
    TXT_ITEM_VALUE_INCLUDED_BILL: "항목의 금액은 회계에 포함됩니다.",
    TXT_THANK_YOU_1: "칩에 감사드립니다!",
    TXT_THANK_YOU_2: "팁을 주셔서 감사합니다. 나중에 호가 인사를 드리겠습니다. 선물을 드리겠습니다!",
}
