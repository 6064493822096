import {createActions, createReducer} from "reduxsauce";
import {AnyAction} from "redux";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  resetError: [],
  failure: ["data"]
});

export const ErrorTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  error: null, // Detail content of error
  status: null
};

/* ------------- Reducers ------------- */
export const raise = (state: any, action: AnyAction) => {
  const data = action.data ? action.data : {};
  return { ...state, ...data };
};
export const reset = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FAILURE]: raise,
  [Types.RESET_ERROR]: reset
});
