import OrderDetail from "./order.detail";
import {uuid} from "../utils";

export default class Order {
    orderCode: string
    shopCode: string
    comment: string | null
    requestId: string
    transactionId: string
    version: number | null
    state: number
    subtotal: number | null
    total: number | null
    orderTime: number | null
    tableActivityCode: string
    discountType: number | null
    discountPrice: number | null
    discountValue: number | null
    customerNumber: number | null
    orderDetails: OrderDetail[]


    constructor() {
        this.orderCode = uuid()
        this.shopCode = ""
        this.comment = ""
        this.requestId = uuid()
        this.transactionId = uuid()
        this.version = 0
        this.state = 1
        this.subtotal = 0
        this.total = 0
        this.orderTime = Math.floor(Date.now() / 1000)
        this.tableActivityCode = ""
        this.discountType = null
        this.discountPrice = null
        this.discountValue = null
        this.customerNumber = 1
        this.orderDetails = []
    }
}
