export default {
    TXT_PAYMENT_TITLE: "お会計",
    TXT_BILL_AMOUNT: "ご請求額",
    TXT_PAYMENT_TAX_INCLUDED: "(税込)",
    TXT_PAYMENT_SUBTOTAL: "小計",
    TXT_PAYMENT_SALE_TAX: "消費税",
    TXT_PAYMENT_CHECK_OUT: "お会計する",
    TXT_PAYMENT_TOTAL: "合計(税込)",
    TXT_PAYMENT_CANCEL_CHECK_OUT: "お会計を中止する",
    TXT_PAYMENT_PLEASE_SHOW_SCREEN_STAFF: "レジにてこちらの画面をスタッフにお見せください。",
    TXT_AFTER_CONFIRMING_PAYMENT: "お会計を確定すると、メニューを注文することができなくなります。よろしいですか？",
    TXT_PAYMENT_STOP_PAYMENT: "メニューを再度注文する場合は、お会計を中止してください。",
}
