import {AnyAction} from "redux";
import {toCamel, toSnake} from "../utils";
import {call, put} from "redux-saga/effects";
import SettingMoPaymentAction from "../redux/setting.mo.payment.redux";
import {ResponseGenerator} from "../models";
import {SettingMoPaymentService} from "../services";
import { ApiConstant } from "../const";
import SettingMoPayment from "../models/setting.mo.payment";

export function* requestSettingMoPayment(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(SettingMoPaymentService.readSettingMoPayment, snakeData);
        if (response.status === ApiConstant.STT_OK && response.data) {
            const settingMoPaymentData: SettingMoPayment = Object.assign(new SettingMoPayment(), toCamel(response.data));
            yield put(SettingMoPaymentAction.settingMoPaymentSuccess(settingMoPaymentData));
        } else {
            yield put(SettingMoPaymentAction.settingMoPaymentFailure(response.data));
        }
    } catch
        (e) {
        yield put(SettingMoPaymentAction.settingMoPaymentFailure(e));
    }
}
