import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {Item} from "../../models";
import {getLiffId, getShopInfo, getTableActivityCode, setSearchQuery, uuid} from "../../utils";
import {ItemDetailInfo, ItemInfo} from "./components";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ItemDetailAction from "../../redux/item.detail.redux";
import {IStateRedux} from "../../redux/state";
import ItemDetail from "../../models/item.detail";
import {Box, Button, Typography} from "@mui/material";
import {ImageConstant, KeyConstant, PathConstant, SystemConstant} from "../../const";
import {palette} from "../../theme/material";
import {calculateStock, checkUseStock, getOrderDetailByItem, updateOrderDetailByItemCode} from "../../utils/cart.utils";
import ItemDetailContent from "../../models/item.detail.content";
import LangConst from "../../const/lang.const";
import {useTranslation} from "react-i18next";
import {getShopCode} from "../../utils/shop.util";
import MainLayout from "../../components/MainLayout";
import {getPriceFormatYen, truncateString} from "../../utils/lang.utils";
import clsx from "clsx";

interface State {
    item: Item
}

const ItemDetailsPage = () => {
    const dispatch = useDispatch();
    const {itemCode} = useParams();
    const navigate = useNavigate();
    const shopInfo = getShopInfo();
    const [isInvalidSelect, setIsInvalidSelect] = useState(false);
    const itemDetails: { [key: string]: ItemDetail; } = useSelector((state: IStateRedux) => state.itemDetailRedux.data);
    const image = useSelector((state: IStateRedux) => state.itemDetailRedux.image);
    let item = new Item()
    const {state} = useLocation();
    if (state != null) {
        const stateLocation: State = state as State;
        item = stateLocation.item
    }
    const classes = useStyles();
    const customClasses = {
        body: `${classes.listCategoryWrap}`
    }

    useEffect(() => {
        if (checkUseStock()) {
            calculateStock(item)
        }
    }, [])

    useEffect(() => {
        if (state != null) {
            const stateLocation: State = state as State;
            item = stateLocation.item;
            const moItemDetailCodes = item.moItemDetailCodes;
            const itemDetailCodes: string[] = [];
            if (moItemDetailCodes != null) {
                moItemDetailCodes.forEach(value => {
                    itemDetailCodes.push(value.itemDetailCode)
                })
            }
            const searchData = {
                shopCode: shopInfo.shopCode,
                itemDetailCodes: itemDetailCodes,
            };
            dispatch(ItemDetailAction.requestReadItemDetail(searchData))

            if (item.moAttachmentId) {
                dispatch(ItemDetailAction.requestReadAttachment({
                    type: SystemConstant.ATTACHMENT_TYPE_ITEM,
                    id: item.moAttachmentId
                }))
            }
        }

    }, [state])

    const moItemDetails: ItemDetail[] = []

    if (item.moItemDetailCodes != null && itemDetails != null && Object.keys(itemDetails).length > 0) {
        item.moItemDetailCodes.forEach(value => {
            const itemDetailValue = itemDetails[value.itemDetailCode]
            if (itemDetailValue != null) {
                itemDetailValue.isRequired = value.isRequired
                itemDetailValue.minNumber = value.minNumber
                itemDetailValue.maxNumber = value.maxNumber
                itemDetailValue.sortOrder = value.sortOrder
                moItemDetails.push(itemDetailValue)
            }
        })
    }

    // const sumCurrentAmountOfCart = getSumCurrentAmountOfCart();

    const [sumItemAmount, setSumItemAmount] = useState<number>(getOrderDetailByItem(item).priceTaxIn * 1)

    const [orderDetail, setOrderDetail] = useState(getOrderDetailByItem(item))
    const onChangeOrderNumberByItemCode = (orderNumber: number) => {
        if (orderNumber >= 0 && ((item.stock != null && checkUseStock() && ((orderDetail.orderNumber == item.stock && orderNumber < orderDetail.orderNumber) || orderDetail.orderNumber < item.stock)) || !checkUseStock() || item.stock == null)) {
            setOrderDetail(
                {
                    ...orderDetail,
                    orderNumber: orderNumber
                }
            )

            let sumDetail = 0
            moItemDetails.forEach(value => {
                if (value.itemDetailContents) {
                    value.itemDetailContents.forEach(val => {
                        if (val.orderNumber > 0) {
                            sumDetail += val.priceTaxIn * val.orderNumber
                        }
                    })
                }
            })

            setSumItemAmount((item.priceTaxIn * orderNumber) + sumDetail)
        }
    }

    const checkCartInvalidSelect = () => {
        let temporary = true
        const requiredItemDetail = moItemDetails.filter(value => value.isRequired && value.itemDetailContents && value.itemDetailContents.length > 0 && !value.isCourseF)
        requiredItemDetail.forEach(value => {
            const orderNumberTotal = orderDetail.itemDetailContent.filter(idc => idc.itemDetailCode == value.itemDetailCode).reduce((total, idc) => total + idc.orderNumber, 0);
            const selectedRequiredItem = (value.minNumber != null && orderNumberTotal >= value.minNumber) || value.minNumber == null;
            if (selectedRequiredItem) {
                temporary = false
            }
        });
        if (requiredItemDetail.length == 0) {
            temporary = false
        }
        setIsInvalidSelect(temporary)
    }

    const onChangeCart = (itemCode?: string) => {
        const isPreview = localStorage.getItem(KeyConstant.KEY_IS_PREVIEW)
        if (isPreview != null && isPreview == '1') {
            return
        }
        if (itemCode != null) {
            checkCartInvalidSelect();

            if (orderDetail.orderNumber > 0 && !isInvalidSelect) {
                let orderDetailClone = orderDetail
                const updatedList: ItemDetailContent[] = orderDetailClone.itemDetailContent
                // Check item have course, auto add item detail content no id
                moItemDetails.filter(value => value.itemDetailContents && value.itemDetailContents.length > 0 && value.isCourseF).map(
                    id => {
                        id.itemDetailContents.map(
                            itemDetailContent => {
                                updatedList.push({
                                    ...itemDetailContent,
                                    orderNumber: 1,
                                    orderDetailCode: uuid(),
                                    itemName: itemDetailContent.name,
                                    total: itemDetailContent.priceTaxIn * orderDetail.orderNumber,
                                    subtotal: itemDetailContent.price * orderDetail.orderNumber,
                                    tax: itemDetailContent.tax
                                })
                            }
                        )
                        orderDetailClone = {
                            ...orderDetail,
                            itemDetailContent: updatedList
                        }
                    }
                )

                updateOrderDetailByItemCode(orderDetailClone)
                navigate({
                    pathname: PathConstant.CATEGORY,
                    search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
                })
            }
        }
    }

    const onChangeItemDetailContentOrderNumber = (itemDetailCode: string, itemDetailContentCode: string, orderNumber: number, isDeleteOtherItemDetailDetailContentCode?: boolean | null) => {
        const itemDetail = moItemDetails.find(value => value.itemDetailCode === itemDetailCode)
        if (itemDetail) {
            // Start logic Delete other item detail content when maxNumber of item detail is 1
            if (isDeleteOtherItemDetailDetailContentCode != null && isDeleteOtherItemDetailDetailContentCode) {
                itemDetail.itemDetailContents.forEach(value => {
                    if (value.itemDetailContentCode !== itemDetailContentCode) {
                        value.orderNumber = 0
                    }
                })
                orderDetail.itemDetailContent.forEach(value => {
                    if (value.itemDetailContentCode !== itemDetailContentCode && value.itemDetailCode == itemDetailCode) {
                        value.orderNumber = 0
                    }
                })
            }
            // End logic Delete other item detail content when maxNumber of item detail is 1

            const itemDetailContent = itemDetail.itemDetailContents.find(value => value.itemDetailContentCode === itemDetailContentCode)
            if (itemDetailContent) {
                const updatedList: ItemDetailContent[] = []
                updatedList.push(...orderDetail.itemDetailContent.filter(value => value.orderNumber > 0 && value.itemDetailContentCode !== itemDetailContentCode))
                if (orderNumber > 0) {
                    updatedList.push({
                        ...itemDetailContent,
                        orderNumber: orderNumber,
                        orderDetailCode: uuid(),
                        itemName: itemDetailContent.name,
                        total: itemDetailContent.priceTaxIn * orderNumber,
                        subtotal: itemDetailContent.price * orderNumber,
                        tax: itemDetailContent.tax
                    })
                }

                setOrderDetail({
                    ...orderDetail,
                    itemDetailContent: updatedList
                })
            }

            let sumDetail = 0

            moItemDetails.forEach(value => {
                if (value.itemDetailContents) {
                    value.itemDetailContents.forEach(val => {
                        if (val.orderNumber > 0) {
                            sumDetail += val.priceTaxIn * val.orderNumber
                        }
                    })
                }
            })
            setSumItemAmount((orderDetail.priceTaxIn * orderDetail.orderNumber) + sumDetail)
        }
    }

    const changeStatusItemDetailContents = (itemDetailCodes: string) => {
        moItemDetails.forEach(value => {
            if (value.itemDetailCode == itemDetailCodes) {
                value.isOpenItemDetailContents = !value.isOpenItemDetailContents;
            }
        });
    }

    useEffect(() => {
        checkCartInvalidSelect();
    }, [orderDetail, moItemDetails]);

    const addIcon = ((item.stock != null && checkUseStock() && orderDetail.orderNumber < item.stock) || !checkUseStock() || item.stock == null) ? ImageConstant.AddActiveIcon : ImageConstant.AddIcon
    const minusIcon = (orderDetail.orderNumber > 0) ? ImageConstant.MinusActiveIcon : ImageConstant.MinusIcon
    const {t: getLabel} = useTranslation(LangConst.NS_CATEGORY)

    return (
        <MainLayout customClasses={customClasses}
                    title={truncateString(getLabel(LangConst.TXT_MENU, {ns: LangConst.NS_COMMON}), SystemConstant.LIMIT_LENGTH_NAME)}>
            <Typography
                className={clsx(classes.itemNameItem, `light-md-txt hgp-w6-font-family-normal`)}>{item.name}</Typography>
            <Box className={`${classes.itemDetailWrap} no-scrollbar`}>
                <ItemInfo item={item} image={image}/>
                {moItemDetails.sort((a, b) => ((a.sortOrder != null && b.sortOrder != null) ? (a.sortOrder > b.sortOrder) : (a.itemDetailCode > b.itemDetailCode)) ? 1 : -1)
                    .filter(
                        value => value.itemDetailContents && value.itemDetailContents.length > 0 &&
                            !value.isCourseF
                    ).map((value: ItemDetail) =>
                        <ItemDetailInfo
                            data={value}
                            isInValidSelect={isInvalidSelect}
                            key={uuid()}
                            pickItemDetailContent={(itemDetailCode, itemDetailContentCode, orderNumberChange, isDeleteOtherItemDetailDetailContentCode) => onChangeItemDetailContentOrderNumber(itemDetailCode, itemDetailContentCode, orderNumberChange, isDeleteOtherItemDetailDetailContentCode)}
                            changeStatusItemDetailContents={() => changeStatusItemDetailContents(value.itemDetailCode)}
                        />
                    )}
            </Box>
            <Box className={`${classes.addItemToCartWrap}`}>
                <Box className={`${classes.changeOrderNumberWrap}`}>
                    <Box className={classes.boxPrice2}>
                        <Typography className={clsx(classes.yen, `hvt-font-family light-sm-txt`)}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}</Typography>
                        <Typography className={clsx(classes.price, `hvt-font-family light-xxl-txt`)}>
                            {getPriceFormatYen(sumItemAmount)}
                        </Typography>
                    </Box>
                    <Box className={classes.boxPrice}>
                        <img className={`${classes.img}`}
                             onClick={() => {
                                 onChangeOrderNumberByItemCode(orderDetail.orderNumber - 1);
                             }}
                             src={minusIcon}/>
                        <Typography
                            className={`${classes.orderNumber} hvt-font-family dark-lg-txt`}>{orderDetail.orderNumber}</Typography>
                        <img
                            className={`${classes.img}`}
                            onClick={() => {
                                onChangeOrderNumberByItemCode(orderDetail.orderNumber + 1);
                            }}
                            src={addIcon}/>
                    </Box>
                </Box>

                <Button
                    fullWidth={true}
                    className={(!isInvalidSelect && orderDetail.orderNumber > 0) ? classes.addItemToCartButtonActive : classes.addItemToCartButton}
                    onClick={() => {
                        if (orderDetail.orderNumber > 0 && !isInvalidSelect) {
                            onChangeCart(itemCode)
                        }
                    }}>
                    <Box
                        className={clsx((!isInvalidSelect && orderDetail.orderNumber > 0) ? classes.addItemToCartTextButton : classes.addItemToCartTextButtonActive, `light-md-txt`)}>
                        {getLabel(LangConst.TXT_ADD_TO_CART)}
                    </Box>
                </Button>
            </Box>
        </MainLayout>
    );
};

const useStyles = makeStyles(() => ({
    listCategoryWrap: {
        backgroundColor: palette.white,
        padding: "0",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        position: "relative",
    },
    itemDetailWrap: {
        overflow: "auto",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f8f8f8",
    },
    errorPopup: {
        position: "absolute",
        bottom: 142,
        width: "100%"
    },
    addItemToCartWrap: {
        background: palette.white,
        width: "100%",
        height: 152,
        borderTop: `1px solid #dddddd`,
        zIndex: 2,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
    },
    changeOrderNumberWrap: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
    },
    img: {
        width: 24,
        height: 24,
    },
    orderNumber: {
        margin: "0 16px",
    },
    addItemToCartButton: {
        height: 44,
        borderRadius: 4,
        backgroundColor: "rgba(255, 175, 49, 0.25) !important",
    },
    addItemToCartButtonActive: {
        height: 44,
        borderRadius: 4,
        backgroundColor: "#ffaf31",
    },
    addItemToCartTextButton: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        color: palette.black,
    },
    addItemToCartTextButtonActive: {
        color: "rgba(0, 0, 0, 0.25)",
    },
    total: {
        color: palette.black,
    },
    boxPrice: {
        display: "flex",
    },
    boxPrice2: {
        alignItems: "baseline",
        display: "flex",
    },
    yen: {
        marginRight: 4,
        lineHeight: 1.5,
        fontWeight: "bold",
    },
    price: {
        lineHeight: 1.5,
        fontWeight: "bold",
    },
    itemNameItem: {
        height: 44,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));

export default ItemDetailsPage;
