import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import LangConst from "../const/lang.const";
import {palette} from "../theme/material";
import {FC} from "react";

interface Error {
    title?: string | null
}

const ErrorModal: FC<Error> = ({title}) => {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();
    const {t: getLabel} = useTranslation();
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.box}>
                <Typography className={'hp-w6-font-family '} variant={"h5"}>
                    {(title) ? title : getLabel(LangConst.TXT_CURRENTLY_UNAVAILABLE)}
                </Typography>
            </Box>
        </Modal>
    );
}

export default ErrorModal;

const useStyles = makeStyles(() => ({
    box: {
        position: 'absolute',
        top: '48%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: palette.white,
        width: '82%',
        height: '80%',
        borderRadius: '10px',
        boxShadow: 'none',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        '&:focus-visible': {
            outline: "unset",
        },
    }
}));
