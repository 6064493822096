export default {
    TXT_TIP_THE_STAFF: "スタッフにチップを贈ろう",
    TXT_TIP_CONTENT: "応援したい、感謝を伝えたいという気持ちを \n スタッフへチップとして贈ることができます。",
    TXT_TIP_1: "丁寧な接客対応に好感！",
    TXT_TIP_2: "おすすめやアレルギーなど\n" + "親切に解説してくれた！",
    TXT_TIP_3: "席の移動希望にも\n" + "笑顔で対応してくれた！",
    TXT_LET_SEND_TIP: "こんな時に是非スタッフへチップを送ってみましょう",
    TXT_GIVE_TIP: "チップを贈る",
    TXT_TIP_TITLE: "チップを贈りたい \n スタッフを選択してください",
    TXT_PLEASE_SELECT_TIP: "チップを選択してください",
    TXT_ITEM_VALUE_INCLUDED_BILL: "アイテムの金額は、お会計に含まれます。",
    TXT_THANK_YOU_1: "チップをありがとうございます！",
    TXT_THANK_YOU_2: "チップをありがとうございます。後程りほがご挨拶に伺います。またギフトをいただけるように精進いたします！",
}
