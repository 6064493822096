import {createApiWithToken} from "../api";
import {ApiConstant} from "../const";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const readTableActivity = (data: any) => createApiWithToken().get(ApiConstant.TABLE_ACTIVITY_READ, data);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createTableActivity = (data: any) => createApiWithToken().post(ApiConstant.TABLE_ACTIVITY_CREATE, data);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const updateTableActivity = (data: any) => createApiWithToken().put(ApiConstant.TABLE_ACTIVITY_UPDATE, data);