import {makeStyles} from "@material-ui/core";
import React from "react";
import {Box, Typography, ListItem, ListItemText} from "@mui/material";
import palette from "../../../theme/material/palette";
import {useTranslation} from "react-i18next";
import LangConst from "../../../const/lang.const";
import StringFormat from "string-format";
import {ImageConstant} from "../../../const";
import clsx from "clsx";

interface ItemDetailTitleProps {
    name: string,
    minNumber: number | null,
    maxNumber: number | null,
    isRequired: boolean,
    open: boolean,
    openItemDetailContents: (e: any) => void,
}

const ItemDetailTitle = (props: ItemDetailTitleProps) => {
    const {name, isRequired, open, minNumber, maxNumber, openItemDetailContents} = props;
    const {t: getLabel} = useTranslation(LangConst.NS_CATEGORY);
    const classes = useStyles();
    let message = "";
    let message2 = "";

    if (minNumber == null || minNumber == 0) {
        if (maxNumber == 1) {
            message2 = StringFormat(getLabel(LangConst.TXT_CHOOSE_AT_LEAST_N), maxNumber.toString());
        } else if (maxNumber && maxNumber > 1) {
            message2 = StringFormat(getLabel(LangConst.TXT_CHOOSE_UP_TO), maxNumber.toString());
        } else if (maxNumber && maxNumber == 0) {
            message2 = getLabel(LangConst.TXT_WOULD_YOU_LIKE);
        }
    } else if (minNumber && minNumber == 1) {
        if (maxNumber && maxNumber == 1) {
            message = getLabel(LangConst.TXT_REQUIRED);
            message2 = StringFormat(getLabel(LangConst.TXT_CHOOSE_AT_LEAST_N), maxNumber.toString());
        } else if (maxNumber && maxNumber > 1) {
            message = getLabel(LangConst.TXT_REQUIRED);
            message2 = StringFormat(getLabel(LangConst.TXT_CHOOSE_UP_TO), maxNumber.toString());
        }
    } else if (minNumber && minNumber >= 1 && (maxNumber == null || maxNumber == 0)) {
        message = getLabel(LangConst.TXT_REQUIRED);
        message2 = StringFormat(getLabel(LangConst.TXT_CHOOSE_AT_LEAST_N), minNumber.toString());
    } else if (minNumber && minNumber > 1 && maxNumber && minNumber == maxNumber) {
        message = getLabel(LangConst.TXT_REQUIRED);
        message2 = StringFormat(getLabel(LangConst.TXT_CHOOSE_EXACTLY_N), minNumber.toString());
    } else if (maxNumber) {
        message = getLabel(LangConst.TXT_REQUIRED);
        message2 = StringFormat(getLabel(LangConst.TXT_CHOOSE_BETWEEN), minNumber.toString(), maxNumber.toString());
    }

    return (
        <ListItem className={clsx(classes.itemDetailTitle, open && classes.itemDetailTitleOpen)}>
            <Box>
                <Typography className={`hgp-w6-font-family light-md-txt`}>{name}</Typography>
                {isRequired &&
                    <Box
                        className={`${classes.textRequired} ${classes.textRequiredRed} light-sm-txt`}>
                        {message}
                      <Typography className={`${classes.textRequired} light-xs-txt`}>
                          {message2}
                      </Typography>
                    </Box>
                }
            </Box>
            <img src={open ? ImageConstant.ArrowUpIcon : ImageConstant.ArrowDownIcon}
                 onClick={openItemDetailContents}/>
        </ListItem>
    );
};

const useStyles = makeStyles(() => ({
    itemDetailTitle: {
        backgroundColor: palette.background.default,
        padding: 16,
        margin: "16px 16px 0 16px",
        display: "flex",
        justifyContent: "space-between",
        width: "auto",
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)"
    },
    itemDetailTitleOpen: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    nameItemDetail: {
        wordBreak: "break-all"
    },
    textRequired: {
        lineHeight: 1.79,
    },
    textRequiredRed: {
        color: "#ff0000",
        display: "flex"
    },
}));

export default ItemDetailTitle;
