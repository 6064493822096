import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestLogin: ["data"],
  loginSuccess: ["data"],
  loginFailure: ["error"],
  resetLoginError: []
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: IDefaultStateProps = {
  isLoggedIn: false,
  isFetching: false,
  error: null
};

/* ------------- Reducers ------------- */
export const requestLogin = (state = INITIAL_STATE, action: AnyAction) => ({
  ...state,
  isFetching: true,
  ...action
});

export const resetError = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

export const loginSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isLoggedIn: true,
  isFetching: false
});

export const loginFailure = (state = INITIAL_STATE, action: any) => ({
  ...state,
  isLoggedIn: false,
  isFetching: false,
  error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_LOGIN]: requestLogin,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.RESET_LOGIN_ERROR]: resetError
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
