const PathConstant = {
    ROOT: "/",
    HOME: "/home",
    LOGIN: "/:shop_code/:mode/:table_code",
    NOT_FOUND: "/not-found",
    CATEGORY: "/category/list",
    SUB_CATEGORY: "/category/list/:categoryCode",
    LIST_ITEM: "/item/list/:categoryCode",
    ITEM_DETAILS: "/item/details/:itemCode",
    CART: "/cart",
    CALL: "/call",
    HISTORY: "/history",
    PAYMENT: "/payment",
    CHECK_IN: "/check-in",
    CONFIRM_HODAI: "/confirm-hodai",
    PLAN_LIST: "/plan/list",
    TIP: "/tip",
    STAFF: "/staff",
};
export default PathConstant;
