const KeyConstant = {
    KEY_TOKEN: "token",
    KEY_UID: "uid",
    KEY_MO_CUSTOMER: "mo_customer",
    KEY_SHOP_INFO: "shop_info",
    KEY_CART: "cart",
    KEY_NOTIFY: "notify",
    KEY_TABLE_ACTIVITY: "table_activity",
    KEY_TIME_ZONE: "time_zone",
    KEY_TABLE_MODE: "table_mode",
    KEY_NEW_ORDER: "new_order",
    KEY_LIFF_ID: "liff_id",
    KEY_LIFF_APP_NAME: "liff_app_name",
    KEY_FAILED_ORDER_DETECTED: "failed_order_detected",
    KEY_IS_PREVIEW: "is_preview",
    KEY_IS_HODAI: "is_hodai",
    KEY_IS_PAYMENT: "is_payment",
    KEY_DAY_OF_WEEK: "day_of_week",
    KEY_TIME_PERIOD: "time_period",
    KEY_TABLE_CODE: "table_code",
    KEY_LANG: "language",
    KEY_HODAI_CODE: "hodai_code",
};
export default KeyConstant;
