import {AnyAction} from "redux";
import {toCamel, toSnake} from "../utils";
import {call, put} from "redux-saga/effects";
import {SettingMoTopService} from "../services";
import {ApiConstant} from "../const";
import SettingMoTop from "../models/setting.mo.top";
import SettingMoTopAction from "../redux/setting.mo.top.redux";
import {ResponseGenerator} from "../models";
import humps from "humps";

export function* requestSettingMoTop(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(SettingMoTopService.readSettingMoTop, snakeData);
        if (response.status === ApiConstant.STT_OK && response.data) {
            const settingMoTopData: SettingMoTop = Object.assign(new SettingMoTop(), toCamel(response.data));
            settingMoTopData.languageChangingOption = humps.pascalize(settingMoTopData.languageChangingOption);
            yield put(SettingMoTopAction.settingMoTopSuccess(settingMoTopData));
        } else {
            yield put(SettingMoTopAction.settingMoTopFailure(response.data));
        }
    } catch
        (e) {
        yield put(SettingMoTopAction.settingMoTopFailure(e));
    }
}
