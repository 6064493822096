/**
 * Export an instance of api client
 */

import apisauce, {ApisauceConfig} from "apisauce";
import Cookies from "js-cookie";
import QueryString from "qs";
import {ApiConstant, KeyConstant} from "../const";

export const API_CONFIG = {
    baseURL: ApiConstant.BASE_URL,
    headers: ApiConstant.HEADER_DEFAULT,
    timeout: ApiConstant.TIMEOUT,
    paramsSerializer: (params: any) => QueryString.stringify(params, {arrayFormat: "repeat"})
};

export const createApiByConfig = (initConfig: ApisauceConfig) => apisauce.create(initConfig);

export const createApiWithToken = (initConfig = API_CONFIG, token: string | undefined | null) => {
    const appToken = token || Cookies.get(KeyConstant.KEY_TOKEN);
    if (appToken) {
        initConfig.headers.Authorization = `Bearer ${appToken}`;
    }
    return apisauce.create(initConfig);
};