import React from "react";
import {Provider} from "react-redux";
import store from "./redux";
import theme from "./theme/material";
import "./theme/styles";
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./language";
import AuthenticationRoute from "./router";
import {KeyConstant, PathConstant} from "./const";
import Login from "./pages/Login";
import CheckIn from "./pages/CheckInPage";
import MenuBar from "./components/MenuBar";
import Category from "./pages/CategoryPage";
import Item from "./pages/ItemPage";
import Cart from "./pages/CartPage/Cart";
import ItemDetailsPage from "./pages/ItemDetailsPage";
import OrderHistory from "./pages/OrderHistoryPage";
import HomePage from "./pages/HomePage";
import CallPage from "./pages/TableActivityCall";
import PaymentPage from "./pages/PaymentPage";
import ConfirmHodaiPage from "./pages/ConfirmHodaiPage";
import Cookies from "js-cookie";
import {hasLogin} from "./utils";
import HodaiPlanSelectPage from "./pages/HodaiPlanSelectPage";
import TipPage from "./pages/TipPage";
import StaffPage from "./pages/StaffPage";

const App = () => {
    const elTitleApp = document.getElementById("title_app");
    if (elTitleApp != null && hasLogin()) {
        const liffAppName = Cookies.get(KeyConstant.KEY_LIFF_APP_NAME);
        elTitleApp.textContent = liffAppName != undefined && liffAppName ? liffAppName : "モバイルオーダー";
    }
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <BrowserRouter>
                        <Routes>
                            <Route path="*" element={<HomePage/>}/>
                            <Route path={PathConstant.LOGIN} element={<Login/>}/>
                            {/*Private router*/}
                            <Route path={PathConstant.CHECK_IN}
                                   element={<AuthenticationRoute><CheckIn/></AuthenticationRoute>}/>
                            <Route path={PathConstant.CATEGORY}
                                   element={<AuthenticationRoute><Category/></AuthenticationRoute>}/>
                            <Route path={PathConstant.SUB_CATEGORY}
                                   element={<AuthenticationRoute><Category/></AuthenticationRoute>}/>
                            <Route path={PathConstant.LIST_ITEM}
                                   element={<AuthenticationRoute><Item/></AuthenticationRoute>}/>
                            <Route path={PathConstant.ITEM_DETAILS}
                                   element={<AuthenticationRoute><ItemDetailsPage/></AuthenticationRoute>}/>
                            <Route path={PathConstant.CART}
                                   element={<AuthenticationRoute><Cart/></AuthenticationRoute>}/>
                            <Route path={PathConstant.HISTORY}
                                   element={<AuthenticationRoute><OrderHistory/></AuthenticationRoute>}/>
                            <Route path={PathConstant.CALL}
                                   element={<AuthenticationRoute><CallPage/></AuthenticationRoute>}/>
                            <Route path={PathConstant.PAYMENT}
                                   element={<AuthenticationRoute><PaymentPage/></AuthenticationRoute>}/>
                            <Route path={PathConstant.CONFIRM_HODAI}
                                   element={<AuthenticationRoute><ConfirmHodaiPage/></AuthenticationRoute>}/>
                            <Route path={PathConstant.PLAN_LIST}
                                   element={<AuthenticationRoute><HodaiPlanSelectPage/></AuthenticationRoute>}/>
                            <Route path={PathConstant.TIP}
                                   element={<AuthenticationRoute><TipPage/></AuthenticationRoute>}/>
                            <Route path={PathConstant.STAFF}
                                   element={<AuthenticationRoute><StaffPage/></AuthenticationRoute>}/>
                        </Routes>
                        <Cart/>
                        <MenuBar/>
                    </BrowserRouter>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
};

export default App;
