export default class TableActivityCall {
    tableActivityCode: string
    userId: string
    shopCode: string
    constructor() {
        this.tableActivityCode = ""
        this.userId = ""
        this.shopCode = ""
    }
}
