import {ApiConstant} from "../const";
import {createApiWithToken} from "../api";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const readHodai = (data: any) => createApiWithToken().get(ApiConstant.HODAI_READ, data);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const readPlan = (data: any) => createApiWithToken().get(ApiConstant.PLAN_READ, data);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const readPlanItem = (data: any) => createApiWithToken().get(ApiConstant.PLAN_ITEM_READ, data);

