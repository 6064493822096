import React, {useEffect, useState} from "react";
import {Badge, Box, Button, Divider, Theme, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core";
import {getCartData, getSumCurrentAmountOfCart} from "../../utils/cart.utils";
import {useDispatch, useSelector} from "react-redux";
import {IStateRedux} from "../../redux/state";
import Order from "../../models/order";
import {getShopCode} from "../../utils/shop.util";
import OrderAction from "../../redux/order.redux";
import CartAction from "../../redux/cart.redux";
import OrderStatusAction from "../../redux/order.status.redux";
import {ImageConstant, KeyConstant, PathConstant, SystemConstant} from "../../const";
import TableActivity from "../../models/table.activity";
import theme, {palette} from "../../theme/material";
import {ItemInCart, OrderFailedPopup, OrderSuccessPopup, TotalPrice} from "./components";
import {addNewOrder, getCustomerInfo, getLiffId, getTableActivityCode, setSearchQuery} from "../../utils";
import {useTranslation} from "react-i18next";
import LangConst from "../../const/lang.const";
import {useLocation, useNavigate} from "react-router-dom";
import clsx from "clsx";
import {TipIcon} from "../../theme/images";
import StaffAction from "../../redux/staff.redux";
import TipAction from "../../redux/tip.redux";

const Cart = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [cartData, setCartData] = useState(getCartData())
    const [isOrderSuccess, setIsOrderSuccess] = useState(false)
    const [createError, setCreateError] = useState(false)
    const [showCart, setShowCart] = useState(false)
    const [showTip, setShowTip] = useState(false)
    const [showCartItem, setShowCartItem] = useState(false);
    const [showPopupTipStaff, setShowPopupTipStaff] = useState(false);
    const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);
    const cart = useSelector((state: IStateRedux) => state.cartRedux.data)
    const orderCode = useSelector((state: IStateRedux) => state.orderRedux.orderCode)
    const tableActivities = useSelector((state: IStateRedux) => state.tableActivityRedux.data)
    const dataStaff = useSelector((state: IStateRedux) => state.staffRedux.data);
    const dataTip = useSelector((state: IStateRedux) => state.tipRedux.data);
    const isSuccess = useSelector((state: IStateRedux) => state.orderRedux.isSuccess)
    const deleteItemError = useSelector((state: IStateRedux) => state.cartRedux.error)
    const createOrderError = useSelector((state: IStateRedux) => state.orderRedux.error)
    const orderStatusError = useSelector((state: IStateRedux) => state.orderStatusRedux.error)
    const tableActivityReadError = useSelector((state: IStateRedux) => state.tableActivityRedux.error)
    const classes = useStyles({isOrderSuccess: isOrderSuccess});
    const {t: getLabel} = useTranslation(LangConst.NS_CART);

    useEffect(() => {
        if (showCart) {
            dispatch(OrderAction.resetOrderCreate())
            dispatch(OrderStatusAction.resetOrderStatus())
        }
    }, [showCart == true])

    useEffect(() => {
        if (deleteItemError == null) {
            setCartData(getCartData())
            if (getCartData().length == 0) {
                setShowCart(false);
                setShowCartItem(false);
                setShowTip(!(dataStaff.length == 0 || dataTip.length == 0));
            }
        }
    }, [cart, deleteItemError])

    useEffect(() => {
        if (createOrderError) {
            setCreateError(true)
        } else {
            setCreateError(false)
        }
    }, [createOrderError])

    useEffect(() => {
        if (orderCode !== "" && isSuccess) {
            setIsOrderSuccess(true)
            addNewOrder({
                orderCode: orderCode,
                lineUserId: getCustomerInfo()["userId"]
            })
            localStorage.removeItem(KeyConstant.KEY_CART)
            dispatch(CartAction.resetCart())
            dispatch(OrderAction.resetOrderCreate())
        } else {
            setIsOrderSuccess(false)
        }
        setTimeout(() => {
            setIsOrderSuccess(false);
        }, 5000);
    }, [orderCode])

    useEffect(() => {
        if (tableActivities && tableActivities.length > 0) {
            localStorage.setItem(KeyConstant.KEY_TABLE_ACTIVITY, JSON.stringify(tableActivities[0]))
        }
    }, [tableActivities])

    useEffect(() => {
        if (pathname.includes(PathConstant.CATEGORY) && (getCartData().length !== 0 || Object.getOwnPropertyNames(getCartData()).length >= 1)) {
            setShowCart(pathname.includes(PathConstant.CATEGORY) && (getCartData().length !== 0 || Object.getOwnPropertyNames(getCartData()).length > 1));
            if (deleteItemError == null) {
                setCartData(getCartData())
            }
            setShowTip(true);
            dispatch(StaffAction.requestReadStaff({
                shopCode: getShopCode(),
            }));
            dispatch(TipAction.requestReadTip({
                shopCode: getShopCode(),
            }));
        } else {
            setShowCart(false);
            setShowTip(false);
        }
        setShowPopupTipStaff(false);
    }, [pathname]);

    useEffect(() => {
        if (pathname.includes(PathConstant.CATEGORY)) {
            setShowTip(!(dataStaff.length == 0 || dataTip.length == 0));
        }
    }, [dataStaff, dataTip])

    const toggleDrawer = () => {
        setShowCartItem(!showCartItem);
    }

    const handleSwipeConfirmButton = () => {
        setCreateError(false)
        if (tableActivityReadError) {
            setCreateError(true)
            return
        }

        const order = new Order()
        order.shopCode = getShopCode()
        const tableActivity = localStorage.getItem(KeyConstant.KEY_TABLE_ACTIVITY)

        if (tableActivity) {
            const tableActivityJson: TableActivity = JSON.parse(tableActivity)
            if (tableActivityJson && tableActivityJson.tableActivityState == SystemConstant.STATE.ACTIVE) {
                order.tableActivityCode = tableActivityJson.tableActivityCode
                order.discountPrice = tableActivityJson.discountPrice
                order.discountType = tableActivityJson.discountType
                order.discountValue = tableActivityJson.discountValue
                order.orderDetails = cartData
                dispatch(OrderAction.requestCreateOrder(order))
            } else {
                setCreateError(true)
            }
        } else {
            setCreateError(true)
        }
    }

    const deleteCartItem = () => {
        localStorage.removeItem(KeyConstant.KEY_CART);
        setShowCartItem(!showCartItem);
        setShowCart(!showCart);
        setIsOpenDeleteConfirmation(false);
    }

    const closePopupDeleteCart = () => {
        setIsOpenDeleteConfirmation(false);
    }

    const gotoStaffScreen = () => {
        navigate({
            pathname: PathConstant.STAFF,
            search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
        });
    }

    const orderSuccessPopup = (isOrderSuccess) ? <OrderSuccessPopup/> : null

    const mapItems = cartData.map((value, index) => {
        return (
            <Box key={value.orderDetailCode}>
                <ItemInCart value={value}/>
                {(index < cartData.length - 1 || value.itemDetailContent.length > 0) ?
                    <Divider/> : null}
            </Box>
        )
    })

    const errorPopup = (createError || orderStatusError) ?
        <OrderFailedPopup/> : null

    const deleteConfirmation = <Box
        className={clsx(classes.containerPopupDelete, !isOpenDeleteConfirmation && classes.hiddenCart)}>
        <Box className={classes.popupDelete}>
            <Typography className={clsx(classes.areYouSure, `light-sm-txt`)}>{getLabel(LangConst.TXT_ARE_YOU_SURE)}</Typography>
            <Button className={clsx(classes.popupDeleteButton)} onClick={deleteCartItem}>
                <Typography className={`light-md-txt`}>{getLabel(LangConst.TXT_DELETE)}</Typography>
            </Button>
            <Button className={clsx(classes.popupCancelButton)} onClick={closePopupDeleteCart}>
                <Typography className={`light-md-txt`}>{getLabel(LangConst.TXT_CANCEL)}</Typography></Button>
        </Box>
    </Box>

    const popUpTipStaff = showPopupTipStaff ?
        <Box className={classes.popupTipStaff}>
            <Box className={classes.containerTipStaff}>
                <Box className={classes.boxImageClose}>
                    <img src={ImageConstant.CloseIcon} className={classes.imageClose}
                         onClick={() => setShowPopupTipStaff(!showPopupTipStaff)}/>
                </Box>
                <img src={ImageConstant.TipTheStaffImg}/>
                <Typography
                    className={clsx(classes.titleTip, `light-xs-txt`)}>{getLabel(LangConst.TXT_TIP_CONTENT, {ns: LangConst.NS_TIP})}</Typography>
                <Box className={clsx(classes.commonReason, classes.reason1)}>
                    <Typography
                        className={clsx(classes.titleCommonReason, `hgp-w6-font-family light-sm-txt`)}>{getLabel(LangConst.TXT_TIP_1, {ns: LangConst.NS_TIP})}</Typography>
                    <img src={ImageConstant.StaffImg1}/>
                </Box>
                <Box className={clsx(classes.commonReason, classes.reason2)}>
                    <Typography
                        className={clsx(classes.titleCommonReason, `hgp-w6-font-family light-sm-txt`)}>{getLabel(LangConst.TXT_TIP_2, {ns: LangConst.NS_TIP})}</Typography>
                    <img src={ImageConstant.StaffImg2}/>

                </Box>
                <Box className={clsx(classes.commonReason, classes.reason3)}>
                    <Typography
                        className={clsx(classes.titleCommonReason, `hgp-w6-font-family light-sm-txt`)}>{getLabel(LangConst.TXT_TIP_3, {ns: LangConst.NS_TIP})}</Typography>
                    <img src={ImageConstant.StaffImg3}/>

                </Box>
                <Typography
                    className={`light-xxs-txt`}>\ {getLabel(LangConst.TXT_LET_SEND_TIP, {ns: LangConst.NS_TIP})} /</Typography>
                <Button
                    onClick={gotoStaffScreen}
                    className={clsx(classes.buttonGotoScreenTip, `light-md-txt`)}>{getLabel(LangConst.TXT_GIVE_TIP, {ns: LangConst.NS_TIP})}</Button>
            </Box>
        </Box> : null;

    return (
        <Box className={clsx(classes.root, !showCart && classes.hidden, showCartItem && classes.rootShowCartItem)}>
            {errorPopup}
            {deleteConfirmation}
            {orderSuccessPopup}
            {popUpTipStaff}
            <Box className={clsx(classes.boxCart, !showCart && classes.hiddenCart)}>
                <img src={!showCartItem ? ImageConstant.ArrowUpIcon : ImageConstant.ArrowDownIcon}
                     onClick={toggleDrawer}/>
                <Box className={clsx(showCartItem ? classes.orderDetail : (classes.hidden && classes.hiddenCart))}>
                    <Box className={classes.boxDeleteCartItem}>
                        <Typography
                            className={`hgp-w6-font-family light-sm-txt`}>{getLabel(LangConst.TXT_ITEMS_IN_CART)}</Typography>
                        <Typography className={clsx(classes.deleteButton, `light-sm-txt`)}
                                    onClick={() => setIsOpenDeleteConfirmation(true)}>{getLabel(LangConst.TXT_DELETE_ALL)}</Typography>
                    </Box>
                    <Divider/>
                    {mapItems}
                </Box>
                <Box className={classes.cart}>
                    <Box sx={{display: "flex"}}>
                        <Badge badgeContent={cartData ? cartData.length : ""} classes={{badge: classes.badge}}>
                            <img src={ImageConstant.CartIcon}/>
                        </Badge>
                        <TotalPrice totalPrice={getSumCurrentAmountOfCart()}/>
                    </Box>
                    <Button className={clsx(classes.confirmedOrder, `light-md-txt`)}
                            onClick={handleSwipeConfirmButton}>{getLabel(LangConst.TXT_CONFIRMED_ORDER)}</Button>
                </Box>
            </Box>
            <img src={TipIcon}
                 onClick={() => setShowPopupTipStaff(!showPopupTipStaff)}
                 className={clsx(classes.boxTip, (!showTip || showCartItem) && classes.hiddenTip, !showCart && classes.boxTipWhenHiddenCart)}/>
        </Box>
    );
};

export default Cart;

interface Success {
    isOrderSuccess: boolean
}

const useStyles = makeStyles<Theme, Success>(() => ({
    root: {
        height: 108,
        width: "100%",
        backgroundColor: theme.palette.common.white,
        boxShadow: "0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
        position: "relative",
        padding: "8px 16px 16px 16px",
    },
    rootShowCartItem: {
        height: "40vh",
    },
    hidden: {
        height: 0,
        padding: 0,
    },
    hiddenCart: {
        display: "none !important"
    },
    hiddenTip: {
        display: "none"
    },
    boxCart: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "visible",
    },
    cart: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: 44,
        marginTop: 16,
    },
    boxTip: {
        position: "absolute",
        right: 10,
        bottom: 120,
        zIndex: 1000,
    },
    boxTipWhenHiddenCart: {
        bottom: 10,
    },
    items: {
        overflow: "auto",
        height: "100%",
    },
    orderConfirm: {
        padding: "0 16px 16px",
        backgroundColor: palette.white,
        width: "100%"
    },
    confirmedOrder: {
        width: 132,
        height: 44,
        backgroundColor: "#ffaf31 !important",
        color: theme.palette.common.black,
    },
    orderDetail: {
        height: 'calc(40vh - 110px)',
        width: "100%",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
    },
    boxDeleteCartItem: {
        display: "flex",
        height: 45,
        justifyContent: "space-between",
        alignItems: "center",
    },
    deleteButton: {
        color: "#da0006"
    },
    containerPopupDelete: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        bottom: -89,
        left: 0,
        zIndex: 10000
    },
    popupDelete: {
        width: 296,
        height: 205,
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        position: "absolute",
        top: "35%",
        left: "14%",
        zIndex: 10001,
        padding: "32px 16px 16px 16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    },
    popupDeleteButton: {
        width: 264,
        height: 44,
        backgroundColor: "#ffaf31 !important",
        borderRadius: 4,
        // marginTop: 20,
    },
    popupCancelButton: {
        width: 264,
        height: 44,
        backgroundColor: "white !important",
        borderRadius: 4,
        border: "solid 0.5px #2d2d2d",
    },
    badge: {
        backgroundColor: "#ffaf31"
    },
    popupTipStaff: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        left: 0,
        bottom: -88.5,
        zIndex: 11111,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    containerTipStaff: {
        width: 328,
        height: 540,
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        padding: "16px 16px 48px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    },
    boxImageClose: {
        width: "100%",
        height: 24,
        display: "flex",
        justifyContent: "flex-end",
    },
    imageClose: {
        width: 24,
        height: 24,
    },
    commonReason: {
        width: 296,
        height: 80,
        borderRadius: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    reason1: {
        backgroundColor: "#fffae8",
    },
    reason2: {
        backgroundColor: "#fef2f0",
    },
    reason3: {
        backgroundColor: "#f5f9ef",
    },
    buttonGotoScreenTip: {
        width: 296,
        height: 44,
        borderRadius: 4,
        backgroundColor: "#ffaf31 !important",
        color: theme.palette.common.black,
    },
    titleTip: {
        width: "100%",
        padding: "0 22px",
        textAlign: "center",
        whiteSpace: "pre-line",
    },
    titleCommonReason: {
        width: 196,
        textAlign: "center",
        paddingLeft: 16,
        whiteSpace: "pre-line",
    },
    areYouSure: {
        textAlign: "center",
        whiteSpace: "pre-line",
    }
}));
