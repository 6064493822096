import React, {FC, useState} from "react";
import {Box, Typography} from "@mui/material";
import {makeStyles, Theme} from "@material-ui/core";
import {ImageConstant} from "../../../const";
import {useDispatch, useSelector} from "react-redux";
import CartAction from "../../../redux/cart.redux"
import {getPriceFormatYen} from "../../../utils/lang.utils";
import OrderDetail from "../../../models/order.detail";
import clsx from "clsx";
import LangConst from "../../../const/lang.const";
import {useTranslation} from "react-i18next";
import {IStateRedux} from "../../../redux/state";

interface Item {
    value: OrderDetail,
}

const ItemInCart: FC<Item> = ({value}) => {
    const [isChosen, setIsChosen] = useState(false);
    const {t: getLabel} = useTranslation(LangConst.NS_CART);
    const orderCodeError = useSelector((state: IStateRedux) => state.orderRedux.error);
    const classes = useStyles({isChosen: isChosen});
    const dispatch = useDispatch()

    const handleDeleteItems = (orderDetailCode: string) => {
        if (orderDetailCode != null) {
            dispatch(CartAction.requestDeleteItemInCart(orderDetailCode))
        }
    }

    const onChangeOrderNumberByItemCode = (orderNumber: number, orderDetailCode: string) => {
        dispatch(CartAction.requestChangeItemInCart({
            orderNumber: orderNumber,
            orderDetailCode: orderDetailCode
        }))
    }

    const addIcon = ImageConstant.AddActiveIcon;
    const minusIcon = ImageConstant.MinusActiveIcon;
    return (
        <Box className={classes.root}>
            <Typography
                className={clsx(classes.itemName, `hgp-w6-font-family light-sm-txt`)}>{value.itemName}</Typography>
            {value.itemDetailContent.filter(value => value.itemCode != null && value.itemCode != "").map((itemDetailContent, detailIndex) =>
                <Box key={detailIndex} className={classes.boxItemDetailContent}>
                    <Typography className={`light-xs-txt`}>{itemDetailContent.name}</Typography>
                    <Typography className={`light-xs-txt`}>×{itemDetailContent.orderNumber}</Typography>
                </Box>
            )}
            <Box className={classes.boxChangeItemDetailContent}>
                <Box className={classes.boxPrice}>
                    <Typography className={clsx(classes.yen, `hvt-font-family light-xxs-txt`)}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}</Typography>
                    <Typography
                        className={`hvt-font-family dark-lg-txt`}>{getPriceFormatYen(value.priceTaxIn)}</Typography>
                </Box>
                <Box className={classes.boxChangeOrderNumber}>
                    <img src={minusIcon} className={classes.image} onClick={() => {
                        onChangeOrderNumberByItemCode(value.orderNumber - 1, value.orderDetailCode);
                    }}/>
                    <Typography
                        className={`hvt-font-family dark-lg-txt`}>{value.orderNumber}</Typography>
                    <img src={addIcon} className={classes.image} onClick={() => {
                        onChangeOrderNumberByItemCode(value.orderNumber + 1, value.orderDetailCode);
                    }}/>
                    <img src={ImageConstant.DeleteIcon} onClick={() => handleDeleteItems(value.orderDetailCode)}/>

                </Box>
            </Box>
            <Typography className={clsx(classes.error, `light-xxs-txt`, orderCodeError && orderCodeError.includes(value.itemCode) && classes.errorBlock)}>{getLabel(LangConst.TXT_ERROR_STOCK_TEXT, {ns: LangConst.NS_CART})}</Typography>
        </Box>
    );
};

export default ItemInCart;

interface Chosen {
    isChosen: boolean
}

const useStyles = makeStyles<Theme, Chosen>(() => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: "12px 0 16px 0",
    },
    itemName: {
        height: 21,
        marginBottom: 8,
    },
    boxItemDetailContent: {
        marginLeft: 12,
        height: 18,
        marginBottom: 8,
        display: "flex",
        justifyContent: "space-between",
    },
    boxChangeItemDetailContent: {
        height: 30,
        display: "flex",
        marginTop: 4,
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxPrice: {
        display: "flex",
        alignItems: "baseline",
    },
    boxChangeOrderNumber: {
        display: "flex",
        width: 132,
        justifyContent: "space-between",
    },
    image: {
        width: 24,
        height: 24,
    },
    yen: {
        marginRight: 4,
        fontWeight: "bold",
    },
    error: {
        color: "#ff4348",
        display: "none",
    },
    errorBlock: {
        display: "block"
    }
}));
