import {AnyAction} from "redux";
import {isDisplay, isDisplayInDayOfWeek, toCamel, toSnake} from "../utils";
import {all, call, put} from "redux-saga/effects";
import {ResponseGenerator} from "../models";
import HodaiAction from "../redux/hodai.redux";
import {HodaiService, HolidayService} from "../services";
import Holiday from "../models/holiday";
import Hodai from "../models/hodai.read";
import {convertHmToM, createDateServer, getCurrentMinutesInServer, getFormatDateYYYYMMDD} from "../utils/date.utils";
import {ApiConstant, KeyConstant, LangConstant} from "../const";

export interface HodaiResponseGenerator {
    items?: Hodai[],
    shopCode?: string,
}

export function* requestHodai(action: AnyAction): any {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const [response, responseHoliday] = yield all(
            [call(HodaiService.readHodai, snakeData),
                call(HolidayService.readHoliday, snakeData)]);
        const responseHodai: Hodai = Object.assign(new Hodai(), toCamel(response.data[0])).setMoPeriod().setMoDayOfWeek();
        const holiday: Holiday = Object.assign(new Holiday(), toCamel(responseHoliday.data)).setDatesArray();

        let currentMinutes = getCurrentMinutesInServer(); // currentMinutes to check time period
        const currentDate = createDateServer();
        const currentDateYYYYMMDD = getFormatDateYYYYMMDD(currentDate);
        const dayOfWeekOfCurrentDate = currentDate.getDay(); //: 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.
        //This setting in webAdmin save data with format  moDayOfWeekFull = [0, 1, 2, 3, 4, 5, 6, 7]; // Mon,Tue,Wed,Thu, Fri, Sat, Sun, Holiday
        let dayOfWeekMappingWithMoDayOfWeek = dayOfWeekOfCurrentDate == 0 ? 6 : dayOfWeekOfCurrentDate - 1;
        const isPreview = localStorage.getItem(KeyConstant.KEY_IS_PREVIEW)
        const dayOfWeekInPreviewLink = localStorage.getItem(KeyConstant.KEY_DAY_OF_WEEK)
        const timeInPreviewLink = localStorage.getItem(KeyConstant.KEY_TIME_PERIOD)
        if (isPreview != null && isPreview == '1' && dayOfWeekInPreviewLink != null && timeInPreviewLink != null) {
            dayOfWeekMappingWithMoDayOfWeek = Number(dayOfWeekInPreviewLink)
            currentMinutes = convertHmToM(timeInPreviewLink)
        }
        if (response.status === ApiConstant.STT_OK && responseHodai) {
            if (responseHodai != undefined) {
                yield put(HodaiAction.hodaiReadSuccess({
                    ...responseHodai,
                    isHodai: isDisplay(responseHodai, currentMinutes) && isDisplayInDayOfWeek(responseHodai, currentDateYYYYMMDD, dayOfWeekMappingWithMoDayOfWeek, holiday.datesArray)
                }));
            }
        } else {
            yield put(HodaiAction.hodaiReadFailure(responseHodai));
        }
    } catch (e) {
        yield put(HodaiAction.hodaiReadFailure(e));
    }
}

export function* requestPlanRead(action: AnyAction): any {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(HodaiService.readPlan, snakeData);
        const responseCamel: HodaiResponseGenerator = toCamel(response.data);

        if (response.status === ApiConstant.STT_OK && responseCamel) {
            if (responseCamel.items != undefined) {
                const items: Hodai[] = responseCamel.items.map(value => Object.assign(new Hodai(), value).setMoPeriod().setMoDayOfWeek().setOrderNumber())
                const codeLanguage = localStorage.getItem(KeyConstant.KEY_LANG) ? localStorage.getItem(KeyConstant.KEY_LANG) : LangConstant.DEFAULT_LANG;
                if (codeLanguage !== null && codeLanguage !== LangConstant.DEFAULT_LANG) {
                    items.map((value: any) => {
                        if (value.multiName !== null && value.multiName[codeLanguage] && value.multiName[codeLanguage].trim() !== "") {
                            value.name = value.multiName[codeLanguage];
                            value.selectItemList.map((selectItem: any) => {
                                if (selectItem.multiName != null && selectItem.multiName[codeLanguage] && selectItem.multiName[codeLanguage].trim() !== "") {
                                    selectItem.itemName = selectItem.multiName[codeLanguage];
                                }
                                if (selectItem.multiMoDescription !== null && selectItem.multiMoDescription[codeLanguage] && selectItem.multiMoDescription[codeLanguage].trim() !== "") {
                                    selectItem.description = selectItem.multiMoDescription[codeLanguage];
                                }
                            });
                        }
                        if (value.multiMoDescription !== null && value.multiMoDescription[codeLanguage] && value.multiMoDescription[codeLanguage].trim() !== "") {
                            value.description = value.multiMoDescription[codeLanguage];
                        }
                    });
                }
                yield put(HodaiAction.planReadSuccess(items));
            }
        } else {
            yield put(HodaiAction.planReadFailure(response.data.items));
        }

    } catch (e) {
        yield put(HodaiAction.planReadFailure(e));
    }
}

export function* requestPlanItemRead(action: AnyAction): any {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(HodaiService.readPlanItem, snakeData);
        const responseCamel: HodaiResponseGenerator = toCamel(response.data);
        if (response.status === ApiConstant.STT_OK && responseCamel) {
            if (responseCamel.items != undefined) {
                const items: Hodai[] = responseCamel.items.map(value => Object.assign(new Hodai(), value));
                const codeLanguage = localStorage.getItem(KeyConstant.KEY_LANG) ? localStorage.getItem(KeyConstant.KEY_LANG) : LangConstant.DEFAULT_LANG;
                if (codeLanguage !== null && codeLanguage !== LangConstant.DEFAULT_LANG) {
                    items.map((value: any) => {
                        if (value.multiName !== null && value.multiName[codeLanguage] && value.multiName[codeLanguage].trim() !== "") {
                            value.name = value.multiName[codeLanguage];
                        }
                        if (value.multiMoDescription !== null && value.multiMoDescription[codeLanguage] && value.multiMoDescription[codeLanguage].trim() !== "") {
                            value.moDescription = value.multiMoDescription[codeLanguage];
                        }
                    });
                }
                yield put(HodaiAction.planItemReadSuccess(items));
            }
        } else {
            yield put(HodaiAction.planItemReadFailure(response.data.items));
        }

    } catch (e) {
        yield put(HodaiAction.planItemReadFailure(e));
    }
}
