import React, {useEffect, useState} from "react";
import {KeyConstant, PathConstant, SystemConstant} from "../const";
import liff from "@line/liff";
import {useDispatch, useSelector} from "react-redux";
import {IStateRedux} from "../redux/state";
import {useNavigate, useParams} from "react-router-dom";
import AuthAction from "../redux/auth.redux";
import TableActivityAction from "../redux/table.activity.redux";
import ShopAction from "../redux/shop.redux";
import ErrorModal from "../components/ErrorModal";
import ApiConst from "../const/api.const";
import LoadingProgress from "../components/LoadingProgress";
import {Box} from "@mui/material";
import TableMode from "../models/table.mode";
import {setSearchQuery} from "../utils";
import Cookies from "js-cookie";

const Login = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const [isInvalidTableActivity, setIsInvalidTableActivity] = useState(false)
    const isLoggedIn = useSelector((state: IStateRedux) => state.authRedux.isLoggedIn)
    const isFetching = useSelector((state: IStateRedux) => state.authRedux.isFetching)
    const loginError = useSelector((state: IStateRedux) => state.authRedux.error)
    const isTableActivityFetching = useSelector((state: IStateRedux) => state.tableActivityRedux.isFetching)
    const isReadTableSuccess = useSelector((state: IStateRedux) => state.tableActivityRedux.isSuccess)
    const tableActivities = useSelector((state: IStateRedux) => state.tableActivityRedux.data)
    const tableActivityError = useSelector((state: IStateRedux) => state.tableActivityRedux.error)
    const shopInfo = useSelector((state: IStateRedux) => state.shopRedux.shopInfo)
    const shopReadError = useSelector((state: IStateRedux) => state.shopRedux.error)
    const liffId = new URLSearchParams(window.location.search).get("moa")

    // Get params to use for preview function
    const isPreview = new URLSearchParams(window.location.search).get("is_preview")
    const dayOfWeekPreview = new URLSearchParams(window.location.search).get("day_of_week")
    const timePreview = new URLSearchParams(window.location.search).get("time_period")
    localStorage.setItem(KeyConstant.KEY_IS_PREVIEW, (isPreview) ? isPreview : "0")
    localStorage.setItem(KeyConstant.KEY_DAY_OF_WEEK, (dayOfWeekPreview) ? dayOfWeekPreview : "0")
    localStorage.setItem(KeyConstant.KEY_TIME_PERIOD, (timePreview) ? timePreview : "0")

    const logInToMos = () => {
        const authenticationRequestDto = {
            accessToken: (liff.getAccessToken()) ? liff.getAccessToken() : "",
            clientId: process.env.REACT_APP_CLIENT_ID,
            clientSecret: process.env.REACT_APP_CLIENT_SECRET,
            shopCode: (params.shop_code !== null) ? params.shop_code : ""
        }
        dispatch(AuthAction.requestLogin(authenticationRequestDto))
    }

    const readShop = () => {
        const shopCode = (params.shop_code !== null) ? params.shop_code : ""
        dispatch(ShopAction.requestReadShop({shopCode: shopCode}))
    }

    const readTableActivity = () => {
        const mode = (params.mode != null) ? params.mode : "0"
        const code = (params.table_code != null) ? params.table_code : "0"
        switch (mode) {
            case ApiConst.TABLE_MODE: {
                localStorage.setItem(KeyConstant.KEY_TABLE_CODE, code)
                const tableActivityReadDto = {
                    shopCode: (params.shop_code != null) ? params.shop_code : "",
                    tableCode: code
                }

                dispatch(TableActivityAction.requestReadTableActivity(tableActivityReadDto))
                break;
            }
            case ApiConst.TABLE_ACTIVITY_MODE: {
                const tableActivityReadDto = {
                    shopCode: (params.shop_code != null) ? params.shop_code : "",
                    tableActivityCode: code
                }

                dispatch(TableActivityAction.requestReadTableActivity(tableActivityReadDto))
                break;
            }
        }
    }

    const setTableMode = (userId: string) => {
        const tableMode = new TableMode()
        tableMode.shopCode = (params.shop_code != null) ? params.shop_code : ""
        tableMode.userId = userId
        tableMode.code = (params.table_code != null) ? params.table_code : ""

        return tableMode
    }

    useEffect(() => {
        try {
            localStorage.setItem(KeyConstant.KEY_LIFF_ID, (liffId) ? liffId : "")
            if (!liff.isLoggedIn()) {
                liff.login()
            } else {
                logInToMos()
            }
        } catch (e) {
            console.log(e)
        }
    }, [])

    useEffect(() => {
        if (isLoggedIn) {
            liff.getProfile().then(value => {
                localStorage.setItem(KeyConstant.KEY_TABLE_MODE, JSON.stringify(setTableMode(value.userId)))
                localStorage.setItem(KeyConstant.KEY_MO_CUSTOMER, JSON.stringify({...value}))
            }).catch(reason => {
                localStorage.removeItem(KeyConstant.KEY_MO_CUSTOMER)
                console.log(reason)
            })
            const elTitleApp = document.getElementById("title_app");
            if (elTitleApp != null) {
                const liffAppName = Cookies.get(KeyConstant.KEY_LIFF_APP_NAME);
                elTitleApp.textContent = liffAppName != undefined && liffAppName ? liffAppName : "モバイルオーダー";
            }
            readShop()
            readTableActivity()
        } else {
            localStorage.removeItem(KeyConstant.KEY_MO_CUSTOMER)
        }

    }, [isLoggedIn])

    useEffect(() => {
        const isPreview = localStorage.getItem(KeyConstant.KEY_IS_PREVIEW);
        if (isReadTableSuccess) {
            if (tableActivities == null) {
                setIsInvalidTableActivity(true)
            }
            if (tableActivities && tableActivities.length > 0) {
                const state = tableActivities[0].tableActivityState
                if (state === SystemConstant.STATE.ACTIVE) {
                    const tableActivityCode = tableActivities[0].tableActivityCode
                    const tableMode = localStorage.getItem(KeyConstant.KEY_TABLE_MODE)
                    if (tableMode && params.shop_code != null && tableActivityCode != null && isPreview == null && isPreview == "0") {
                        const tableModeJson: TableMode = JSON.parse(tableMode)
                        const tableActivityUpdateDto = {
                            shopCode: params.shop_code,
                            tableActivityCode: tableActivityCode,
                            customerIdScan: tableModeJson.userId,
                        }
                        dispatch(TableActivityAction.requestUpdateTableActivity(tableActivityUpdateDto))
                    }
                    localStorage.setItem(KeyConstant.KEY_TABLE_ACTIVITY, JSON.stringify(tableActivities[0]))
                    // Cookies.set(KeyConstant.KEY_TABLE_ACTIVITY, 'true')
                    navigate({
                        pathname: PathConstant.CATEGORY,
                        search: setSearchQuery((params.shop_code) ? params.shop_code : "", tableActivities[0].tableActivityCode, (liffId) ? liffId : "")
                    })
                } else if (state === SystemConstant.STATE.INACTIVE) {
                    navigate(PathConstant.CHECK_IN)
                } else {
                    setIsInvalidTableActivity(true)
                }
            } else {
                setIsInvalidTableActivity(true)
            }
        }
    }, [isReadTableSuccess])

    useEffect(() => {
        if (shopInfo !== null) {
            localStorage.setItem(KeyConstant.KEY_SHOP_INFO, JSON.stringify({...shopInfo}))
        } else {
            localStorage.removeItem(KeyConstant.KEY_SHOP_INFO)
        }
    }, [shopInfo])

    useEffect(() => {
        if (tableActivityError != null || loginError != null || shopReadError != null) {
            setIsInvalidTableActivity(true)
        }
    }, [loginError, tableActivityError, shopReadError])

    const result = (isFetching || isTableActivityFetching)
        ? <LoadingProgress/>
        : (isInvalidTableActivity)
            ? <ErrorModal/>
            : null

    return (
        <Box height={"100%"}>
            {result}
        </Box>
    );
};

export default Login;
