import TableActivity, {OrderDetailDto, OrderDto} from "./table.activity";
import SystemConstant from "../const/system.const";
import OrderDetail from "./order.detail";

export const LINE_USER_ID_UNKNOWN = "-1"

export interface ItemOrderHistoryDto {
    itemCode?: string,
    itemName?: string,
    orderNumber?: number,
    priceTaxIn?: number
}

export class ItemOrderHistory {
    itemCode?: string
    itemName?: string
    orderNumber?: number
    priceTaxIn?: number
    itemDetailContentOrderList?: ItemOrderHistoryDto[]
    lineUserId: string // line user id order from mo
    picture: string | null // picture of line user
    created: number
    itemDetailContentInDetailsObject: number[]
    itemDetailContentNamesInDetailsObject: string[]
    createStatus? : number

    constructor() {
        this.itemCode = ""
        this.itemName = ""
        this.orderNumber = 1
        this.priceTaxIn = 0
        this.itemDetailContentOrderList = []
        this.lineUserId = LINE_USER_ID_UNKNOWN
        this.picture = null
        this.created = 0
        this.itemDetailContentInDetailsObject = []
        this.itemDetailContentNamesInDetailsObject = []
        this.createStatus = 0
    }
}

export class MoOrderHistory {
    lineUserId: string
    picture: string | null
    history: ItemOrderHistory[]
    created: number

    constructor() {
        this.lineUserId = LINE_USER_ID_UNKNOWN
        this.picture = null
        this.history = []
        this.created = 0
    }
}

export default class OrderHistory {
    subtotal: number;
    total: number;
    moOrderHistory: MoOrderHistory[];
    bHandyOrderHistory: ItemOrderHistory[];
    bRegOrderHistory: ItemOrderHistory[];

    constructor() {
        this.subtotal = 0
        this.total = 0
        this.moOrderHistory = []
        this.bHandyOrderHistory = []
        this.bRegOrderHistory = []
    }
}

export function orderDetailsToItemOrderHistory(orderDetail: OrderDetail) {
    const item = new ItemOrderHistory()
    item.itemCode = orderDetail.itemCode
    item.itemName = orderDetail.itemName
    item.orderNumber = orderDetail.orderNumber
    item.itemDetailContentOrderList = orderDetail.itemDetailContent.map(value => {
        const content: ItemOrderHistoryDto = {
            itemCode: (value.itemCode) ? value.itemCode : "",
            itemName: (value.itemName) ? value.itemName : "",
            orderNumber: value.orderNumber,
            priceTaxIn: value.priceTaxIn
        }
        return content
    })
    item.priceTaxIn = orderDetail.priceTaxIn
    return item
}

export function setData(tableActivity: TableActivity) {
    const orderHistory = new OrderHistory()
    orderHistory.total = tableActivity.total
    orderHistory.subtotal = tableActivity.subtotal
    const orderDetailList = tableActivity.orderDetailList
    const orderListObject = tableActivity.orderListObject;
    const orderDetailListHaveParentOrderDetailCode = orderDetailList.filter(value => value.parentOrderDetailCode != "")

    // Make data for order from mo with terminal type = null
    const orderDetailListByMoa = getDataOrderHistory(orderDetailList, orderDetailListHaveParentOrderDetailCode, orderListObject, SystemConstant.TERMINAL_TYPE.MO)
    // Group order by line user id
    const moOrderHistoryObject: { [key: string]: MoOrderHistory; } = {}
    orderDetailListByMoa.map(value => {
        if (value.lineUserId != null && moOrderHistoryObject[value.lineUserId] == null) {
            const moHistoryOrder = new MoOrderHistory()
            moHistoryOrder.lineUserId = value.lineUserId
            moHistoryOrder.picture = value.picture
            const history: ItemOrderHistory[] = []
            history.push(value)
            moHistoryOrder.history = history
            moHistoryOrder.created = 0
            moOrderHistoryObject [value.lineUserId] = moHistoryOrder
        } else if (value.lineUserId != null && moOrderHistoryObject[value.lineUserId] != null) {
            const moHistoryOrder = moOrderHistoryObject[value.lineUserId];
            const history: ItemOrderHistory[] = moHistoryOrder.history
            history.push(value)
            moHistoryOrder.history = history
            moOrderHistoryObject [value.lineUserId] = moHistoryOrder
        }
    })
    const moOrderHistoryArray: MoOrderHistory[] = []
    for (const [key, value] of Object.entries(moOrderHistoryObject)) {
        moOrderHistoryArray.push(value)
    }
    moOrderHistoryArray.sort((a, b) => (a.created > b.created) ? -1 : 1)
    orderHistory.moOrderHistory = moOrderHistoryArray

    // Make data for order from bReg with terminal type = 1
    orderHistory.bRegOrderHistory = getDataOrderHistory(orderDetailList, orderDetailListHaveParentOrderDetailCode, orderListObject, SystemConstant.TERMINAL_TYPE.B_REG)

    // Make data for order from BHandy with terminal type = 2
    orderHistory.bHandyOrderHistory = getDataOrderHistory(orderDetailList, orderDetailListHaveParentOrderDetailCode, orderListObject, SystemConstant.TERMINAL_TYPE.B_HANDY)

    return orderHistory
}

function getDataOrderHistory(orderDetailList: OrderDetailDto[], orderDetailListHaveParentOrderDetailCode: OrderDetailDto[], orderListObject: { [key: string]: OrderDto; }, terminalType: number) {
    // Find item order with terminal type
    let deviceOrderDetailList = orderDetailList.filter(value => value.parentOrderDetailCode == "" && (value.terminalType == terminalType))
    // Update data item order in device when staff decrease or increase orderNumber
    orderDetailListHaveParentOrderDetailCode.forEach(value => {
        deviceOrderDetailList = checkParentOrderDetailCodeExitInArray(value, deviceOrderDetailList, orderListObject)
    })
    const resultDeviceOrderDetailList: { [key: string]: OrderDetailDto; } = {}
    // Find item (not item detail content) in order
    deviceOrderDetailList.forEach(value => {
        if (value.parentUid == null) {
            resultDeviceOrderDetailList[value.orderDetailCode] = value
        }
    })
    // Find item have item detail content and update data item detail content of this item in order
    deviceOrderDetailList.forEach(value => {
        if (value.parentUid != null && resultDeviceOrderDetailList[value.parentUid]) {
            const itemDetailContentInOrderDetail = resultDeviceOrderDetailList[value.parentUid].itemDetailContentInOrderDetail;
            itemDetailContentInOrderDetail.push(
                value
            )
            resultDeviceOrderDetailList[value.parentUid].itemDetailContentInOrderDetail = itemDetailContentInOrderDetail
        }
    })
    // Format data orderHistory to show for UI
    const deviceOrderHistory: ItemOrderHistory[] = []
    for (const [key, value] of Object.entries(resultDeviceOrderDetailList)) {
        if (value.orderNumber > 0) {
            // Check item detail content don't have item id in orderDetail.details.itemDetailContent
            if (value.itemDetailContentInDetailsObject.length > value.itemDetailContentInOrderDetail.length) {
                const itemDetailContentInOrderDetail = value.itemDetailContentInOrderDetail
                value.itemDetailContentNamesInDetailsObject.forEach(name => {
                    if (!isItemDetailContentDontItemIdExitOrderDetail(name, itemDetailContentInOrderDetail)) {
                        const newOrderDetail = new OrderDetailDto()
                        newOrderDetail.itemName = name
                        newOrderDetail.orderNumber = value.orderNumber
                        itemDetailContentInOrderDetail.push(newOrderDetail)
                    }
                })
                value.itemDetailContentInOrderDetail = itemDetailContentInOrderDetail
            }
            deviceOrderHistory.push(value.convertToItemOrderHistory())
        }
    }
    deviceOrderHistory.sort((a, b) => (a.created > b.created) ? -1 : 1)
    return deviceOrderHistory
}

function isItemDetailContentDontItemIdExitOrderDetail(itemDetailName: string, itemDetailContentInOrderDetail: OrderDetailDto[]) {
    const result = itemDetailContentInOrderDetail.filter(value => value.itemName == itemDetailName)
    return result.length > 0
}

function checkParentOrderDetailCodeExitInArray(orderDetail: OrderDetailDto, orderDetailList: OrderDetailDto[], orderListObject: { [key: string]: OrderDto; }) {
    orderDetailList.map(value => {
            if (value.orderDetailCode == orderDetail.parentOrderDetailCode) {
                if (orderListObject[orderDetail.orderCode] && (orderListObject[orderDetail.orderCode].state == 3)) {
                    value.orderNumber = value.orderNumber - orderDetail.orderNumber
                }
                // if (orderListObject[orderDetail.orderCode] && (orderListObject[orderDetail.orderCode].state == 1)) {
                //     value.orderNumber = value.orderNumber + orderDetail.orderNumber
                // }
            }
            return value;
        }
    )

    return orderDetailList;
}
