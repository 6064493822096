import {AnyAction} from "redux";
import {toCamel, toSnake} from "../utils";
import {call, put} from "redux-saga/effects";
import {ShopService} from "../services";
import {ApiConstant} from "../const";
import ShopAction from "../redux/shop.redux"
import Shop from "../models/shop";
import {ResponseGenerator} from "../models";

export function* requestReadShop(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(ShopService.readTableActivity, snakeData);
        if (response.status === ApiConstant.STT_OK && response.data) {
            const shopData: Shop = Object.assign(new Shop(), toCamel(response.data));
            yield put(ShopAction.readShopSuccess(shopData.setDetailJson()));
        } else {
            yield put(ShopAction.readShopFailure(response.data));
        }
    } catch (e) {
        yield put(ShopAction.readShopFailure(e));
    }
}