import React from "react";
import {Container, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {KeyConstant, LangConstant} from "../const";
import {HomePageImg} from "../theme/images";
import Cookies from "js-cookie";

const HomePage = () => {
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConstant.NS_COMMON);
    const elTitleApp = document.getElementById("title_app");
    if (elTitleApp != null) {
        const liffAppName = Cookies.get(KeyConstant.KEY_LIFF_APP_NAME);
        elTitleApp.textContent = liffAppName != undefined && liffAppName ? liffAppName : "モバイルオーダー";
    }
    return (
        <Container className={classes.root}>
            <img className={classes.img} src={HomePageImg}/>
            <Typography variant="h4"  className={classes.text}>
                {getLabel(LangConstant.TXT_HOME_PAGE)}
            </Typography>
        </Container>
    );
};

export default HomePage;

const useStyles = makeStyles(() => ({
    root: {
        height: "100%",
        padding: "34px!important",
        alignItems: "center",
        display: "flex !important",
        flexDirection: "column"
    },
    img:{
        width: 334,
        height: 343,
        objectFit: "contain",
        marginBottom: 32,
    },
    text: {
        textAlign: "left",
        whiteSpace: "pre-line",
        width: "max-content"
    },
}));
