import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import Shop from "../models/shop";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    requestReadShop: ["data"],
    readShopSuccess: ["data"],
    readShopFailure: ["error"]
});

export const ShopTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface ShopStateRedux extends IDefaultStateProps {
    shopInfo?: Shop | null,
}

export const INITIAL_STATE: ShopStateRedux = {
    shopInfo: null,
    error: null,
    isFetching: false,
}

/* ------------- Reducers ------------- */
export const requestReadShop = (state = INITIAL_STATE) => ({
    ...state,
    isFetching: true,
    error: null
});

export const readShopSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    shopInfo: action.data
});

export const readShopFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_SHOP]: requestReadShop,
    [Types.READ_SHOP_SUCCESS]: readShopSuccess,
    [Types.READ_SHOP_FAILURE]: readShopFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
