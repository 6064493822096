import {Item} from "../../../models";
import {makeStyles} from "@material-ui/core";
import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import clsx from "clsx";
import theme from "../../../theme/material";
import {getPriceFormatYen} from "../../../utils/lang.utils";
import LangConst from "../../../const/lang.const";
import {useTranslation} from "react-i18next";

interface ItemInfoProps {
    item: Item,
    image: string | null,
}

const ItemInfo = (props: ItemInfoProps) => {
    const {item, image} = props
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_CART);

    return (
        <Box>
            {item.moAttachmentId && image &&
                <img
                    loading="lazy"
                    alt={item.name}
                    src={image}
                    className={classes.image}
                />}
            <Box className={classes.infoDetail}>
                {item.moDescription && <Typography
                    className={`${classes.moDescription} light-xs-txt`}>{item.moDescription}</Typography>}
                <Box className={classes.boxPrice}>
                    <Typography className={clsx(classes.yen, `hvt-font-family light-sm-txt`)}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}</Typography>
                    <Typography className={clsx(classes.price, `hvt-font-family light-xxl-txt`)}>
                        {getPriceFormatYen(item.priceTaxIn)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

ItemInfo.defaultProps = {
    item: new Item(),
    image: null
}

const imgHeight = 203;
const useStyles = makeStyles(() => ({
    image: {
        objectFit: "cover",
        height: imgHeight,
        width: "100%"
    },
    infoDetail: {
        padding: 16,
        marginTop: -5,
        backgroundColor: theme.palette.background.default,
    },
    boxPrice: {
        display: "flex",
        marginTop: 8,
        alignItems: "baseline",
    },
    yen: {
        marginRight: 4,
        lineHeight: 1.5,
        fontWeight: "bold",
    },
    price: {
        lineHeight: 1.5,
        fontWeight: "bold",
    },
    moDescription: {
        width: "100%",
        height: 36,
        // wordBreak: "break-all",
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.common.black,
    }
}));

export default ItemInfo;
