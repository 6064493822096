import {SystemConstant} from "../const";

export default class Staff {
    // Set default
    staffCode: string;
    name: string;
    value: number;
    sortOrder: number;
    shopCode: string;
    state: number;
    details: string | null;
    created: number;
    modified: number;
    attachmentId: number | null;
    displayName: string | null;
    introduction: string | null;

    constructor(obj?: any) {
        this.staffCode = "";
        this.name = "";
        this.value = 0;
        this.sortOrder = 0;
        this.shopCode = "";
        this.state = SystemConstant.STATE.ACTIVE;
        this.details = null;
        this.created = 0;
        this.modified = 0;
        this.attachmentId = null;
        this.displayName = null;
        this.introduction = null;
    }
}
