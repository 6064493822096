import {AuthLoginResponseGenerator} from "../sagas/auth.saga";
import {SystemConstant} from "../const";

export default class DataLogin implements AuthLoginResponseGenerator{
    scope?: string
    timeZone?: number
    userId?: string
    accessToken?: string
    liffAppName?: string

    constructor() {
        this.scope = "";
        this.timeZone = SystemConstant.TIME_ZONE_SERVER_DEFAULT;
        this.userId = "";
        this.accessToken = "";
        this.liffAppName = "";
    }
}