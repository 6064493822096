export default {
    TXT_APP_NAME: "MOA",
    TXT_MENU: "メニュー",
    TXT_CALL: "呼出",
    TXT_HISTORY: "注文履歴",
    TXT_PAYMENT: "お会計",
    TXT_UNIT: "さん",
    TXT_YEN: "円",
    TXT_ITEM: "品",
    TXT_SOLD_OUT : "SOLDOUT",
    TXT_CURRENTLY_UNAVAILABLE: "現在利用できません。",
    TXT_UNKNOWN_ERROR: "エラーが発生しました。",
    TXT_HOME_PAGE: "カメラを起動して、QRコードを\nスキャンしてください。"
};
