import React, {FC} from "react";
import {makeStyles} from "@material-ui/core";
import {Box} from "@mui/material";

interface Props {
    title: string | undefined
}

const PageTitle: FC<Props> = ({title}) => {
    const classes = useStyles();

    return (
        <Box className={`${classes.root} hp-w6-font-family light-sm-txt`}>
            {title}
        </Box>
    )
}

PageTitle.defaultProps = {
    title: ""
}

export const HEADER_BAR_MOBILE = 44;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: HEADER_BAR_MOBILE,
        width: '100%',
        padding: 5,
        backgroundColor: theme.palette.background.paper,
    }
}));

export default PageTitle
