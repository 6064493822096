import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useNavigate, useParams} from "react-router-dom";
import {KeyConstant, PathConstant, SystemConstant} from "../../const";
import {useDispatch, useSelector} from "react-redux";
import CategoryAction from "../../redux/category.redux"
import ItemAction from "../../redux/item.redux";
import TableActivityAction from "../../redux/table.activity.redux"
import {IStateRedux} from "../../redux/state";
import {getPriceFormatYen, truncateString} from "../../utils/lang.utils";
import BoxItem from "../../components/BoxItem";
import MainLayout from "../../components/MainLayout";
import {getLiffId, getShopInfo, getTableActivityCode, setSearchQuery} from "../../utils";
import {palette} from "../../theme/material";
import LoadingProgress from "../../components/LoadingProgress";
import {useTranslation} from "react-i18next";
import LangConst from "../../const/lang.const";
import {Card, Grid, Button} from "@mui/material";
import {getShopCode} from "../../utils/shop.util";
import {ScrollMenu} from "react-horizontal-scrolling-menu";
import clsx from "clsx";
import {Item} from "../../models";
import HodaiAction from "../../redux/hodai.redux";

const Category = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {categoryCode} = useParams();
    const dispatch = useDispatch();
    const shopInfo = getShopInfo();
    const isUseStock = shopInfo.detailsJson.stockF;
    const {t: getLabel} = useTranslation(LangConst.NS_COMMON);
    const title = getLabel(LangConst.TXT_MENU);
    const customClasses = {
        body: `${classes.listCategoryWrap}`
    }

    const [dataThumbnail, setDataThumbnail] = useState<any[]>([]);
    const [dataItem, setDataItem] = useState<any[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isUseHodai, setIsUseHodai] = useState(false);
    const [isActiveButtonHodai, setIsActiveButtonHodai] = useState(false);
    const isSuccess = useSelector((state: IStateRedux) => state.tableActivityRedux.isSuccess);
    const code = useSelector((state: IStateRedux) => state.tableActivityRedux.tableActivityCode);
    const tableActivities = useSelector((state: IStateRedux) => state.tableActivityRedux.data);
    const categoryReduxData = useSelector((state: IStateRedux) => state.categoryRedux);
    const dataHodaiPlanRead = useSelector((state: IStateRedux) => state.hodaiRedux.dataHodaiItem);
    const dataHodaiItemRead = useSelector((state: IStateRedux) => state.hodaiRedux.dataHodaiItemList);
    const itemReduxData = useSelector((state: IStateRedux) => state.itemRedux);

    const goToDetails = (itemCode: string, item: Item) => {
        navigate({
                pathname: `/item/details/${itemCode}`,
                search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
            },
            {
                state: {item: item}
            })
    }

    const goToCategoryOrSub = (categoryCode: string) => {
        navigate({
            pathname: PathConstant.CATEGORY + `/${categoryCode}`,
            search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
        }, {
            state: {name: name},
        })
    }

    useEffect(() => {
        if (localStorage.getItem(KeyConstant.KEY_IS_HODAI) && localStorage.getItem(KeyConstant.KEY_IS_HODAI) === SystemConstant.STATE.ACTIVE.toString()) {
            setIsUseHodai(true);
        } else {
            setIsUseHodai(false);
        }
        setIsActiveButtonHodai(false);
        const isPreview = localStorage.getItem(KeyConstant.KEY_IS_PREVIEW)
        if (isPreview != null && isPreview == '1') {
            return
        }
        if (tableActivities?.length === 0 && !localStorage.getItem(KeyConstant.KEY_TABLE_ACTIVITY)) {
            const tableActivityReadDto = {
                shopCode: shopInfo.shopCode,
                tableActivityCode: code
            }

            dispatch(TableActivityAction.requestReadTableActivity(tableActivityReadDto))
        }
        if (localStorage.getItem(KeyConstant.KEY_IS_HODAI) && localStorage.getItem(KeyConstant.KEY_IS_HODAI) === SystemConstant.STATE.ACTIVE.toString()) {
            setIsUseHodai(true);
        }
    }, []);

    useEffect(() => {
        if (isActiveButtonHodai) {
            dispatch(HodaiAction.requestPlanRead({
                shopCode: getShopCode(),
                hodaiCode: localStorage.getItem(KeyConstant.KEY_HODAI_CODE),
            }));
        } else {
            if (shopInfo != null && shopInfo.shopCode != null) {
                const searchData = {shopCode: shopInfo.shopCode};
                dispatch(CategoryAction.requestReadCategory(searchData))
            }
        }
    }, [isActiveButtonHodai]);

    useEffect(() => {
        if (categoryReduxData.data.length > 0) {
            setIsFetching(categoryReduxData.isFetching);
            setDataThumbnail(categoryReduxData.data);
            navigate({
                pathname: PathConstant.CATEGORY + `/` + categoryReduxData.data[0].categoryCode,
                search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
            }, {
                state: {name: name},
            });
        }
    }, [categoryReduxData]);

    useEffect(() => {
        if (dataHodaiPlanRead.length > 0) {
            setIsFetching(false);
            setDataThumbnail(dataHodaiPlanRead);
            navigate({
                pathname: PathConstant.CATEGORY + `/` + dataHodaiPlanRead[0].itemCode,
                search: setSearchQuery(getShopCode(), getTableActivityCode(), getLiffId())
            }, {
                state: {name: name},
            });
        }
    }, [dataHodaiPlanRead]);

    useEffect(() => {
        if (categoryCode && !isActiveButtonHodai) {
            const searchData = {
                shopCode: shopInfo.shopCode,
                categoryCode: categoryCode,
            };
            dispatch(ItemAction.requestReadItem(searchData))
        } else {
            const searchData = {
                shopCode: shopInfo.shopCode,
                itemCode: categoryCode,
            };
            dispatch(HodaiAction.requestPlanItemRead(searchData))
        }
        if (categoryCode == undefined) {
            dispatch(CategoryAction.requestReadCategory({shopCode: shopInfo.shopCode}))
        }
    }, [categoryCode]);

    useEffect(() => {
        if (isSuccess) {
            if (tableActivities && tableActivities?.length > 0) {
                localStorage.setItem(KeyConstant.KEY_TABLE_ACTIVITY, JSON.stringify(tableActivities[0]))
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (itemReduxData.data.length > 0) {
            setDataItem(itemReduxData.data);
        }
    }, [itemReduxData]);

    useEffect(() => {
        setDataItem(dataHodaiItemRead);
    }, [dataHodaiItemRead]);

    return (
        <MainLayout title={truncateString(title, SystemConstant.LIMIT_LENGTH_NAME)} customClasses={customClasses}>
            {isFetching && <LoadingProgress/>}
            {isUseHodai && <ScrollMenu
                wrapperClassName={clsx(classes.wrapperClassName, classes.wrapperClassNameHodai)}
                scrollContainerClassName={classes.scrollContainerHodai}>
              <Button
                  className={clsx(classes.usually, !isActiveButtonHodai && classes.buttonActive, `hgp-w6-font-family light-xs-txt`)}
                  onClick={() => {
                      setIsActiveButtonHodai(!isActiveButtonHodai)
                      setDataThumbnail([]);
                      setDataItem([]);
                  }}>
                  {getLabel(LangConst.TXT_HODAI_USUALLY, {ns: LangConst.NS_HODAI})}
              </Button>
              <Button
                  className={clsx(classes.unlimited, isActiveButtonHodai && classes.buttonActive, `hgp-w6-font-family light-xs-txt`)}
                  onClick={() => {
                      setIsActiveButtonHodai(!isActiveButtonHodai)
                      setDataThumbnail([]);
                      setDataItem([]);
                  }}>
                  {/* TODO Call api get time plan*/}
                  {getLabel(LangConst.TXT_HODAI_UNLIMITED, {ns: LangConst.NS_HODAI})}
              </Button>
            </ScrollMenu>}
            {isActiveButtonHodai ? <ScrollMenu
                wrapperClassName={clsx(classes.wrapperClassName, classes.isUseHodai)}
                scrollContainerClassName={classes.scrollContainerClassName}>
                {dataThumbnail.map((value) =>
                    <Card key={value.itemCode}
                          className={clsx(`hgp-w6-font-family-normal light-xs-txt`, classes.cardTitle, value.itemCode === categoryCode && classes.activeCardTitle)}
                          onClick={() => !isFetching && goToCategoryOrSub(value.itemCode)}>
                        {value.name}
                    </Card>)}
            </ScrollMenu> : <ScrollMenu
                wrapperClassName={clsx(classes.wrapperClassName, isUseHodai && classes.isUseHodai)}
                scrollContainerClassName={classes.scrollContainerClassName}>
                {dataThumbnail.filter((item) => item.childNumber === 0 && item.itemNumber > 0).map((value) =>
                    <Card key={value.categoryCode}
                          className={clsx(`hgp-w6-font-family-normal light-xs-txt`, classes.cardTitle, value.categoryCode === categoryCode && classes.activeCardTitle)}
                          onClick={() => !isFetching && goToCategoryOrSub(value.categoryCode)}>
                        {value.name}
                    </Card>)}
            </ScrollMenu>}
            <Grid container className={classes.listCategory} spacing={2}>
                {dataItem && dataItem.map((value) =>
                    <BoxItem
                        key={value.itemCode}
                        name={value.name}
                        attachment={value.moAttachmentId}
                        formatPrice={getPriceFormatYen(value.price)}
                        goToDetails={() => {
                            goToDetails(value.itemCode, value)
                        }}
                        stock={value.stock}
                        isUseStock={isUseStock == SystemConstant.STATE.ACTIVE}
                    />
                )}
            </Grid>

        </MainLayout>
    )
}

const useStyles = makeStyles(() => ({
    boxTitle: {
        width: "100%"
    },
    wrapperClassName: {
        position: "sticky",
        top: -1,
        zIndex: 10000,
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: palette.background.default,
        height: 44,
        overflowY: "hidden"
    },
    wrapperClassNameHodai: {
        borderBottom: `1px solid ${palette.divider}`,
        borderTop: `1px solid ${palette.divider}`,
    },
    scrollContainerClassName: {
        display: "flex",
    },
    scrollContainerHodai: {
        display: "flex",
        justifyContent: "space-between",
        height: 44,
        alignItems: "center",
        padding: "0px 4px",
        borderTop: "solid 0.5px #ddd",
        borderBottom: "solid 0.5px #ddd",
    },
    cardTitle: {
        width: "max-content",
        height: 44,
        padding: "13px 16px",
        backgroundColor: palette.background.default,
        boxShadow: "none",
        borderRadius: 0,
    },
    activeCardTitle: {
        borderBottom: "solid 4px #ffaf31",
        color: "#ffaf31",
    },
    listCategoryWrap: {
        backgroundColor: palette.background.paper,
        padding: "0",
        "@media only screen and (max-width: 413px)": {
            padding: "0px"
        },
        height: "calc(100vh - 133px)",
    },
    listCategory: {
        padding: "16px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
    },
    usually: {
        height: 36,
        width: "48vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        color: "#2d2d2d",
    },
    unlimited: {
        height: 36,
        width: "48vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        color: "#2d2d2d",
    },
    buttonActive: {
        backgroundColor: "#ffebcb !important",
    },
    isUseHodai: {
        top: 43,
    }
}));

export default Category;
