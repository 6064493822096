const LangConstant = {
    // List language code
    DEFAULT_LANG: "ja",
    LANG_CODE_ENGLISH: "en",
    LANG_CODE_KOREAN: "ko",
    LANG_CODE_CHINA: "zh-cn",

    // list language
    DEFAULT_LANG_JAPAN: "日本語",
    DEFAULT_LANG_ENGLISH: "English",
    DEFAULT_LANG_CHINA: "簡体中文",
    DEFAULT_LANG_KOREAN: "한국어",

    // Namespace Key
    NS_COMMON: "common",
    NS_NOT_FOUND: "notFound",
    NS_CHECK_IN: "checkIn",
    NS_ORDER_HISTORY: "orderHistory",
    NS_CATEGORY: "category",
    NS_CALL: "call",
    NS_PAYMENT: "payment",
    NS_UNIT: "さん",
    NS_CART: "cart",
    NS_TIP: "tip",
    NS_HODAI: "hodai",

    // Common start
    TXT_APP_NAME: "TXT_APP_NAME",
    TXT_MENU: "TXT_MENU",
    TXT_CALL: "TXT_CALL",
    TXT_HISTORY: "TXT_HISTORY",
    TXT_PAYMENT: "TXT_PAYMENT",
    TXT_UNIT: "TXT_UNIT",
    TXT_YEN: "TXT_YEN",
    TXT_ITEM: "TXT_ITEM",
    TXT_SOLD_OUT: "TXT_SOLD_OUT",
    TXT_CURRENTLY_UNAVAILABLE: "TXT_CURRENTLY_UNAVAILABLE",
    TXT_UNKNOWN_ERROR: "TXT_UNKNOWN_ERROR",
    TXT_HOME_PAGE: "TXT_HOME_PAGE",
    // Common end

    // NotFound start
    TXT_PAGE_NOT_FOUND: "TXT_PAGE_NOT_FOUND",
    // NotFound end

    // CheckInPage
    TXT_CHECK_IN_TITLE: "TXT_CHECK_IN_TITLE",
    TXT_GUEST_NUMBER: "TXT_GUEST_NUMBER",
    TXT_PEOPLE: "TXT_PEOPLE",
    TXT_GO_TO_MENU: "TXT_GO_TO_MENU",

    // TableActivityCall
    TXT_THE_CLERK_CALL_OUT: "TXT_THE_CLERK_CALL_OUT",
    TXT_WOULD_YOU_LIKE_TO_CALL_THE_CLERK: "TXT_WOULD_YOU_LIKE_TO_CALL_THE_CLERK",
    TXT_YES: "TXT_YES",
    TXT_CALLING_NOW: "TXT_CALLING_NOW",
    TXT_PLEASE_WAIT: "TXT_PLEASE_WAIT",

    // Category and item
    TXT_ADD_TO_CART: "TXT_ADD_TO_CART",
    TXT_REQUIRED: "TXT_REQUIRED",
    TXT_CHOOSE_EXACTLY_N: "TXT_CHOOSE_EXACTLY_N",
    TXT_CHOOSE_AT_LEAST_N: "TXT_CHOOSE_AT_LEAST_N",
    TXT_CHOOSE_BETWEEN: "TXT_CHOOSE_BETWEEN",
    TXT_CHOOSE_UP_TO: "TXT_CHOOSE_UP_TO",
    TXT_WOULD_YOU_LIKE: "TXT_WOULD_YOU_LIKE",
    TXT_ORDER_ERROR: "TXT_ORDER_ERROR",
    TXT_ERROR_STOCK_TEXT: "TXT_ERROR_STOCK_TEXT",
    TXT_ERROR_STOCK: "TXT_ERROR_STOCK",
    TXT_ORDER_CONFIRMATION: "TXT_ORDER_CONFIRMATION",
    TXT_CURRENCY_CART: "TXT_CURRENCY_CART",
    TXT_TOTAL_ORDER: "TXT_TOTAL_ORDER",
    TXT_TAX_INCLUDED: "TXT_TAX_INCLUDED",

    // Cart
    TXT_ITEMS_IN_CART: "TXT_ITEMS_IN_CART",
    TXT_DELETE_ALL: "TXT_DELETE_ALL",
    TXT_CONFIRMED_ORDER: "TXT_CONFIRMED_ORDER",
    TXT_ARE_YOU_SURE: "TXT_ARE_YOU_SURE",
    TXT_DELETE: "TXT_DELETE",
    TXT_CANCEL: "TXT_CANCEL",
    TXT_ORDER_IS_COMPLETE: "TXT_ORDER_IS_COMPLETE",
    TXT_CHECK_ORDER_HISTORY: "TXT_CHECK_ORDER_HISTORY",
    TXT_ITEM_SOLD_OUT: "TXT_ITEM_SOLD_OUT",
    TXT_CLOSE: "TXT_CLOSE",

    // History
    TXT_TITLE_HISTORY: "TXT_TITLE_HISTORY",
    TXT_MULTIPLY: "TXT_MULTIPLY",
    TXT_CURRENCY: "TXT_CURRENCY",
    TXT_ORDER_DETAIL_FAILED: "TXT_ORDER_DETAIL_FAILED",
    TXT_ORDER_AGAIN_NOTIFICATION: "TXT_ORDER_AGAIN_NOTIFICATION",

    // Tip
    TXT_TIP_THE_STAFF: "TXT_TIP_THE_STAFF",
    TXT_TIP_CONTENT: "TXT_TIP_CONTENT",
    TXT_TIP_1: "TXT_TIP_1",
    TXT_TIP_2: "TXT_TIP_2",
    TXT_TIP_3: "TXT_TIP_3",
    TXT_LET_SEND_TIP: "TXT_LET_SEND_TIP",
    TXT_GIVE_TIP: "TXT_GIVE_TIP",
    TXT_TIP_TITLE: "TXT_TIP_TITLE",

    // Hodai
    TXT_HODAI_TITLE: "TXT_HODAI_TITLE",
    TXT_HODAI_USE: "TXT_HODAI_USE",
    TXT_HODAI_DONT_USE: "TXT_HODAI_DONT_USE",
    TXT_HODAI_SURE: "TXT_HODAI_SURE",
    TXT_HODAI_RETURN: "TXT_HODAI_RETURN",
    TXT_HODAI_TITLE_LIST: "TXT_HODAI_TITLE_LIST",
    TXT_HODAI_USUALLY: "TXT_HODAI_USUALLY",
    TXT_HODAI_UNLIMITED: "TXT_HODAI_UNLIMITED",
    TXT_TARGET_MENU: "TXT_TARGET_MENU",
    TXT_PLEASE_SELECT_TIP: "TXT_PLEASE_SELECT_TIP",
    TXT_ITEM_VALUE_INCLUDED_BILL: "TXT_ITEM_VALUE_INCLUDED_BILL",
    TXT_THANK_YOU_1: "TXT_THANK_YOU_1",
    TXT_THANK_YOU_2: "TXT_THANK_YOU_2",

    // Payment
    TXT_PAYMENT_TITLE: "TXT_PAYMENT_TITLE",
    TXT_BILL_AMOUNT: "TXT_BILL_AMOUNT",
    TXT_PAYMENT_TAX_INCLUDED: "TXT_PAYMENT_TAX_INCLUDED",
    TXT_PAYMENT_SUBTOTAL: "TXT_PAYMENT_SUBTOTAL",
    TXT_PAYMENT_SALE_TAX: "TXT_PAYMENT_SALE_TAX",
    TXT_PAYMENT_CHECK_OUT: "TXT_PAYMENT_CHECK_OUT",
    TXT_PAYMENT_CANCEL_CHECK_OUT: "TXT_PAYMENT_CANCEL_CHECK_OUT",
    TXT_PAYMENT_TOTAL: "TXT_PAYMENT_TOTAL",
    TXT_PAYMENT_PLEASE_SHOW_SCREEN_STAFF: "TXT_PAYMENT_PLEASE_SHOW_SCREEN_STAFF",
    TXT_AFTER_CONFIRMING_PAYMENT: "TXT_AFTER_CONFIRMING_PAYMENT",
    TXT_PAYMENT_STOP_PAYMENT: "TXT_PAYMENT_STOP_PAYMENT",
};
export default LangConstant;
