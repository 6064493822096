import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import enLang from "./resources/en";
import jaLang from "./resources/ja";
import koLang from "./resources/ko";
import zhCnLang from "./resources/zhCn";
import {LangConstant} from "../const";

i18next.use(initReactI18next).init(
  {
    interpolation: {
      // React already does escaping
      escapeValue: false
    },
    lng: LangConstant.DEFAULT_LANG,
    resources: {
      "en": enLang,
      "ja": jaLang,
      "ko": koLang,
      "zhCn": zhCnLang,
    },
    defaultNS: LangConstant.NS_COMMON
  },
  err => {
    if (err) {
      return console.error(err);
    }
  }
).then(r => {
    console.log(r)
});

export default i18next;

export const getLabel = (key: any, option: any) => i18next.getFixedT(i18next.language)(key, option);
