import {all, call, put} from "redux-saga/effects";
import {toCamel, toSnake} from "../utils";
import {AttachmentService, ItemDetailContentService, ItemDetailService} from "../services";
import {ApiConstant, KeyConstant, LangConstant} from "../const";
import {AnyAction} from "redux";
import ItemDetailAction from "../redux/item.detail.redux";
import {ResponseGenerator} from "../models";
import ItemDetail from "../models/item.detail";
import ItemDetailContent from "../models/item.detail.content";

export interface ItemDetailResponseGenerator {
    itemDetails?: ItemDetail[],
    shopCode?: string,
}

export interface ItemDetailContentResponseGenerator {
    itemDetailContents?: ItemDetailContent[],
    shopCode?: string,
}

export function* requestReadItemDetail(action: AnyAction): any {
    try {
        const {searchData} = action;
        const snakeData = toSnake(searchData);
        const [response, itemDetailContentResponse] = yield all(
            [call(ItemDetailService.readItemDetail, snakeData),
                call(ItemDetailContentService.readItemDetailContent, snakeData)
            ])
        const responseCamel: ItemDetailResponseGenerator = toCamel(response.data);
        const itemDetailContentResponseCamel: ItemDetailContentResponseGenerator = toCamel(itemDetailContentResponse.data);
        const codeLanguage = localStorage.getItem(KeyConstant.KEY_LANG) ? localStorage.getItem(KeyConstant.KEY_LANG) : LangConstant.DEFAULT_LANG;

        if (response.status === ApiConstant.STT_OK && responseCamel && itemDetailContentResponse.status === ApiConstant.STT_OK && itemDetailContentResponseCamel) {
            if (responseCamel.itemDetails != undefined &&
                itemDetailContentResponseCamel.itemDetailContents != undefined) {
                const itemDetails: ItemDetail[] = responseCamel.itemDetails.map(value => Object.assign(new ItemDetail(), value).setCourseF());
                const dataObject: { [key: string]: ItemDetail; } = {};
                itemDetails.forEach(value => {
                    dataObject[value.itemDetailCode] = value
                })

                const itemDetailContents: ItemDetailContent[] = itemDetailContentResponseCamel.itemDetailContents;
                itemDetailContents.forEach(value => {
                    if (dataObject[value.itemDetailCode] != null) {
                        const itemDetailObject = dataObject[value.itemDetailCode];
                        let itemDetailContentInObject = itemDetailObject.itemDetailContents;
                        if (itemDetailContentInObject == undefined) {
                            itemDetailContentInObject = []
                        }
                        itemDetailContentInObject.push({...value, orderNumber: 0});
                        if (codeLanguage !== null && codeLanguage !== LangConstant.DEFAULT_LANG) {
                            itemDetailContentInObject.map((item) => {
                                if (item.multiName !== null && item.multiName[codeLanguage] && item.multiName[codeLanguage].trim() !== "") {
                                    item.name = item.multiName[codeLanguage];
                                }
                            });
                        }
                        dataObject[value.itemDetailCode]['itemDetailContents'] = itemDetailContentInObject
                    }
                })
                yield put(ItemDetailAction.readItemDetailSuccess(dataObject));
            }
        } else {
            yield put(ItemDetailAction.readItemDetailFailure(response.data));
        }
    } catch (error) {
        yield put(ItemDetailAction.readItemDetailFailure(error));
    }
}

export function* requestReadAttachment(action: AnyAction) {
    try {
        const {searchData} = action;
        const snakeData = toSnake(searchData);
        const response: ResponseGenerator = yield call(AttachmentService.readAttachment, snakeData);
        if (response.status === ApiConstant.STT_OK && response.data) {
            const encryptedBytes = new Uint8Array(response.data);
            const blob = new Blob([encryptedBytes], {type: "application/image"})
            yield put(ItemDetailAction.readAttachmentSuccess(URL.createObjectURL(blob)));
        } else {
            yield put(ItemDetailAction.readAttachmentFailure(response.data));
        }
    } catch (error) {
        yield put(ItemDetailAction.readAttachmentFailure(error));
    }
}
