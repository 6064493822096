import {makeStyles} from "@material-ui/core";
import React from "react";
import {Box, Typography} from "@mui/material";
import palette from "../../../theme/material/palette";
import {ImageConstant} from "../../../const";
import ItemDetailContent from "../../../models/item.detail.content";
import clsx from "clsx";
import {getPriceFormatYen} from "../../../utils/lang.utils";
import LangConst from "../../../const/lang.const";
import {useTranslation} from "react-i18next";

interface ItemDetailContentMultiSelectProps {
    itemDetailContent: ItemDetailContent,
    maxNumber: number | null,
    currentNumber: number
    onClickItemDetailContent: (itemDetailContent: ItemDetailContent, orderNumberChange: number) => void,
}

const ItemDetailContentMultiSelect = (props: ItemDetailContentMultiSelectProps) => {
    const {itemDetailContent, maxNumber, currentNumber, onClickItemDetailContent} = props;
    const classes = useStyles();
    const addIcon = (maxNumber && currentNumber < maxNumber || maxNumber == null) ? ImageConstant.AddActiveIcon : ImageConstant.AddIcon;
    const minusIcon = (itemDetailContent.orderNumber > 0) ? ImageConstant.MinusActiveIcon : ImageConstant.MinusIcon;
    const {t: getLabel} = useTranslation(LangConst.NS_CART);

    return (
        <>
            <Box className={classes.itemDetailContentLine}>
                <Box className={classes.boxNameAndPrice}>
                    <Typography
                        className={clsx(classes.nameItemDetailContent, itemDetailContent.orderNumber > 0 && classes.active, `hgp-w6-font-family light-sm-txt`)}>{itemDetailContent.name}</Typography>
                    <Box className={classes.boxPrice}>
                        <Typography
                            className={clsx(itemDetailContent.orderNumber > 0 && classes.active, `hvt-font-family light-sm-txt`)}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}</Typography>
                        <Typography
                            className={clsx(classes.price, itemDetailContent.orderNumber > 0 && classes.active, `hvt-font-family dark-lg-txt`)}>{(itemDetailContent.priceTaxIn > 0) ? `${getPriceFormatYen(itemDetailContent.priceTaxIn)}` : (itemDetailContent.priceTaxIn < 0 ? `-${getPriceFormatYen(Math.abs(itemDetailContent.priceTaxIn))}` : "")}</Typography>
                    </Box>
                </Box>
                <Box className={classes.boxOrderNumber}>
                    <img className={`${classes.img}`}
                         onClick={() => onClickItemDetailContent(itemDetailContent, -1)}
                         src={minusIcon}/>
                    <Typography
                        className={`${classes.orderNumber} hvt-font-family dark-lg-txt`}>{itemDetailContent.orderNumber}</Typography>
                    <img
                        className={`${classes.img}`}
                        onClick={() => onClickItemDetailContent(itemDetailContent, +1)}
                        src={addIcon}/>
                </Box>
            </Box>
        </>
    );
};

const useStyles = makeStyles(() => ({
    itemDetailContentLine: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 16,
        borderTop: `1px solid ${palette.divider}`,
        height: 73,
        justifyContent: "space-between",
        "&:last-child": {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
    },
    boxNameAndPrice: {
        display: "flex",
        flexDirection: "column"
    },
    boxOrderNumber: {
        display: "flex",
    },
    boxPrice: {
        display: "flex",
        alignItems: "baseline",
    },
    nameItemDetailContent: {
        marginRight: 4
    },
    price: {
        lineHeight: 1.93,
        marginLeft: 4,
        fontWeight: "bold",
    },
    img: {
        width: 24,
        height: 24,
    },
    orderNumber: {
        margin: "0 16px"
    },
    active: {
        color: "#ffaf31"
    },
}));

export default ItemDetailContentMultiSelect;
