import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import ItemDetail from "../models/item.detail";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestReadItemDetail: ["searchData"],
    readItemDetailSuccess: ["data"],
    readItemDetailFailure: ["error"],

    requestReadAttachment: ["searchData"],
    readAttachmentSuccess: ["image"],
    readAttachmentFailure: ["error"]
});

export const ItemDetailTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface ItemDetailStateRedux extends IDefaultStateProps {
    data: { [key: string]: ItemDetail; };
    image: string | null,
    isSuccess: boolean;
    searchData: any;
}

export const INITIAL_STATE: ItemDetailStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: {},
    searchData: {},
    image: null
};

/* ------------- Reducers ------------- */
export const requestReadItemDetail = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true,
    ...action
});

export const readItemDetailSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readItemDetailFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

export const requestReadAttachment = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true,
    error: null
});

export const readAttachmentSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readAttachmentFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_ITEM_DETAIL]: requestReadItemDetail,
    [Types.READ_ITEM_DETAIL_SUCCESS]: readItemDetailSuccess,
    [Types.READ_ITEM_DETAIL_FAILURE]: readItemDetailFailure,

    [Types.REQUEST_READ_ATTACHMENT]: requestReadAttachment,
    [Types.READ_ATTACHMENT_SUCCESS]: readAttachmentSuccess,
    [Types.READ_ATTACHMENT_FAILURE]: readAttachmentFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
