export default {
    TXT_TIP_THE_STAFF: "给员工小费",
    TXT_TIP_CONTENT: "您可以给工作人员一个小费 \n" + "以表达您的支持和感谢。",
    TXT_TIP_1: "我喜欢有礼貌的客户服务！",
    TXT_TIP_2: "建议、过敏等\n" + "非常有帮助的解释！",
    TXT_TIP_3: "即使我要求换座位\n" + "他们也微笑着回应！",
    TXT_LET_SEND_TIP: "让我们在这种时候向工作人员发送小费",
    TXT_GIVE_TIP: "给个小费",
    TXT_TIP_TITLE: "我想给个小费 \n 请选择工作人员",
    TXT_PLEASE_SELECT_TIP: "请选择小费",
    TXT_ITEM_VALUE_INCLUDED_BILL: "物品价值包含在您的账单中。",
    TXT_THANK_YOU_1: "谢谢你的小费！",
    TXT_THANK_YOU_2: "谢谢你的小费。Riho 稍后会来打招呼。我会尽力收到更多的礼物！",
}
