import {toCamel} from "../utils";
import {ItemOrderHistory, LINE_USER_ID_UNKNOWN} from "./history";
import SystemConstant from "../const/system.const";

export default class TableActivity implements TableActivityDto {
    tableActivityCode: string;
    tableCode: string;
    tableActivityCustomerNumber: number | null;
    tableActivityState: number;
    tableActivityCreated: number;
    tableActivityModified: number | null;
    tax: number;
    subtotal: number;
    subtotalTaxNon: number;
    subtotalTaxNormal: number;
    subtotalTaxReduced: number;
    total: number;
    totalTaxNon: number;
    totalTaxNormal: number;
    totalTaxReduced: number;
    discountPrice: number | null;
    discountType: number | null;
    discountValue: number | null;
    orderDetailList: OrderDetailDto[];
    orderList: OrderDto[] | null;
    paymentList: PaymentDto[] | null;
    paymentOrderDetailList: PaymentOrderDetail[] | null;
    orderListObject: { [key: string]: OrderDto; }

    constructor() {
        this.discountPrice = 0;
        this.discountType = 0;
        this.discountValue = 0;
        this.orderDetailList = [];
        this.orderList = [];
        this.paymentList = [];
        this.paymentOrderDetailList = [];
        this.subtotal = 0;
        this.subtotalTaxNon = 0;
        this.subtotalTaxNormal = 0;
        this.subtotalTaxReduced = 0;
        this.tableActivityCode = "";
        this.tableActivityCreated = 0;
        this.tableActivityCustomerNumber = 0;
        this.tableActivityModified = 0;
        this.tableActivityState = 0;
        this.tableCode = "";
        this.tax = 0;
        this.total = 0;
        this.totalTaxNon = 0;
        this.totalTaxNormal = 0;
        this.totalTaxReduced = 0;
        this.orderListObject = {}
    }

    setOrderListObject() {
        const orderListObject: { [key: string]: OrderDto; } = {};
        this.orderList?.forEach(value => {
            orderListObject[value.orderCode] = value
        })
        this.orderListObject = orderListObject
        return this
    }
}

interface TableActivityDto {
    tableActivityCode: string,
    tableCode: string,
    tableActivityCustomerNumber?: number | null,
    tableActivityCreated: number,
    tableActivityModified?: number | null,
    tax: number,
    tableActivityState: number,
    subtotal: number,
    subtotalTaxNon: number,
    subtotalTaxNormal: number,
    subtotalTaxReduced: number,
    total: number,
    totalTaxNon: number,
    totalTaxNormal: number,
    totalTaxReduced: number,
    discountValue?: number | null,
    discountPrice?: number | null,
    discountType?: number | null,
    orderDetailList: OrderDetailDto[]
    orderList?: OrderDto[] | null,
    paymentList?: PaymentDto[] | null,
    paymentOrderDetailList?: PaymentOrderDetail[] | null
}

interface CustomerDetails {
    lineUserId: string,
    picture: string
}

interface Details {
    parentUid?: string | null,
    terminalType?: number,
    customerDetails?: CustomerDetails
    itemDetailContent?: number[]
    itemDetailContentNames? : string[]
}

export class OrderDetailDto {
    orderDetailCode: string
    orderCode: string
    details: string
    discount?: number | null
    discountPrice?: number | null
    discountType?: number | null
    itemCode: string
    itemName: string
    orderNumber: number
    price: number
    priceTaxIn: number
    subTotal: number
    tax: number
    total: number
    parentOrderDetailCode: string
    parentUid: string | null
    terminalType: number
    itemDetailContentInOrderDetail: OrderDetailDto[] // use for make data item detail content in item when order
    createStatus?: number
    lineUserId: string | null // line user id order from mo
    picture: string | null // picture of line user
    created: number
    itemDetailContentInDetailsObject: number[]
    itemDetailContentNamesInDetailsObject: string[]
    constructor() {
        this.orderDetailCode = ""
        this.orderCode = ""
        this.details = ""
        this.discount = null
        this.discountPrice = null
        this.discountType = null
        this.itemCode = ""
        this.itemName = ""
        this.orderNumber = 0
        this.price = 0
        this.priceTaxIn = 0
        this.subTotal = 0
        this.tax = 0
        this.total = 0
        this.parentOrderDetailCode = ""
        this.parentUid = null
        this.createStatus = 0
        this.terminalType = SystemConstant.TERMINAL_TYPE.MO
        this.itemDetailContentInOrderDetail = []
        this.lineUserId = null
        this.picture = null
        this.created = 0
        this.itemDetailContentInDetailsObject = []
        this.itemDetailContentNamesInDetailsObject = []
    }

    formatDataOrderDetail() {
        if (this.details != null) {
            const jsonDetail = JSON.parse(this.details)
            const jsonDetailCamel: Details = toCamel(jsonDetail)
            if (jsonDetailCamel?.parentUid != null) {
                this.parentUid = jsonDetailCamel?.parentUid;
            }
            if (jsonDetailCamel?.terminalType != null) {
                this.terminalType = jsonDetailCamel?.terminalType;
            }
            if (jsonDetailCamel?.customerDetails != null) {
                if (jsonDetailCamel?.customerDetails.lineUserId) {
                    this.lineUserId = jsonDetailCamel?.customerDetails.lineUserId
                }
                if (jsonDetailCamel?.customerDetails.picture) {
                    this.picture = jsonDetailCamel?.customerDetails.picture
                }
            }
            if (jsonDetailCamel?.itemDetailContent != null) {
                this.itemDetailContentInDetailsObject = jsonDetailCamel?.itemDetailContent;
            }
            if (jsonDetailCamel?.itemDetailContentNames != null) {
                this.itemDetailContentNamesInDetailsObject = jsonDetailCamel?.itemDetailContentNames;
            }
        }
        return this;
    };

    convertToItemOrderHistory() {
        const itemOrderHistory = new ItemOrderHistory()
        itemOrderHistory.itemCode = this.itemCode
        itemOrderHistory.itemName = this.itemName
        itemOrderHistory.orderNumber = this.orderNumber
        itemOrderHistory.priceTaxIn = this.priceTaxIn
        itemOrderHistory.lineUserId = this.lineUserId ? this.lineUserId : LINE_USER_ID_UNKNOWN
        itemOrderHistory.picture = this.picture
        itemOrderHistory.created = this.created
        itemOrderHistory.createStatus = this.createStatus
        itemOrderHistory.itemDetailContentInDetailsObject =  this.itemDetailContentInDetailsObject
        itemOrderHistory.itemDetailContentNamesInDetailsObject =  this.itemDetailContentNamesInDetailsObject
        itemOrderHistory.itemDetailContentOrderList = this.itemDetailContentInOrderDetail.map(value => {
            const itemDetailContentOrderHistory = new ItemOrderHistory()
            itemDetailContentOrderHistory.itemCode = value.itemCode
            itemDetailContentOrderHistory.itemName = value.itemName
            itemDetailContentOrderHistory.orderNumber = value.orderNumber
            itemDetailContentOrderHistory.priceTaxIn = value.priceTaxIn
            itemDetailContentOrderHistory.lineUserId = value.lineUserId ? value.lineUserId : LINE_USER_ID_UNKNOWN
            itemDetailContentOrderHistory.picture = value.picture
            itemDetailContentOrderHistory.created = value.created
            itemDetailContentOrderHistory.itemDetailContentInDetailsObject =  value.itemDetailContentInDetailsObject
            itemDetailContentOrderHistory.itemDetailContentNamesInDetailsObject =  value.itemDetailContentNamesInDetailsObject
            return itemDetailContentOrderHistory
        }).sort((a, b) => (a.created > b.created) ? 1 : -1)
        return itemOrderHistory
    }
}

export interface OrderDto {
    orderCode: string,
    shopCode: string,
    state: number
    customerId: string,
    status: number,
    created: number,
    modified: number,
    createStatus: number
}

interface PaymentDto {
    code: string,
    created: number,
    deposit: number,
    change: number,
    discount?: number | null,
    discountPrice?: number | null,
    discountPrice2?: number | null,
    discountType?: number | null,
    lateNightCharge?: number | null,
    lateNightChargePrice?: number | null,
    lateNightChargeType?: number | null,
    serviceCharge?: number | null,
    serviceChargePrice?: number | null,
    serviceChargeType?: number | null
    subtotal: number,
    tax: number,
    total: number,
    type: number,
    state: number
}

interface PaymentOrderDetail {
    created: number,
    orderDetailCode: number,
    orderNumber: number,
    paymentCode: number,
    state: number
}