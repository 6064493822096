import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import LangConst from "../../../const/lang.const";
import {getPriceFormatYen} from "../../../utils/lang.utils";

interface Price {
    totalPrice: number
}

const TotalPrice: FC<Price> = ({totalPrice}) => {
    const classes = useStyles();

    const {t: getLabel} = useTranslation(LangConst.NS_CART)
    return (
        <Box className={classes.container}>
            <Typography className={`hvt-font-family dark-lg-txt`}>{getLabel(LangConst.TXT_CURRENCY_CART)}</Typography>
            <Typography className={`hvt-font-family light-xxl-txt`}>{getPriceFormatYen(totalPrice)}</Typography>
        </Box>
    )
}

export default TotalPrice;

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "baseline",
        marginLeft: 16,
        justifyContent: "flex-end",
        "&>p": {
            fontWeight: "bold",
        },
        "&>p:last-child": {
            marginLeft: 4,
        }
    }
}));
