import {AnyAction} from "redux";
import {toCamel, toSnake} from "../utils";
import {call, put} from "redux-saga/effects";
import {OrderService} from "../services";
import {ApiConstant} from "../const";
import OrderAction from "../redux/order.redux";

export interface ResponseGenerator {
    data?: any,
    status?: number,
}

export function* requestCreateOrder(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(OrderService.createOrder, snakeData);
        const orderData = toCamel(response.data);
        if (response.status === ApiConstant.STT_OK && orderData) {
            if (Object.values(orderData).length > 0) {
                yield put(OrderAction.createOrderSuccess(Object.values(orderData)[0]));
            } else {
                yield put(OrderAction.createOrderFailure("Order code not found."));
            }
        } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
            yield put(OrderAction.createOrderFailure(response.data.data));
        } else {
            yield put(OrderAction.createOrderFailure("Order code not found."));
        }
    } catch (error) {
        yield put(OrderAction.createOrderFailure(error));
    }
}
