import {AnyAction} from "redux";
import {toSnake} from "../utils";
import {call, put} from "redux-saga/effects";
import {ResponseGenerator} from "../models";
import CallAction from "../redux/table.activity.call.redux";
import {ApiConstant} from "../const";
import {TableActivityCallService} from "../services";

export function* requestTableActivityCallCreate(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(TableActivityCallService.requestTableActivityCallCreate, snakeData);
        if (response.status === ApiConstant.STT_OK && response.data) {
            yield put(CallAction.createTableActivityCallSuccess(response.data));
        } else {
            yield put(CallAction.createTableActivityCallFailure(response.data));
        }
        yield put(CallAction.createTableActivityCallSuccess(action));

    } catch (e) {
        yield put(CallAction.createTableActivityCallFailure(e));
    }
}
