import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import Staff from "../models/staff";
import {AnyAction} from "redux";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestReadStaff: ["data"],
    readStaffSuccess: ["data"],
    readStaffFailure: ["error"]
});

export const StaffTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface StaffStateRedux extends IDefaultStateProps {
    data: Staff[],
}

export const INITIAL_STATE: StaffStateRedux = {
    data: [],
    error: null,
    isFetching: false,
}

/* ------------- Reducers ------------- */
export const requestReadStaff = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true,
    ...action
});

export const readStaffSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readStaffFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_STAFF]: requestReadStaff,
    [Types.READ_STAFF_SUCCESS]: readStaffSuccess,
    [Types.READ_STAFF_FAILURE]: readStaffFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
