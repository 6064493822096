import SystemConstant from "../const/system.const";
import {toCamel} from "../utils";

export class MoPeriodTime {
    start: string;
    end: string;

    constructor() {
        this.start = SystemConstant.TIME_PERIOD_DEFAULT;
        this.end = SystemConstant.TIME_PERIOD_DEFAULT;
    }
}

export class MoPeriod {
    lunchTime?: MoPeriodTime | null;
    dinnerTime?: MoPeriodTime | null;

    constructor() {
        this.lunchTime = null;
        this.dinnerTime = null;
    }
}

export default class Category {
    // Set default
    categoryCode: string;
    name: string;
    description: string;
    lft: number;
    rgt: number;
    moDisplay: number;
    moSortOrder: number;
    moPeriod?: string | null;
    moPeriodObject?: MoPeriod;
    moDayOfWeek?: string | null;
    moDayOfWeekArray?: number[] | null;
    sortOrder: number;
    state: number;
    created: number;
    modified: number | null;
    childNumber: number | null;
    itemNumber: number;
    multiName: any;


    constructor() {
        // Set default
        this.categoryCode = "";
        this.name = "";
        this.description = "";
        this.lft = 0;
        this.rgt = 0;
        this.moDisplay = 0;
        this.moSortOrder = 0;
        this.moPeriod = null;
        this.moPeriodObject = new MoPeriod();
        this.moDayOfWeek = null;
        this.moDayOfWeekArray = null;
        this.sortOrder = 0;
        this.state = SystemConstant.STATE.ACTIVE;
        this.created = 0;
        this.modified = null;
        this.childNumber = null;
        this.itemNumber = 0;
        this.multiName = {};
        this.multiName = {};

    }

    setMoPeriod() {
        if (this.moPeriod) {
            this.moPeriodObject = toCamel(JSON.parse(this.moPeriod))
        }
        return this
    }

    setMoDayOfWeek() {
        if (this.moDayOfWeek) {
            this.moDayOfWeekArray = JSON.parse(this.moDayOfWeek)
        }
        return this
    }

}
