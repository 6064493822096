export default {
    TXT_APP_NAME: "MOA",
    TXT_MENU: "菜單",
    TXT_CALL: "通知",
    TXT_HISTORY: "歷史",
    TXT_PAYMENT: "付款",
    TXT_UNIT: "さん",
    TXT_YEN: "日元",
    TXT_ITEM: "品",
    TXT_SOLD_OUT: "SOLDOUT",
    TXT_CURRENTLY_UNAVAILABLE: "目前不可用",
    TXT_UNKNOWN_ERROR: "發生錯誤",
    TXT_HOME_PAGE: "啟動相機並 \n 掃描二維碼"
};
