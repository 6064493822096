const SystemConstant = {
  STOCK_FLAG: {
    INACTIVE: 0,
    ACTIVE: 1
  },
  STATE: {
    INACTIVE: 0,
    ACTIVE: 1
  },
  ORDER_STATUS: {
    PROCESSING: 1,
    READY: 2,
    FAILED: 3
  },
  MAX_CUSTOMER_NUMBER: 10,
  ATTACHMENT_TYPE_ITEM: 4,
  ATTACHMENT_TYPE_TIP: 9,
  TERMINAL_TYPE:{
    MO: 0,
    B_REG: 1,
    B_HANDY: 2
  },
  TIME_ZONE_SERVER_DEFAULT: 9,
  TIME_PERIOD_DEFAULT :"00:00",
  LIMIT_LENGTH_NAME: 14,
  LIMIT_LENGTH_NAME_IN_HISTORY: 15,
};
export default SystemConstant;
