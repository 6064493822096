export default {
    TXT_MENU: "메뉴",
    TXT_CALL: "알림",
    TXT_HISTORY: "기록",
    TXT_PAYMENT: "결제",
    TXT_UNIT: "사응",
    TXT_YEN: "엔",
    TXT_ITEM: "상품",
    TXT_SOLD_OUT: "SOLDOUT",
    TXT_CURRENTLY_UNAVAILABLE: "현재 사용할 수 없습니다.",
    TXT_UNKNOWN_ERROR: "오류 발생",
    TXT_HOME_PAGE: "카메라를 시작하고 \n QR 코드를 스캔하세요."
};
