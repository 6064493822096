import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import TableActivityCall from "../models/TableActivityCall";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestTableActivityTipCreate: ["data"],
    readTableActivityTipCreateSuccess: ["data"],
    readTableActivityTipCreateFailure: ["error"],
});

export const TableActivityTipCreateTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface TableActivityTipCreateStateRedux extends IDefaultStateProps {
    data: TableActivityCall,
    isSuccess: boolean;
}

export const INITIAL_STATE: TableActivityTipCreateStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: new TableActivityCall(),
}

/* ------------- Reducers ------------- */

export const requestTableActivityTipCreate = (state = INITIAL_STATE) => ({
    ...state,
    isFetching: true
});

export const readTableActivityTipCreateSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readTableActivityTipCreateFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_TABLE_ACTIVITY_TIP_CREATE]: requestTableActivityTipCreate,
    [Types.READ_TABLE_ACTIVITY_TIP_CREATE_SUCCESS]: readTableActivityTipCreateSuccess,
    [Types.READ_TABLE_ACTIVITY_TIP_CREATE_FAILURE]: readTableActivityTipCreateFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
