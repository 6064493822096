import React, {memo} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {getLiffId, getTableActivityCode, hasLogin} from "../utils";
import {KeyConstant, PathConstant} from "../const";
import {getShopCode} from "../utils/shop.util";
import {parse} from "qs";
import ApiConst from "../const/api.const";

export const AuthenticationRoute: React.FC<{ children: JSX.Element }> = ({children}) => {
    const isChecked = hasLogin();
    const location = useLocation();
    // Format link for preview: https://192.168.1.87:3000/277_2252/1/277_1000076?moa=1656930868-8oo4pXjr&is_preview=1&day_of_week=2&time_period=17:30
    const isPreview = localStorage.getItem(KeyConstant.KEY_IS_PREVIEW)
    if (isPreview != null && isPreview == '1') {
        return children
    }

    if (location.pathname != PathConstant.CHECK_IN && location.pathname != PathConstant.LOGIN) {
        const searchQuery = parse(location.search, {ignoreQueryPrefix: true})
        const moa = new URLSearchParams(window.location.search).get("moa")?.split("-").slice(0, 2).join("-")
        const isPreview = searchQuery.is_preview
        const dayOfWeekPreview = searchQuery.day_of_week
        const timePreview = searchQuery.time_period

        localStorage.setItem(KeyConstant.KEY_IS_PREVIEW, (isPreview) ? `${isPreview}` : "0")
        localStorage.setItem(KeyConstant.KEY_DAY_OF_WEEK, (dayOfWeekPreview) ? `${dayOfWeekPreview}` : "0")
        localStorage.setItem(KeyConstant.KEY_TIME_PERIOD, (timePreview) ? `${timePreview}` : "0")

        if (searchQuery.shopCode != null &&
            searchQuery.is_preview != null &&
            searchQuery.day_of_week != null &&
            searchQuery.time_period != null &&
            searchQuery.tableCode != null
        ) {
            return <Navigate to={`/${searchQuery.shopCode}/${ApiConst.TABLE_MODE}/${searchQuery.tableCode}?moa=${moa}&is_preview=${searchQuery.is_preview}&day_of_week=${searchQuery.day_of_week}&time_period=${searchQuery.time_period}`}
                             state={{from: location}}/>;
        }
        if (searchQuery.shopCode !== getShopCode() || searchQuery.tableActivityCode !== getTableActivityCode() || moa !== getLiffId()) {
            return <Navigate to={`/${searchQuery.shopCode}/${ApiConst.TABLE_ACTIVITY_MODE}/${searchQuery.tableActivityCode}?moa=${moa}`} state={{from: location}}/>;
        }
    }
    if (!isChecked) {
        return <Navigate to={PathConstant.LOGIN} state={{from: location}}/>;
    }
    return children;
};

export default memo(AuthenticationRoute);
