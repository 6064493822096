export default {
    TXT_TIP_THE_STAFF: "Tip the staff",
    TXT_TIP_CONTENT: "You can give the staff a tip \n to express your support and gratitude.",
    TXT_TIP_1: "I like the polite customer service!",
    TXT_TIP_2: "Recommendations, allergies, etc.\n" + "Very helpful explanation!",
    TXT_TIP_3: "Even when I asked to change seats\n" + "They responded with a smile!",
    TXT_LET_SEND_TIP: "Let's send a tip to the staff at times like this",
    TXT_GIVE_TIP: "Give a tip",
    TXT_TIP_TITLE: "I want to give a tip\n" + "Please select staff",
    TXT_PLEASE_SELECT_TIP: "Please select a tip",
    TXT_ITEM_VALUE_INCLUDED_BILL: "Item value is included in your bill.",
    TXT_THANK_YOU_1: "Thank you for the tip!",
    TXT_THANK_YOU_2: "Thank you for the tip. Riho will come to say hello later. We will be devoted to receiving gifts again!",
}
