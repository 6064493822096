import {setData} from "../models/history";
import {AnyAction} from "redux";
import {toCamel, toSnake} from "../utils";
import {call, put} from "redux-saga/effects";
import {ApiConstant, SystemConstant} from "../const";
import HistoryAction from "../redux/history.redux";
import {TableActivityService} from "../services";
import {ResponseGenerator} from "../models";
import TableActivity, {OrderDetailDto} from "../models/table.activity";

interface TableActivityResponseGenerator {
    shopCode?: string,
    tableActivities?: TableActivity[],
}

export function* requestReadHistory(action: AnyAction) {
    try {
        const {searchData} = action;
         const snakeData = toSnake(searchData);
         const response: ResponseGenerator = yield call(TableActivityService.readTableActivity, snakeData);
         const tableActivityResponseGenerator: TableActivityResponseGenerator = toCamel(response.data);

        if (response.status === ApiConstant.STT_OK && tableActivityResponseGenerator) {
            if (tableActivityResponseGenerator.tableActivities != undefined) {
                const tableActivities: TableActivity[] = tableActivityResponseGenerator.tableActivities;
                if (tableActivities.length > 0) {
                    const tableActivity = Object.assign(new TableActivity(), tableActivities[0]).setOrderListObject();
                    const orderDetailList = tableActivity.orderDetailList.map(orderDetail => {
                        const orderDetailResult = Object.assign(new OrderDetailDto(), orderDetail).formatDataOrderDetail();
                        if(tableActivity.orderListObject != null && tableActivity.orderListObject[orderDetail.orderCode]!=null){
                            orderDetailResult.created = tableActivity.orderListObject[orderDetail.orderCode].created
                        }
                        return orderDetailResult;
                    })
                    tableActivity.orderList?.filter(value => value.createStatus == SystemConstant.ORDER_STATUS.FAILED || value.createStatus == SystemConstant.ORDER_STATUS.PROCESSING)
                        .forEach(value => {
                            orderDetailList.forEach(detail => {
                                if (detail.orderCode == value.orderCode && detail.parentUid == null) {
                                    detail.createStatus = value.createStatus
                                }
                            })
                        })
                    tableActivity.orderDetailList = orderDetailList
                    const orderHistory = setData(tableActivity)
                    yield put(HistoryAction.readHistorySuccess(orderHistory));
                }
            }
        } else {
            yield put(HistoryAction.readHistoryFailure(response.data));
        }
    } catch (e) {
        yield put(HistoryAction.readHistoryFailure(e));
    }
}
