import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import Tip from "../models/tip";
import {AnyAction} from "redux";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestReadTip: ["data"],
    readTipSuccess: ["data"],
    readTipFailure: ["error"],
});

export const TipTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface TipStateRedux extends IDefaultStateProps {
    data: Tip[],
}

export const INITIAL_STATE: TipStateRedux = {
    data: [],
    error: null,
    isFetching: false,
}

/* ------------- Reducers ------------- */
export const requestReadTip = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: true,
    ...action
});

export const readTipSuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readTipFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_TIP]: requestReadTip,
    [Types.READ_TIP_SUCCESS]: readTipSuccess,
    [Types.READ_TIP_FAILURE]: readTipFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
