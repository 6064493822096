import {call, put} from "redux-saga/effects";
import {handlingLogin, toCamel, toSnake} from "../utils";
import {AuthService} from "../services";
import {ApiConstant} from "../const";
import {AnyAction} from "redux";
import AuthAction from "../redux/auth.redux";
import {ResponseGenerator} from "../models";

export interface AuthLoginResponseGenerator {
    scope?: string
    timeZone?: number
    userId?: string
    liffAppName?: string
}

export function* requestLogin(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(AuthService.login, snakeData);
        const dataLogin: AuthLoginResponseGenerator = toCamel(response.data);
        if (response.status === ApiConstant.STT_OK && dataLogin) {
            handlingLogin({
                ...dataLogin,
                accessToken: data.accessToken
            });
            yield put(AuthAction.loginSuccess());
        } else {
            yield put(AuthAction.loginFailure(response.data));
        }
    } catch (error) {
        yield put(AuthAction.loginFailure(error));
    }
}
