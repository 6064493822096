import {all, call, put} from "redux-saga/effects";
import {isDisplay, isDisplayInDayOfWeek, toCamel, toSnake} from "../utils";
import {CategoryService, HolidayService} from "../services";
import {ApiConstant, KeyConstant, LangConstant} from "../const";
import {AnyAction} from "redux";
import CategoryAction from "../redux/category.redux";
import {Category} from "../models";
import {convertHmToM, createDateServer, getCurrentMinutesInServer, getFormatDateYYYYMMDD} from "../utils/date.utils";
import Holiday from "../models/holiday";

export interface CategoryResponseGenerator {
    categories?: Category[],
    shopCode?: string,
}

export function* requestReadCategory(action: AnyAction): any {
    try {
        const {searchData} = action;
        const snakeData = toSnake(searchData);
        const [response, responseHoliday] = yield all(
            [call(CategoryService.readCategory, snakeData),
                call(HolidayService.readHoliday, snakeData)
            ])
        const responseCamel: CategoryResponseGenerator = toCamel(response.data);
        const holiday: Holiday = Object.assign(new Holiday(), toCamel(responseHoliday.data)).setDatesArray();

        let currentMinutes = getCurrentMinutesInServer(); // currentMinutes to check time period
        const currentDate = createDateServer();
        const currentDateYYYYMMDD = getFormatDateYYYYMMDD(currentDate);
        const dayOfWeekOfCurrentDate = currentDate.getDay(); //: 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.
        //This setting in webAdmin save data with format  moDayOfWeekFull = [0, 1, 2, 3, 4, 5, 6, 7]; // Mon,Tue,Wed,Thu, Fri, Sat, Sun, Holiday
        let dayOfWeekMappingWithMoDayOfWeek = dayOfWeekOfCurrentDate == 0 ? 6 : dayOfWeekOfCurrentDate - 1;
        const isPreview = localStorage.getItem(KeyConstant.KEY_IS_PREVIEW)
        const dayOfWeekInPreviewLink = localStorage.getItem(KeyConstant.KEY_DAY_OF_WEEK)
        const timeInPreviewLink = localStorage.getItem(KeyConstant.KEY_TIME_PERIOD)
        if (isPreview != null && isPreview == '1' && dayOfWeekInPreviewLink != null && timeInPreviewLink != null) {
            dayOfWeekMappingWithMoDayOfWeek = Number(dayOfWeekInPreviewLink)
            currentMinutes = convertHmToM(timeInPreviewLink)
        }
        const codeLanguage = localStorage.getItem(KeyConstant.KEY_LANG) ? localStorage.getItem(KeyConstant.KEY_LANG) : LangConstant.DEFAULT_LANG;
        if (response.status === ApiConstant.STT_OK && responseCamel) {
            if (responseCamel.categories != undefined) {
                const categories: Category[] = responseCamel.categories.map(value => Object.assign(new Category(), value).setMoPeriod().setMoDayOfWeek())
                const currentMinutes = getCurrentMinutesInServer();
                const categoryFilter = categories.filter(value => searchData.parentCategoryCode ||
                    (isDisplay(value, currentMinutes) && isDisplayInDayOfWeek(value, currentDateYYYYMMDD, dayOfWeekMappingWithMoDayOfWeek, holiday.datesArray))
                )
                categoryFilter.sort((a, b) => (a.moSortOrder > b.moSortOrder) ? 1 : -1);
                if (codeLanguage !== null && codeLanguage !== LangConstant.DEFAULT_LANG) {
                    categoryFilter.map((value) => {
                        if (value.multiName !== null && value.multiName[codeLanguage] && value.multiName[codeLanguage].trim() !== "") {
                            value.name = value.multiName[codeLanguage];
                        }
                    })
                }
                yield put(CategoryAction.readCategorySuccess(categoryFilter));
            }
        } else {
            yield put(CategoryAction.readCategoryFailure(response.data));
        }
    } catch (error) {
        yield put(CategoryAction.readCategoryFailure(error));
    }
}
