export default class SettingMoPayment {
    id: number;
    details: string;
    created: number;
    modified: number;
    shopCode: string;
    guideText: string;

    constructor() {
        this.id = 1;
        this.details = "";
        this.created = 0;
        this.modified = 0;
        this.shopCode = "";
        this.guideText = "";
    }
}
