import React from "react";
import LangConst from "../../../const/lang.const";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {Grid, Typography, Box} from "@mui/material";
import clsx from "clsx";
import {JapanIcon, EnglishIcon, ChinaIcon, KoreanIcon} from "../../../theme/images";
import {Language} from "../../../models";
import {LangConstant} from "../../../const";

const dataIcon: any = {
    'ja': {
        icon: JapanIcon,
        language: LangConst.DEFAULT_LANG_JAPAN,
    },
    'en': {
        icon: EnglishIcon,
        language: LangConst.DEFAULT_LANG_ENGLISH,
    },
    'zhCn': {
        icon: ChinaIcon,
        language: LangConst.DEFAULT_LANG_CHINA
    },
    'ko': {
        icon: KoreanIcon,
        language: LangConst.DEFAULT_LANG_KOREAN
    },
}

const CustomerButtonLanguage = (props: CustomerButtonLanguageProps) => {
    const {languageArray, languageData, selectedLanguage, onChangeLanguage} = props;
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_CHECK_IN);
    for (let i = 0; i < languageData.length; i++) {
        languageData[i].icon = dataIcon[languageData[i].code].icon;
        languageData[i].language = dataIcon[languageData[i].code].language;
    }
    const data = languageData.filter((item: any) => languageArray.includes(item.code) || item.code === LangConstant.DEFAULT_LANG);

    return <>
        <Box className={classes.boxLanguages}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                {data.length > 1 &&
                    data.map((value: Language, index: number) =>
                        <Grid item key={index} sm={6} xs={12}>
                            <Box
                                className={clsx(classes.boxLanguage, value.code === selectedLanguage && classes.active)}>
                                <label onClick={onChangeLanguage}>
                                    <img src={value.icon}/>
                                    <Typography
                                        className={`${classes.flagName} light-sm-txt hgp-w6-font-family-normal`}>{value.language}</Typography>
                                    <input type="radio" name="language" data-language={value.code}
                                           style={{display: "none"}}/>
                                    <span></span>
                                </label>
                            </Box>
                        </Grid>
                    )}
            </Grid>
        </Box>
    </>
}

interface CustomerButtonLanguageProps {
    languageArray: Array<string>;
    languageData: Array<Language>;
    selectedLanguage: string;
    onChangeLanguage: (e: any) => void;
}

export default CustomerButtonLanguage

const useStyles = makeStyles(() => ({
    boxLanguages: {
        width: "100%",
        marginTop: 32,
    },
    boxLanguage: {
        // justifyContent: "space-between",
        boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.1)",
        height: 48,
        borderRadius: 4,

        '& label': {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "inherit",
            position: "relative",
        }
    },
    active: {
        border: "solid 1px #ffaf31",
        '& span': {
            position: "absolute",
            top: -10,
            right: -10,
            height: 24,
            width: 24,
            backgroundColor: "#ffaf31",
            borderRadius: "50%",
            zIndex: 100,
        },
        '& span::after': {
            content: "''",
            position: "absolute",
            left: 8,
            top: 4,
            width: 8,
            height: 14,
            border: "solid white",
            borderWidth: "0 2px 2px 0",
            WebkitTransform: "rotate(45deg)",
            MsTransform: "rotate(45deg)",
            transform: "rotate(45deg)",
        }
    },
    flagName: {
        width: 72,
        marginLeft: 8,
    }
}));
