import {call, put} from "redux-saga/effects";
import {toCamel, toSnake} from "../utils";
import {ItemService} from "../services";
import {ApiConstant, KeyConstant, LangConstant} from "../const";
import {AnyAction} from "redux";
import ItemAction from "../redux/item.redux";
import {Item, ResponseGenerator} from "../models";

export interface ItemResponseGenerator {
    items?: Item[],
    shopCode?: string,
}

export function* requestReadItem(action: AnyAction) {
    try {
        const {searchData} = action;
        const snakeData = toSnake(searchData);
        const response: ResponseGenerator = yield call(ItemService.readItem, snakeData);
        const responseCamel: ItemResponseGenerator = toCamel(response.data);
        if (response.status === ApiConstant.STT_OK && responseCamel) {
            if (responseCamel.items != undefined) {
                const result = responseCamel.items.map(item => {
                    return Object.assign(new Item(), item).setMoInfo();
                })
                result.sort((a, b) => (a.moSortOrder > b.moSortOrder) ? 1 : -1);
                const codeLanguage = localStorage.getItem(KeyConstant.KEY_LANG) ? localStorage.getItem(KeyConstant.KEY_LANG) : LangConstant.DEFAULT_LANG;
                if (codeLanguage !== null && codeLanguage != LangConstant.DEFAULT_LANG) {
                    result.map((item) => {
                        if (item.multiName !== null && item.multiName[codeLanguage] && item.multiName[codeLanguage].trim() !== "") {
                            item.name = item.multiName[codeLanguage];
                        }
                        if (item.multiMoDescription !== null && item.multiMoDescription[codeLanguage] && item.multiMoDescription[codeLanguage].trim() !== "") {
                            item.moDescription = item.multiMoDescription[codeLanguage];
                        }
                    })
                }
                yield put(ItemAction.readItemSuccess(result));
            }
        } else {
            yield put(ItemAction.readItemFailure(response.data));
        }
    } catch (error) {
        yield put(ItemAction.readItemFailure(error));
    }
}
