export default {
    TXT_CURRENCY: "¥",
    TXT_MULTIPLY: "×",
    TXT_YEN: "円",
    TXT_TOTAL_ORDER: "ご注文合計:",
    TXT_TAX_INCLUDED: "(税込)",
    TXT_TITLE_HISTORY: "注文履歴",
    TXT_ORDER_DETAIL_FAILED: "注文の送信に失敗しました。",
    TXT_ORDER_AGAIN_NOTIFICATION: "注文の送信中にエラーが発生しました。\n" +
        "メニューからもう一度注文してください。"
};