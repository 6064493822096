import {Grid, MenuItem, Select, Typography} from "@mui/material";
import React from "react";
import LangConst from "../../../const/lang.const";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {DropdownNumberIcon, SelectedIcon} from "../../../theme/images";
import {SystemConstant} from "../../../const";

const CustomerInput = (props: CustomerInputProps) => {
    const {guestNumber, onChangeGuestNumber} = props
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_CHECK_IN);

    return <>
        <Grid container={true} direction="row" alignItems="center" className={classes.guestNumberWrap}>
            <Typography className={`${classes.title} hgp-w6-font-family light-sm-txt`}>
                {getLabel(LangConst.TXT_GUEST_NUMBER)}
            </Typography>
            <Select
                IconComponent={() => <img src={DropdownNumberIcon}/>}
                name={"guestNumber"}
                value={guestNumber}
                onChange={onChangeGuestNumber}
                className={`${classes.guestNumberInput} light-sm-txt`}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                padding: "0px 32px",
                                borderBottom: 1,
                                borderColor: "#e4e4e4",
                            },
                            '& .MuiList-root': {
                                paddingTop: 0,
                                paddingBottom: 0
                            },
                        },

                    },
                    transitionDuration: 0
                }}
            >
                {[...Array(SystemConstant.MAX_CUSTOMER_NUMBER)].map((x, i) =>
                    <MenuItem className={`${classes.selectDefault} light-xll-txt`} key={i + 1}
                              value={i + 1}>{i + 1}</MenuItem>
                )}
            </Select>
            <Typography className={`${classes.title} hgp-w6-font-family light-sm-txt`}>
                {getLabel(LangConst.TXT_PEOPLE)}
            </Typography>
        </Grid>
    </>
}

interface CustomerInputProps {
    guestNumber: number;
    onChangeGuestNumber: (e: any) => void;
}

CustomerInput.defaultProps = {
    guestNumber: 1
}
export default CustomerInput

const useStyles = makeStyles(() => ({
    title: {
        color: "#000000",
        flexShrink: 0,
    },
    guestNumberWrap: {
        marginTop: 32,
        display: "flex",
        flexWrap: "nowrap",
    },
    guestNumberInput: {
        width: "100%",
        height: 48,
        borderRadius: 4,
        textAlign: "left",
        paddingRight: 12,
        marginLeft: 16,
        marginRight: 16,
    },
    selectDefault: {
        borderColor: "#e4e4e4",
        backgroundColor: "#ffffff",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#000000",
        },
        "&.Mui-selected": {
            backgroundColor: "#f4f5f7 !important",
            backgroundImage: `url(${SelectedIcon})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: 222
        },
    },
}));
