const config = {
  api: {
    apiEndpoint: process.env.REACT_APP_API_ENDPOINT
  }
};

const API_ENDPOINT = config.api.apiEndpoint;

export {
  API_ENDPOINT
};

export default config;