import {makeStyles} from "@material-ui/core";
import React from "react";
import {Box, Typography} from "@mui/material";
import palette from "../../../theme/material/palette";
import {ImageConstant} from "../../../const";
import ItemDetailContent from "../../../models/item.detail.content";
import clsx from "clsx";
import {getPriceFormatYen} from "../../../utils/lang.utils";
import LangConst from "../../../const/lang.const";
import {useTranslation} from "react-i18next";

interface ItemDetailContentProps {
    itemDetailContent: ItemDetailContent,
    onClickItemDetailContent: (itemDetailContent: ItemDetailContent, orderNumberChange: number) => void,
}

const ItemDetailContentSelect = (props: ItemDetailContentProps) => {
    const {itemDetailContent, onClickItemDetailContent} = props;
    const classes = useStyles();
    const {t: getLabel} = useTranslation(LangConst.NS_CART);
    const selected = (itemDetailContent.orderNumber > 0) ?
        <img className={`${classes.img}`} src={ImageConstant.RoundSelectedIcon}/> : null

    return (
        <Box onClick={() => onClickItemDetailContent(itemDetailContent, +1)}
             className={classes.itemDetailContentLine}>
            <Box className={classes.boxNameAndPrice}>
                <Typography
                    className={clsx(classes.nameItemDetailContent, itemDetailContent.orderNumber > 0 && classes.active, `hgp-w6-font-family light-sm-txt`)}>{itemDetailContent.name}</Typography>
                <Box className={classes.boxPrice}>
                    <Typography
                        className={clsx(itemDetailContent.orderNumber > 0 && classes.active, `hvt-font-family light-sm-txt`)}>{getLabel(LangConst.TXT_CURRENCY_CART, {ns: LangConst.NS_CART})}</Typography>
                    <Typography
                        className={clsx(classes.price, itemDetailContent.orderNumber > 0 && classes.active, `hvt-font-family dark-lg-txt`)}>{(itemDetailContent.priceTaxIn > 0) ? `${getPriceFormatYen(itemDetailContent.priceTaxIn)}` : (itemDetailContent.priceTaxIn < 0 ? `-${getPriceFormatYen(Math.abs(itemDetailContent.priceTaxIn))}` : "")}</Typography>
                </Box>
            </Box>
            {selected}
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    itemDetailContentLine: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 16,
        borderTop: `1px solid ${palette.divider}`,
    },
    nameItemDetailContent: {},
    active: {
        color: "#ffaf31"
    },
    price: {
        lineHeight: 1.93,
        marginLeft: 4,
    },
    img: {
        marginLeft: "auto",
        width: 24,
        height: 24,
    },
    boxNameAndPrice: {
        display: "flex",
        flexDirection: "column"
    },
    boxPrice: {
        display: "flex",
        alignItems: "baseline",
    },
}));

export default ItemDetailContentSelect;
