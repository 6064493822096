import {AnyAction} from "redux";
import {toCamel, toSnake} from "../utils";
import {call, put} from "redux-saga/effects";
import {TableActivityService} from "../services";
import {ApiConstant} from "../const";
import TableActivityAction from "../redux/table.activity.redux";

export interface ResponseGenerator {
    data?: any,
    status?: number,
}

export function* requestReadTableActivity(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(TableActivityService.readTableActivity, snakeData);
        if (response.data?.table_activities === undefined) {
            yield put(TableActivityAction.readTableActivityFailure(toCamel(response.data)));
            return
        }
        const tableActivityData = toCamel(response.data?.table_activities);
        if (response.status === ApiConstant.STT_OK && tableActivityData) {
            yield put(TableActivityAction.readTableActivitySuccess(tableActivityData));
        } else {
            yield put(TableActivityAction.readTableActivityFailure(response.data));
        }
    } catch (error) {
        yield put(TableActivityAction.readTableActivityFailure(error));
    }
}

export function* requestCreateTableActivity(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(TableActivityService.createTableActivity, snakeData);
        const tableActivityData = toCamel(response.data);
        if (response.status === ApiConstant.STT_OK && tableActivityData) {
            if (Object.values(tableActivityData).length > 0) {
                yield put(TableActivityAction.createTableActivitySuccess(Object.values(tableActivityData)[0]));
            } else {
                yield put(TableActivityAction.createTableActivityFailure("Table activity code not found"));
            }
        } else {
            yield put(TableActivityAction.createTableActivityFailure(response.data));
        }
    } catch (error) {
        yield put(TableActivityAction.createTableActivityFailure(error));
    }
}

export function* requestUpdateTableActivity(action: AnyAction) {
    try {
        const {data} = action;
        const snakeData = toSnake(data);
        const response: ResponseGenerator = yield call(TableActivityService.updateTableActivity, snakeData);
        const tableActivityData = toCamel(response.data);
        if (response.status === ApiConstant.STT_OK && tableActivityData) {
            if (Object.values(tableActivityData).length > 0) {
                yield put(TableActivityAction.updateTableActivitySuccess(Object.values(tableActivityData)[0]));
            } else {
                yield put(TableActivityAction.updateTableActivityFailure("Table activity code not found"));
            }
        } else {
            yield put(TableActivityAction.updateTableActivityFailure(response.data));
        }
    } catch (error) {
        yield put(TableActivityAction.updateTableActivityFailure(error));
    }
}