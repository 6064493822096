import {createActions, createReducer} from "reduxsauce";
import {IDefaultStateProps} from "./state";
import {AnyAction} from "redux";
import {OrderHistory} from "../models";

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({
    requestReadHistory: ["searchData"],
    readHistorySuccess: ["data"],
    readHistoryFailure: ["error"]
});

export const HistoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export interface HistoryStateRedux extends IDefaultStateProps {
    data: OrderHistory;
    isSuccess: boolean;
    searchData: any;
}

export const INITIAL_STATE: HistoryStateRedux = {
    isFetching: false,
    error: null,
    isSuccess: false,
    data: new OrderHistory(),
    searchData: {}
};

/* ------------- Reducers ------------- */

export const requestReadHistory = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    error: null,
    isSuccess: false,
    isFetching: true,
});

export const readHistorySuccess = (state = INITIAL_STATE, action: AnyAction) => ({
    ...state,
    isFetching: false,
    ...action
});

export const readHistoryFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isFetching: false,
    error: action.error,
    isSuccess: false
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
    [Types.REQUEST_READ_HISTORY]: requestReadHistory,
    [Types.READ_HISTORY_SUCCESS]: readHistorySuccess,
    [Types.READ_HISTORY_FAILURE]: readHistoryFailure
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);