/**
 * Saga index: connects action type and saga
 */

import {all, takeLatest} from "redux-saga/effects";

/* ------------- Types ------------- */
/* ------------- Sagas ------------- */
import {requestLogin} from "./auth.saga";
import {requestCreateTableActivity, requestReadTableActivity, requestUpdateTableActivity} from "./table.activity.saga";
import {requestReadCategory} from "./category.saga"
import {requestReadItem} from "./item.saga";
import {requestReadAttachment, requestReadItemDetail} from "./item.detail.saga"
import {requestReadShop} from "./shop.saga";
import {requestDeleteItemInCart, requestChangeItemInCart} from "./cart.saga";
import {requestCreateOrder} from "./order.saga";
import {requestSettingMoTop} from "./setting.mo.top.saga";
import {requestSettingMoPayment} from "./setting.mo.payment.saga";
import {requestLanguage} from "./language.saga";
import {raiseError} from "./error.saga";
import {requestReadHistory} from "./history.saga";
import {requestReadOrderStatus} from "./order.status.saga";
import {requestTableActivityCallCreate} from "./table.activity.call.saga";
import {requestHodai, requestPlanRead, requestPlanItemRead} from "./hodai.saga";
import {requestReadTip} from "./tip.saga";
import {requestTableActivityTipCreate} from "./table.activity.tip.create.saga";
import {requestReadStaff} from "./staff";

import {AuthTypes} from "../redux/auth.redux";
import {TableActivityTypes} from "../redux/table.activity.redux"
import {CategoryTypes} from "../redux/category.redux";
import {ItemTypes} from "../redux/item.redux";
import {ItemDetailTypes} from "../redux/item.detail.redux";
import {ShopTypes} from "../redux/shop.redux";
import {HistoryTypes} from "../redux/history.redux";
import {TableActivityCallTypes} from "../redux/table.activity.call.redux";
import {CartTypes} from "../redux/cart.redux";
import {OrderTypes} from "../redux/order.redux";
import {OrderStatusTypes} from "../redux/order.status.redux";
import {SettingMoTopTypes} from "../redux/setting.mo.top.redux";
import {SettingMoPaymentTypes} from "../redux/setting.mo.payment.redux";
import {LanguageTypes} from "../redux/language.redux";
import {HodaiTypes} from "../redux/hodai.redux";
import {TipTypes} from "../redux/tip.redux";
import {TableActivityTipCreateTypes} from "../redux/table.activity.tip.create.redux";
import {StaffTypes} from "../redux/staff.redux";

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
    yield all([
        //login
        takeLatest(AuthTypes.REQUEST_LOGIN, requestLogin),
        // Table activity
        takeLatest(TableActivityTypes.REQUEST_READ_TABLE_ACTIVITY, requestReadTableActivity),
        takeLatest(TableActivityTypes.REQUEST_CREATE_TABLE_ACTIVITY, requestCreateTableActivity),
        takeLatest(TableActivityTypes.REQUEST_UPDATE_TABLE_ACTIVITY, requestUpdateTableActivity),
        //Category
        takeLatest(CategoryTypes.REQUEST_READ_CATEGORY, requestReadCategory),
        //Item
        takeLatest(ItemTypes.REQUEST_READ_ITEM, requestReadItem),
        //Item detail
        takeLatest(ItemDetailTypes.REQUEST_READ_ITEM_DETAIL, requestReadItemDetail),
        takeLatest(ItemDetailTypes.REQUEST_READ_ATTACHMENT, requestReadAttachment),
        // Shop
        takeLatest(ShopTypes.REQUEST_READ_SHOP, requestReadShop),
        // Order history
        takeLatest(HistoryTypes.REQUEST_READ_HISTORY, requestReadHistory),
        // Cart
        takeLatest(CartTypes.REQUEST_DELETE_ITEM_IN_CART, requestDeleteItemInCart),
        takeLatest(CartTypes.REQUEST_CHANGE_ITEM_IN_CART, requestChangeItemInCart),
        // Order
        takeLatest(OrderTypes.REQUEST_CREATE_ORDER, requestCreateOrder),
        // Order status
        takeLatest(OrderStatusTypes.REQUEST_READ_ORDER_STATUS, requestReadOrderStatus),
        // Setting mo top
        takeLatest(SettingMoTopTypes.REQUEST_SETTING_MO_TOP, requestSettingMoTop),
        takeLatest(LanguageTypes.REQUEST_READ_LANGUAGE, requestLanguage),
        // Setting mo payment
        takeLatest(SettingMoPaymentTypes.REQUEST_SETTING_MO_PAYMENT, requestSettingMoPayment),
        // TableActivityCall
        takeLatest(TableActivityCallTypes.REQUEST_TABLE_ACTIVITY_CALL_CREATE, requestTableActivityCallCreate),
        // Hodai
        takeLatest(HodaiTypes.REQUEST_HODAI_READ, requestHodai),
        takeLatest(HodaiTypes.REQUEST_PLAN_READ, requestPlanRead),
        takeLatest(HodaiTypes.REQUEST_PLAN_ITEM_READ, requestPlanItemRead),

        // Staff Tip
        takeLatest(TipTypes.REQUEST_READ_TIP, requestReadTip),
        takeLatest(TableActivityTipCreateTypes.REQUEST_TABLE_ACTIVITY_TIP_CREATE, requestTableActivityTipCreate),
        takeLatest(StaffTypes.REQUEST_READ_STAFF, requestReadStaff),

        takeLatest(
            [
                // Authenticate
                AuthTypes.LOGIN_FAILURE
            ],
            raiseError
        )
    ]);
}
